import React, { useState } from 'react';
import {FaStar } from "react-icons/fa";

const StarRating2 = ({ initialValue, onSave }) => {
  const [rating, setRating] = useState(initialValue);

  const handleClick = (value) => {
    setRating(value);
    onSave(value);
  };

  return (
    <div className='flex'>
      {[...Array(5)].map((_, index) => {
        const ratingValue = index + 1;
        return (
          <div
            key={index}
            className={`cursor-pointer text-[16px] ${
              ratingValue <= rating ? 'text-yellow-400' : 'text-gray-300'
            }`}
            onClick={() => handleClick(ratingValue)}
          >
            <FaStar/>
          </div>
        );
      })}
    </div>
  );
};

export default StarRating2;
