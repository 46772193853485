import React from 'react';
import "./App.css";
import Homepage from "./Pages/Homepage";
import { Routes, Route } from "react-router-dom";
import Login from "../src/Pages/Login";
import SignUp from "./Pages/SignUp";
import Navbar from "./Components/Common/Navbar";
import Footer from "./Components/Common/Footer";
import ProductPage from "./Pages/ProductPage";
import CartPage from "./Pages/CartPage";
import UserDashboard from "./Components/UserDashboard/main/UserDashboard";
import OrderHistory from "./Components/UserDashboard/main/OrderHistory";
import UserSettings from "./Components/UserDashboard/Settings/UserSettings"
import UserCart from "./Components/UserDashboard/main/UserCart";
import UserProfile from "./Components/UserDashboard/main/UserProfile";
import ProductDetailPage from "./Components/Product/ProductDetailPage";
import ErrorPage from "./Components/Common/ErrorPage";
import CategoryProductsPage from "./Pages/CategoryProductPage";
import UserWishlist from "./Components/UserDashboard/main/UserWishlist";
import AboutUs from "./Pages/AboutUs";
import ContactUs from "./Pages/ContactUs";
import ReturnPolicyPage from "./Pages/ReturnPolicyPage";
import RefundPolicyPage from "./Pages/RefundPolicyPage";
import ShippingPolicyPage from "./Pages/ShippingPolicypage";
import CheckoutPage from "./Pages/CheckoutPage";
import PaymentData from "./Pages/PaymentData";
import SearchResultsPage from "./Pages/SearchResultsPage";
import TermAndConditions from "./Pages/TermAndConditions";
import PasswordChange from "./Components/UserDashboard/Settings/PasswordChange";
import ViewOrderData from "./Components/UserDashboard/main/ViewOrderData";
import OrderTrackingPage from "./Pages/OrderTrackingPage";
import PrivacyPolicyPage from "./Pages/PrivicyPolicyPage";
import CancellationPolicyPage from "./Pages/CancelaltionPolicyPage";
import PaymentDecline from "./Pages/PaymentDecline";
import BestSellerProductPage from './Pages/BestSellerProductsPage';



function App() {
  return (
    <div className="min-h-screen flex flex-col font-inter">
      <Navbar />
      <Routes>
        <Route path="/" element={<Homepage />}></Route>
        <Route path="/login" element={<Login />}></Route>
        <Route path="/signup" element={<SignUp />}></Route>
        <Route path="/productpage" element={<ProductPage />}></Route>
        <Route path="/best-seller" element={<BestSellerProductPage />}></Route>
        <Route path="/cart" element={<CartPage />}></Route>
        <Route path="/product-detail/:slug/:id" element={<ProductDetailPage />}></Route>
        <Route path="/collections/:slug" element={<CategoryProductsPage />}></Route>
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/return-policy" element={<ReturnPolicyPage />} />
        <Route path="/refund-policy" element={<RefundPolicyPage />}></Route>
        <Route path="/shipping-policy" element={<ShippingPolicyPage />}></Route>
        <Route path="/privacy-policy" element={<PrivacyPolicyPage />}></Route>
        <Route path="/cancellation-policy" element={<CancellationPolicyPage />}></Route>
        <Route path="/checkout" element={<CheckoutPage />}></Route>
        <Route path="/order-recieved/:order_id" element={<PaymentData />}> </Route>
        <Route path="/search-results" element={<SearchResultsPage />} />
        <Route path="/term&conditions" element={<TermAndConditions />} />
        <Route path="/ordertracking" element={<OrderTrackingPage />} />
        <Route path="/payment-decline" element={<PaymentDecline />}></Route>
        <Route path="/userdashboard" element={<UserDashboard />}>
          <Route index element={<UserProfile />}></Route>
          <Route path="/userdashboard/order-history" element={<OrderHistory />}></Route>
          <Route path="/userdashboard/settings" element={<UserSettings />}></Route>
          <Route path="/userdashboard/cart" element={<UserCart />}></Route>
          <Route path="/userdashboard/wishlist" element={<UserWishlist />}></Route>
          <Route path="/userdashboard/profile" element={<UserProfile />}></Route>
          <Route path="/userdashboard/password-change" element={<PasswordChange />}></Route>
          <Route path="/userdashboard/view-order" element={<ViewOrderData />}></Route>
        </Route>
        <Route path="*" element={<ErrorPage />}></Route>
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
