import React, { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllProducts } from '../services/operations/productDetailsAPI';
import BestSellerProduct from '../Components/Product/BestSellerProduct';
import { addBestSellerProducts } from '../slices/bestSellerSlice';
import Loader from '../Components/Common/Loader';
import axios from 'axios';

const BestSellerProductPage = () => {
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const bestSellerProducts = useSelector(state => state.bestSellerProducts.bestSellerProducts);

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        async function fetchBestSellerData() {
            setLoading(true);
            try {
                const response = await axios.get('https://admin.theroarfit.com/api/get-bestseller-products')
                const bestSellerproducts = response?.data?.data?.data;
                dispatch(addBestSellerProducts(bestSellerproducts));
                setLoading(false);
              } catch (error) {
                console.error('Error while fetching best seller products:', error);
                setLoading(false);
              } finally {
                setLoading(false);
            }
        }

        if (bestSellerProducts.length === 0) {
            fetchBestSellerData();
        }
    }, [dispatch, bestSellerProducts]);

    return (
        <div>
            <div className='pt-8 pb-12'>
                {loading ? (
                    <Loader/>
                ) : (
                    bestSellerProducts.length > 0 ? (
                        <div className="max-w-7xl w-11/12 pt-4 mx-auto grid gap-2 grid-cols-2 md:gap-2 sm:grid-cols-3 lg:grid-cols-4">
                            {bestSellerProducts.map((product) => {
                                return <BestSellerProduct key={product.id} product={product} />;
                            })}
                        </div>
                    ) : (
                        <div className="flex justify-center items-center">
                            <p>No Data Found...</p>
                        </div>
                    )
                )}
            </div>
        </div>


    );
}

export default BestSellerProductPage;
