import React, { useEffect } from 'react'
import { useState } from 'react'
import Product from '../Components/Product/Product';
import {getAllProducts} from "../services/operations/productDetailsAPI"
import Loader from '../Components/Common/Loader';

function ProductPage() {
  const [loading,setLoading]=useState(false);
  const [products,setProducts]=useState([]);

  async function fetchProductData(){
    setLoading(true);
    try {
      const result = await getAllProducts();
      const data = result.data.data;
      console.log("all products",data);
      setProducts(data);
    } catch (error) {
      console.log("Error occured during fetching the all products",error);
      setProducts([]);
    }
    setLoading(false);
  }
  useEffect(()=>{
    fetchProductData();
  },[])
  return (
    <div className='pt-36 mt-11 pb-12 lg:max-w-7xl mx-auto w-11/12 '>
     { 
      loading?<Loader/>:
      products.length>0?
      (
        <div className="max-w-7xl mx-auto grid gap-2 grid-cols-2 md:gap-4 sm:grid-cols-3 lg:grid-cols-4 ">
          {
            products.map((product)=>{
              return <Product key={product.id} product={product}/>
            })
          }
        </div>
      ):
      <div className="flex justify-center items-center">
        <p>No Data Found...</p>
      </div>
     }
    </div>
  )
}

export default ProductPage