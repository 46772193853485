import React, { useState,useEffect } from 'react';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import toast from 'react-hot-toast';
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai"


const PasswordChange = () => {

    useEffect(() => {
        // Scroll to the top of the page when the component mounts
         window.scrollTo({ top: 0, behavior: 'smooth' });
      }, []);

    const [oldPassword, setOldPassword] = useState(false)
    const [newPassword, setNewPassword] = useState(false)
    const [confirmPassword, setShowConfirmPassword] = useState(false)
    
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const onSubmit = async (data) => {
        let loadingToastId;
        try {
            loadingToastId = toast.loading('Updating...');
            const authToken = JSON.parse(localStorage.getItem('token'));;

            // API endpoint
            const apiUrl = 'https://admin.theroarfit.com/api/change-password';

            // API request payload
            const payload = {
                current_password: data['current-password'],
                new_password: data['new_password'],
                new_password_confirmation: data['new_password_confirmation'],
            };

            // Make API request
            const response = await axios.post(apiUrl, payload, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authToken}`,
                },
            });

            // Handle success
            console.log('Password change API Response:', response.data);
            toast.success("Password updated successfully");
            // Optionally, you can reset the form or perform any other actions
        } catch (error) {
            // Handle errors
            console.error('Passsword change API Error:', error);
            toast.error('Password updating failure ,try again')
        }
        finally {
            // Close the loading toast regardless of success or failure
            toast.dismiss(loadingToastId);
        }
    };


    return (
        <section className='bg-gray-50'>
            <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
                <div className="w-full p-6 bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md sm:p-8">
                    <h2 className="mb-1 text-xl font-bold leading-tight tracking-tight md:text-2xl text-black">
                        Change Password
                    </h2>
                    <form className="mt-4 space-y-4 lg:mt-5 md:space-y-5" onSubmit={handleSubmit(onSubmit)}>
                        <div className='relative'>
                            <label htmlFor="current-password" className="block mb-2 text-sm font-medium text-black">Current Password</label>
                            <input
                                type={oldPassword ? "text" : "password"}
                                name="current-password"
                                id="current-password"
                                placeholder="Enter old password"
                                className={`bg-slate-100 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:border-none  outline-none block w-full p-2.5 ${errors['current-password'] ? 'border-red-500' : ''}`}
                                {...register('current-password', { required: true })}
                            />
                            {errors['current-password'] && (
                                <span className="text-red-500 text-sm">Current Password is required</span>
                            )}
                            <span
                                onClick={() => setOldPassword((prev) => !prev)}
                                className="absolute right-2 top-[2.47rem] sm:top-9 z-10 cursor-pointer text-red-500"
                            >
                                {oldPassword ? (
                                    <AiOutlineEyeInvisible fontSize={24} fill="#AFB2BF" />
                                ) : (
                                    <AiOutlineEye fontSize={24} fill="#AFB2BF" />
                                )}
                            </span>
                        </div>
                        <div className='relative'>
                            <label htmlFor="new_password" className="block mb-2 text-sm font-medium text-black">New Password</label>
                            <input
                                type={newPassword ? "text" : "password"}
                                name="new_password"
                                id="new_password"
                                placeholder="Enter new password"
                                className={`bg-slate-100 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:border-none  outline-none block w-full p-2.5 ${errors.new_password ? 'border-red-500' : ''}`}
                                {...register('new_password', { required: true })}
                            />
                            {errors.new_password && (
                                <span className="text-red-500 text-sm">New Password is required</span>
                            )}
                            <span
                                onClick={() => setNewPassword((prev) => !prev)}
                                className="absolute right-2 top-[2.47rem] sm:top-9 z-10 cursor-pointer text-red-500"
                            >
                                {newPassword ? (
                                    <AiOutlineEyeInvisible fontSize={24} fill="#AFB2BF" />
                                ) : (
                                    <AiOutlineEye fontSize={24} fill="#AFB2BF" />
                                )}
                            </span>
                        </div>
                        <div className='relative'>
                            <label htmlFor="new_password_confirmation" className="block mb-2 text-sm font-medium text-black">Confirm password</label>
                            <input
                                type={confirmPassword ? "text" : "password"}
                                name="new_password_confirmation"
                                id="new_password_confirmation"
                                placeholder="Confirm new password"
                                className={`bg-slate-100 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:border-none  outline-none block w-full p-2.5 ${errors.new_password_confirmation ? 'border-red-500' : ''}`}
                                {...register('new_password_confirmation', { required: true })}
                            />
                            {errors.new_password_confirmation && (
                                <span className="text-red-500 text-sm">Confirm Password is required</span>
                            )}
                            <span
                                onClick={() => setShowConfirmPassword((prev) => !prev)}
                                className="absolute right-2 top-[2.47rem] sm:top-9 z-10 cursor-pointer text-red-500"
                            >
                                {confirmPassword ? (
                                    <AiOutlineEyeInvisible fontSize={24} fill="#AFB2BF" />
                                ) : (
                                    <AiOutlineEye fontSize={24} fill="#AFB2BF" />
                                )}
                            </span>
                        </div>
                        <button
                            type="submit"
                            className="w-full text-white bg-black focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                        >
                            Reset password
                        </button>
                    </form>
                </div>
            </div>
        </section>
    );
};

export default PasswordChange;
