import {combineReducers} from "@reduxjs/toolkit";
import authReducer from "../slices/authSlice"
import cartReducer from "../slices/cartSlice"
import productSlice from "../slices/productSlice";
import categorySlice from "../slices/categorySlice";
import wishlistSlice from "../slices/wishlistSlice";
import bestSellerSlice from "../slices/bestSellerSlice";
import bannerSlice from "../slices/bannerSlice";
import modalSlice from "../slices/modalSlice";
import couponSlice from "../slices/couponSlice";
import comboProductSlice from "../slices/comboProductSlice";

const rootReducer  = combineReducers({
    auth: authReducer,
    cart:cartReducer,
    products : productSlice,
    bestSellerProducts: bestSellerSlice,
    category : categorySlice,
    wishlist :wishlistSlice,
    banners: bannerSlice,
    modal: modalSlice,
    coupon: couponSlice,
    selectedProducts: comboProductSlice
})

export default rootReducer