import { createSlice } from "@reduxjs/toolkit";

const WishlistSlice = createSlice({
  name: "wishlist",
  initialState: {
    wishlistData: [],
  },
  reducers: {
    addWishlistItem: (state, action) => {
      return {
        ...state,
        wishlistData: [...state.wishlistData, action.payload],
      };
    },
    removeWishlistItem: (state, action) => {
      state.wishlistData = state.wishlistData.filter((item) => item.product.id !== action.payload);
    },
    setAllWishlistItems: (state, action) => {
      state.wishlistData = action.payload;
    }
  }
})

export const { addWishlistItem, removeWishlistItem, setAllWishlistItems } = WishlistSlice.actions;
export default WishlistSlice.reducer;