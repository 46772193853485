import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { MdLocalShipping } from "react-icons/md";
import { Link, useLocation, useParams } from 'react-router-dom';
import Loader from "../Components/Common/Loader"
import { SiRazorpay } from "react-icons/si";
import toast from 'react-hot-toast';

const Example = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  const [loading, setLoading] = useState(true);
  const [paymentData, setPaymentData] = useState();
  const { order_id } = useParams();
  const [cancellationStatus, setCancellationStatus] = useState(false);
  const createdAt = paymentData?.data?.created_at;
  const dateObject = createdAt ? new Date(createdAt) : null;
  const userToken = JSON.parse(localStorage.getItem('token'));
  const [showModal, setShowModal] = useState(false);
  let formattedDate
  if (dateObject) {
    const year = dateObject.getFullYear();
    const month = dateObject.getMonth() + 1;
    const day = dateObject.getDate();
    formattedDate = `${day < 10 ? '0' : ''}${day}-${month < 10 ? '0' : ''}${month}-${year}`;
  }
  const responsedata = async () => {
    try {
      const response = await axios.get(`https://admin.theroarfit.com/api/order/${order_id}`);
      const result = response.data;
      setPaymentData(result);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching payment data:", error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    responsedata();
  }, [order_id]);

  const cancelOrder = async () => {
    const toastId = toast.loading("Loading...")
    try {
      const response = await axios.post(
        'https://admin.theroarfit.com/api/cancel-order',
        {
          "order_id": order_id,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      toast.success("Order canceled successfully")
      setCancellationStatus(true);
      setLoading(false);

    } catch (error) {
      setShowModal(true);
      setTimeout(() => {
        setShowModal(false);
      }, 3000);
      console.error("Error while canceling order:", error);
      setLoading(false);
    } finally {
      setLoading(false);
      toast.dismiss(toastId);
    }
  };

  const toggleModal = () => {
    setShowModal(!showModal);
    if (!showModal) {
      setTimeout(() => {
        setShowModal(false);
      }, 3000);
    }
  };



  return (
    <div className="container mx-auto p-4 max-w-7xl w-11/12 font-Quicksand">
      <div className="text-center mb-8">
        <div className="text-3xl font-bold "> <h1 className='text-green-600'>Thank you for your order</h1></div>
        <div className="text-gray-600 mt-2">  <p> Thank you for your purchase. Your payment has been successfully processed </p></div>
      </div>
      {loading && <div className='text-center'><Loader /></div>}
      {(!loading && !paymentData) &&
        <div className='h-screen'>
          <h1 className='text-center text-red-500'>Uh Oh! Somethiing went wrong , please try again </h1>
        </div>}
      <div className="flex flex-wrap -mx-4">
        {(!loading && paymentData) &&
          (
            <div className="w-full px-4 mb-8">
              <div className="bg-white p-6 rounded shadow">
                <div className="flex justify-between items-center mb-6 flex-wrap">
                  <p className="text-xl font-bold flex items-center justify-center"><span className='mr-2 text-2xl'><MdLocalShipping /></span>We'll send you an email with tracking information when your items ships. </p>
                  <Link to={"/"} className="ml-8 bg-transparent hover:bg-black text-black font-semibold hover:text-white py-0 px-4 border border-gray-500 hover:border-transparent rounded">
                    continue shopping
                  </Link>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                  <div>
                    <h3 className="text-lg font-bold mb-2">Order Detail</h3>
                    <div className='flex gap-1'><p className='font-semibold'>Order Id :</p> <p>{paymentData.data.order_details[0].order_id}</p></div>
                    <div className='flex gap-1'><p className='font-semibold'>Order Date :</p> <p>{formattedDate}</p></div>
                    <div className='flex gap-1'><p className='font-semibold'>Order Status :</p> <p>{paymentData.data.order_status}</p></div>
                    <div className='flex gap-1'><p className='font-semibold'>Payment Method :</p> <p>{paymentData.data.payment_mode}</p></div>
                    <div className='flex gap-1'><p className='font-semibold'>{(paymentData.data.payment_mode === "phonepe" || paymentData.data.payment_mode === "RazorPay") ? <>Paid Amount :</> : <> Payable Amount : </>}</p> <p>Rs{paymentData.data.payable_amount}</p></div>
                    <div className='flex gap-1'><p className='font-semibold'>{(paymentData.data.payment_mode === "phonepe" || paymentData.data.payment_mode === "RazorPay") ? <>Transaction Id :</> : <></>}</p> <p>{paymentData.data.payment_mode === "phonepe" ? <>{paymentData.data.payment?.transaction_id}</> : <></>}</p></div>
                    <div className='flex gap-1'><p className='font-semibold'>Payment Status :</p> <p>{paymentData.data.payment_status}</p></div>
                  </div>

                  <div>
                    <h3 className="text-lg font-bold mb-2">Shipping Address</h3>
                    <div className='flex gap-1'><p className='font-semibold'>Name :</p> <p>{paymentData.data.customer.shipping_name}</p></div>
                    <div className='flex gap-1'><p className='font-semibold'>Phone :</p> <p>{paymentData.data.customer.shipping_phone}</p></div>
                    <div className='flex gap-1'><p className='font-semibold'>Email :</p> <p>{paymentData.data.customer.shipping_email}</p></div>
                    <div className='flex gap-1'><p className='font-semibold'>Address :</p> <p>{paymentData.data.customer.customer_address.shipping_address_line1} {paymentData.data.customer.customer_address.shipping_address_line2}</p></div>
                    <div className='flex gap-1'><p className='font-semibold'>City :</p> <p>{paymentData.data.customer.customer_address.shipping_city}</p></div>
                    <div className='flex gap-1'><p className='font-semibold'>State :</p> <p>{paymentData.data.customer.customer_address.shipping_state}</p></div>
                    <div className='flex gap-1'><p className='font-semibold'>Country :</p> <p>{paymentData.data.customer.customer_address.shipping_country}</p></div>
                    <div className='flex gap-1'><p className='font-semibold'>Zip Code :</p> <p>{paymentData.data.customer.customer_address.shipping_zip_code}</p></div>
                  </div>

                  <div>
                    <h3 className="text-lg font-bold mb-2">Billing Address</h3>
                    <div className='flex gap-1'><p className='font-semibold'>Name :</p> <p>{paymentData.data.customer.billing_name}</p></div>
                    <div className='flex gap-1'><p className='font-semibold'>Phone :</p> <p>{paymentData.data.customer.billing_phone}</p></div>
                    <div className='flex gap-1'><p className='font-semibold'>Email :</p> <p>{paymentData.data.customer.billing_email}</p></div>
                    <div className='flex gap-1'><p className='font-semibold'>Address :</p> <p>{paymentData.data.customer.customer_address.shipping_address_line1} {paymentData.data.customer.customer_address.billing_address_line2}</p></div>
                    <div className='flex gap-1'><p className='font-semibold'>City :</p> <p>{paymentData.data.customer.customer_address.billing_city}</p></div>
                    <div className='flex gap-1'><p className='font-semibold'>State :</p> <p>{paymentData.data.customer.customer_address.billing_state}</p></div>
                    <div className='flex gap-1'><p className='font-semibold'>Country :</p> <p>{paymentData.data.customer.customer_address.billing_country}</p></div>
                    <div className='flex gap-1'><p className='font-semibold'>Zip Code :</p> <p>{paymentData.data.customer.customer_address.billing_zip_code}</p></div>
                  </div>
                </div>
              </div>
            </div>
          )
        }
        <div className="w-full px-4 mb-8">
          {(!loading && paymentData?.data.order_details && paymentData.data.order_details.length > 0) && (
            <div className="overflow-hidden">
              <section className="container mx-auto">
                <div className="w-full mb-8 overflow-hidden rounded-lg shadow-lg">
                  <div className="w-full overflow-x-auto">
                    <table className="w-full">
                      <thead>
                        <tr className="text-md font-semibold tracking-wide text-left text-gray-900 bg-gray-100  border-b border-gray-600">
                          <th className="px-4 py-3">No.</th>
                          <th className="px-4 py-3">Product detail</th>
                          <th className="px-4 py-3">Qty.</th>
                          <th className="px-4 py-3">Price</th>
                          <th className="px-4 py-3">Amount</th>
                        </tr>
                      </thead>
                      <tbody className="bg-white">
                        {!loading && paymentData.data.order_details.map((product, index) => {

                          const gstpercent = product.product?.gst;

                          const calculateGstAmount = (price, gstpercent) => (price * gstpercent) / 100;
                          const gstAmount = calculateGstAmount(product.product?.sale_price, gstpercent);

                          const regulaPriceWithGst = (Number(product.product?.regular_price) + gstAmount).toFixed(2);
                          const salePriceWithGst = (Math.round(product.product?.sale_price) + gstAmount).toFixed(2);

                          return <tr key={index} className="text-gray-700 font-semibold">
                            <td className="px-4 py-3 text-ms  border">{index + 1}</td>
                            <td className="px-4 py-3 text-ms  border">{product.product.name}</td>
                            <td className="px-4 py-3 text-xs  border">
                              {product.qty}
                            </td>
                            <td className="px-4 py-3 text-sm border whitespace-nowrap">{`Rs ${salePriceWithGst}`}</td>
                            <td className="px-4 py-3 text-sm border whitespace-nowrap">{`Rs ${product.qty * salePriceWithGst}`}</td>
                          </tr>
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </section>
              <div>
                {showModal && (
                  <div
                    id="popup-modal"
                    className="fixed top-0 right-0 left-0 z-50 flex justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full"
                    tabIndex="-1"
                  >
                    <div className="relative p-4 w-full max-w-md max-h-full">
                      <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                        <button
                          onClick={toggleModal}
                          type="button"
                          className="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                          data-modal-hide="popup-modal"
                        >
                          <svg
                            className="w-3 h-3"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 14 14"
                          >
                            <path
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                            />
                          </svg>
                          <span className="sr-only">Close modal</span>
                        </button>
                        <div className="p-4 md:p-5 text-center">
                          <svg
                            className="mx-auto mb-4 text-gray-400 w-12 h-12 dark:text-gray-200"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 20 20"
                          >
                            <path
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                            />
                          </svg>
                          <h3 className="mb-5 text-lg font-normal text-gray-400 dark:text-gray-200">
                            Please create an account with your registered email id and cancel your order
                          </h3>
                          <button
                            type="button"
                            className="text-white bg-red-500 hover:bg-red-700 focus:outline-none font-medium rounded-lg text-sm inline-flex items-center px-5 py-2 text-center"
                            data-modal-hide="popup-modal"
                          >
                            <Link to={"/signup"}>Create account</Link>
                          </button>
                          <button
                            type="button"
                            className=" ml-2 py-2 px-5 ms-3 text-sm font-medium text-white focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                            data-modal-hide="popup-modal"
                          >
                            <Link to={"/login"}>Login</Link>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              {
                (paymentData.data.payment_mode === "phonepe" || paymentData.data.payment_mode === "RazorPay") ? (
                  <>
                    {/* <div className="border-b dark:border-neutral-500 flex gap-4 items-center">
                      <div colSpan={4} className="whitespace-normal py-4 text-left font-bold text-black">
                        Prepaid Discount :
                      </div>
                      <div className="whitespace-nowrap px-6 py-4 text-left text-black font-bold">
                        Rs {Math.round(paymentData.data.discount)}
                      </div>
                    </div> */}
                    <div className="border-b dark:border-neutral-500 flex gap-2 items-center">
                      {paymentData?.data?.discount && paymentData?.data?.discount !== '' && (
                        <div className='text-gray-700 font-semibold'>Coupon Discount : {`Rs ${Math.round(paymentData?.data?.discount).toFixed(2)}`}</div>
                      )}
                      <div colSpan={4} className="whitespace-normal text-left font-bold text-black">
                        Grand Total :
                      </div>
                      <div className="whitespace-nowrap px-6 text-left text-black font-bold">
                        {`Rs ${Math.round(paymentData.data.total_amount - paymentData.data.discount).toFixed(2)}`}
                      </div>
                      {paymentData?.data?.discount && paymentData?.data?.discount !== '' && (
                        <div className='text-green-700 font-semibold'>Discount coupon applied</div>
                      )}
                    </div>

                  </>
                ) : (
                  <>
                    <div className="border-b dark:border-neutral-500 flex flex-col gap-2">
                      {paymentData?.data?.discount && paymentData?.data?.discount !== '' && (
                        <div className='text-gray-700 font-semibold'>Coupon Discount : {`Rs ${Math.round(paymentData?.data?.discount).toFixed(2)}`}</div>
                      )}
                      <div colSpan={4} className="whitespace-normal text-left font-bold text-black flex">
                        <p>Grand Total : {`Rs ${Math.round(paymentData.data.total_amount - paymentData.data.discount).toFixed(2)}`}</p>
                      </div>
                      {paymentData?.data?.discount && paymentData?.data?.discount !== '' && (
                        <div className='text-green-700 font-semibold'>Discount coupon applied</div>
                      )}
                      <Link to={`https://pay.theroarfit.com/pay-now-razorpay/${order_id}`}>
                        <button type="button" className="text-white bg-black focus:outline-none font-medium rounded-lg text-xs sm:text-sm px-2 py-1 text-center inline-flex items-center me-2 mb-2">
                          <SiRazorpay className='mr-1 text-blue-600' /> Click to Pay
                        </button>
                      </Link>
                    </div>
                  </>
                )
              }
              {
                (paymentData.data.payment_status === "Cancel" && paymentData.data.payment_mode === "RazorPay") ?
                  <Link to={`https://pay.theroarfit.com/pay-now-razorpay/${order_id}`}>
                    <button type="button" className="mr-2 text-white bg-black focus:outline-none font-medium rounded-lg text-xs sm:text-sm px-2 py-1 text-center inline-flex items-center me-2 mb-2">
                      <SiRazorpay className='mr-1 text-blue-600' /> Click to Pay
                    </button>
                  </Link> :
                  <></>
              }
              {!cancellationStatus && (
                <button onClick={cancelOrder} className="text-white bg-red-500 focus:outline-none font-medium rounded-lg text-sm px-2 py-1 text-center inline-flex  me-2 mb-2 mt-2">cancel order</button>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Example;
