import { RiCustomerService2Fill } from "react-icons/ri";
import { FaShippingFast } from "react-icons/fa";
import { GiReturnArrow } from "react-icons/gi";
import { RiSecurePaymentFill } from "react-icons/ri";

export const featureData = [
  {
    id: 1,
    title: "Customer Service",
    Icon: RiCustomerService2Fill,
    para:"Mon - Fri (10am - 6pm)"
  },
  {
    id: 2,
    title: "Free Shipping",
    Icon: FaShippingFast,
    para:"Free shipping on all orders"
  
  },
  {
    id: 3,
    title: "Hassle Free Returns",
    Icon: GiReturnArrow,
    para:"Buy with confidence today"
  },
  {
    id: 4,
    title: "Secure Payment",
    Icon: RiSecurePaymentFill,
    para:"Strong and consisten"
  },
];
