import React,{useEffect, useState} from 'react'
import axios from 'axios';

const UserProfile = () => {
  // const userString = localStorage.getItem("user");
  // const user = JSON.parse(userString);
  const userToken = JSON.parse(localStorage.getItem('token'));
  const [profile,setProfile] = useState();

  const getUser = async() => {
    try {
      const response = await axios.get(
        'https://admin.theroarfit.com/api/user',
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      
      setProfile(response);
      console.log("user profile",profile)
  
    } catch (error) {
      console.error('Error while getting user profile:', error);
    }
  };


  useEffect(() => {
    // Scroll to the top of the page when the component mounts
     window.scrollTo({ top: 0, behavior: 'smooth' });
    getUser();
  }, []);
  return (
    <div>
      <div className="">
        <div className=" bg-gray-50 p-8 rounded shadow-md w-11/12">
         <div className='flex items-center gap-5'>
         <div className="flex justify-center gap-3 ">
            <img
              src={`https://api.dicebear.com/5.x/initials/svg?seed=${profile?.data?.name}`}
              alt="user"
              className="rounded-full w-24 h-24 object-cover bg-slate-300"
            />
            <h1 className="text-2xl font-bold mb-4 text-black py-7">Welcome <span>{profile?.data?.name}</span></h1>
          </div>
          
         </div>
          <div className="flex gap-4 mt-5">
            <div className='w-full space-y-5'>
              <div>
                <p className="block text-gray-700 text-sm font-bold mb-2" > Name </p>
                <p className="border rounded-lg px-3 py-1 h-10 w-full">{profile?.data?.name}</p>
              </div>
              <div>
                <p className="block text-gray-700 text-sm font-bold mb-2" > Email </p>
                <p className="border rounded-lg px-3 py-1 h-10 w-full">{profile?.data?.email}</p>
              </div>
              <div>
                <p className="block text-gray-700 text-sm font-bold mb-2" >Date of Birth </p>
                <p className="border rounded-lg px-3 py-1 h-10 w-full">{profile?.data?.dob}</p>
              </div>
              <div>
                <p className="block text-gray-700 text-sm font-bold mb-2" > Phone </p>
                <p className="border rounded-lg px-3 py-1 w-full h-10">{profile?.data?.phone} </p>
              </div>
              <div>
                <p className="block text-gray-700 text-sm font-bold mb-2" > Address </p>
                <p className="border rounded-lg px-3 py-1 h-10 w-full">{profile?.data?.address}</p>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  )
}

export default UserProfile