import React, { useState,useEffect } from 'react';
import { FcGoogle } from 'react-icons/fc';
import { FaFacebookF } from 'react-icons/fa6';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { toast } from 'react-hot-toast';
import { useForm } from 'react-hook-form';
import { signUp } from '../services/operations/authAPI';
import img from '../Assets/login-img.webp';
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai"


const SignUp = () => {
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
      window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  const { register, handleSubmit, formState: { errors } } = useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)

  const onSubmit = async (data) => {
    if (data.password !== data.password_confirmation) {
      toast.error('Passwords Do Not Match');
      return;
    }

    dispatch(signUp(data.name, data.email, data.phone, data.password, data.password_confirmation, navigate));
  };

  return (
    <div className="pb-36 pt-5">
      <section className="w-11/12 lg:max-w-7xl flex flex-col md:flex-row justify-center space-y-10 md:space-y-0 md:space-x-16 items-center my-2 mx-5 md:mx-0 md:my-0">
        <div className="md:w-1/3 max-w-sm">
          <img src={img} alt="Sample signupimage" loading="lazy" />
        </div>
        <div className="md:w-1/3 max-w-sm ">
          <form onSubmit={handleSubmit(onSubmit)}>
            {/* <div className="text-center md:text-left">
              <label className="mr-1">Sign up with</label>
              <button
                type="button"
                className="mx-1 h-9 w-9 rounded-full bg-white hover:bg-blue-200 text-white shadow-[0_4px_9px_-4px_#3b71ca]"
              >
                <FcGoogle className="mx-auto h-3.5 w-3.5" />
              </button>
              <button
                type="button"
                className="mx-1 h-9 w-9 rounded-full bg-white hover:bg-blue-200 text-blue-800 shadow-[0_4px_9px_-4px_#3b71ca]"
              >
                <FaFacebookF className="mx-auto h-3.5 w-3.5" />
              </button>
            </div> */}
            <div className="my-5 flex items-center before:mt-0.5 before:flex-1 before:border-t before:border-neutral-300 after:mt-0.5 after:flex-1 after:border-t after:border-neutral-300">
              <p className="mx-4 mb-0 text-center font-semibold text-slate-500">Sign up now</p>
            </div>
            <input
              {...register("name", { required: true })}
              className={`text-sm w-full px-4 py-2 border border-solid border-gray-300 rounded focus:outline-none ${errors.name ? 'border-red-500' : ''}`}
              type="text"
              placeholder="Enter Your Name"
            />
            {errors.name && <span className="text-red-500 text-sm">Name is required</span>}
            <input
              {...register("email", { required: true })}
              className={`text-sm w-full px-4 py-2 border border-solid border-gray-300 rounded mt-4 focus:outline-none ${errors.email ? 'border-red-500' : ''}`}
              type="email"
              placeholder="Enter Your Email Address"
            />
            {errors.email && <span className="text-red-500 text-sm">Email is required</span>}
            <input
              {...register("phone", { required: true })}
              className={`text-sm w-full px-4 py-2 border border-solid border-gray-300 rounded mt-4 focus:outline-none ${errors.phone ? 'border-red-500' : ''}`}
              type="tel"
              placeholder="Enter Your Contact Number"
            />
            {errors.phone && <span className="text-red-500 text-sm">Phone is required</span>}
            <div className="relative mt-4">
              <input
                {...register("password", { required: true, minLength: 8 })}
                className={`text-sm w-full px-4 py-2 border border-solid border-gray-300 rounded focus:outline-none ${errors.password ? 'border-red-500' : ''}`}
                type={showPassword ? "text" : "password"}
                placeholder="Password"
              />
              <span
                onClick={() => setShowPassword((prev) => !prev)}
                className="absolute right-4 top-2 z-10 cursor-pointer text-red-500"
              >
                {showPassword ? (
                  <AiOutlineEyeInvisible fontSize={24} fill="#AFB2BF" />
                ) : (
                  <AiOutlineEye fontSize={24} fill="#AFB2BF" />
                )}
              </span>
            </div>
            {errors.password && <span className="text-red-500 text-sm">Password is required and must be at least 8 characters</span>}
            <div className="relative">
            <input
              {...register("password_confirmation", { required: true, minLength: 8 })}
              className={`text-sm w-full px-4 py-2 border border-solid border-gray-300 rounded mt-4 focus:outline-none ${errors.password_confirmation ? 'border-red-500' : ''}`}
              type={showConfirmPassword ? "text" : "password"}
              placeholder="Confirm Password"
            />
            <span
              onClick={() => setShowConfirmPassword((prev) => !prev)}
              className="absolute right-4 top-6 z-[10] cursor-pointer"
            >
              {showConfirmPassword ? (
                <AiOutlineEyeInvisible fontSize={24} fill="#AFB2BF" />
              ) : (
                <AiOutlineEye fontSize={24} fill="#AFB2BF" />
              )}
            </span>
            </div>
            {errors.password_confirmation && <span className="text-red-500 text-sm">Password confirmation is required and must be at least 8 characters</span>}
            <div className="text-center md:text-left">
              <button
                className="mt-4 bg-black hover:bg-white px-4 py-2 text-white hover:text-black  hover:border uppercase rounded text-xs tracking-wider"
                type="submit"
              >
                Create Account
              </button>
            </div>
            <div className="mt-4 font-semibold text-sm text-slate-500 text-center md:text-left">
              You have already an account? <Link className="text-red-600 hover:underline hover:underline-offset-4" to="/login">Login</Link>
            </div>
          </form>
        </div>
      </section>
    </div>
  );
};

export default SignUp;
