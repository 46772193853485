import React from 'react'
import gif from "../../Assets/loading_cart.gif"

const Loader = () => {
  return (
    // <div className='w-full flex justify-center items-center'>
    //     <img src={gif} alt="waiting for loading the data" />
    // </div>
    <div className="spinner-container">
      <div className="spinner"></div>
    </div>

  )
}

export default Loader