import React, { useState } from 'react';
import { Link,useNavigate } from 'react-router-dom';
import { logout } from '../../services/operations/authAPI';
import { useDispatch } from 'react-redux';
import {BsPersonFill} from "react-icons/bs"

const Dropdown = () => {
const [isOpen, setIsOpen] = useState(false);
const dispatch = useDispatch();
const navigate = useNavigate();


  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleLogout = (event) => {
    event.preventDefault();
    dispatch(logout(navigate));
    setIsOpen(false);
  };

  return (
    <div className="relative inline-block text-left">
      <button
        id="dropdownDefaultButton"
        onClick={toggleDropdown}
        className="text-white  focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm px-2 py-2 text-center inline-flex items-center"
        type="button"
      >
        <BsPersonFill className='text-black text-[1.75rem]'/>{' '}
        {/* <svg
          className={`w-2.5 h-2.5 ml-2.5 transform ${isOpen ? 'rotate-180' : 'rotate-0'}`}
          aria-hidden="true"
          fill="none"
          viewBox="0 0 10 6"
        >
          <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 4 4 4-4" />
        </svg> */}
      </button>

      {/* Dropdown menu */}
      {isOpen && (
        <div className="z-10 absolute right-0 mt-2 w-[8rem] bg-white divide-y text-black rounded-lg shadow">
          <ul className="py-2 text-sm text-black" aria-labelledby="dropdownDefaultButton">
            <li className='pl-2 pr-2'>
              <Link to={'/userdashboard'} className="block px-4 py-2 hover:bg-gray-300 hover:text-black rounded-md w-full"  onClick={() => setIsOpen(false)}>
                Dashboard
              </Link>
            </li>
            <li className='pl-2 pr-2'>
              <Link  className="block px-4 py-2 hover:bg-gray-300 hover:text-black rounded-md w-full" onClick={handleLogout}>
                Log out
              </Link>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};

export default Dropdown;
