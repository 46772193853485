import { createSlice } from '@reduxjs/toolkit';

export const modalSlice = createSlice({
  name: 'modal',
  initialState: {
    show: false,
    product: null,
  },
  reducers: {
    openModal: (state, action) => {
      state.show = true;
    },
    closeModal: (state) => {
      state.show = false;
      state.product = null;
    },
    setProduct: (state, action) => {
      state.product = action.payload; 
    },

  },
});

export const { openModal, closeModal, setProduct } = modalSlice.actions;

export default modalSlice.reducer;
