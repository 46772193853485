import React,{useEffect} from 'react'
import FoundingStory from "../Assets/jersey-image.avif"


const AboutUs = () => {
    useEffect(() => {
        // Scroll to the top of the page when the component mounts
         window.scrollTo({ top: 0, behavior: 'smooth' });
      }, []);
    return (
        <div className='pt-5 font-Quicksand mx-auto w-11/12 max-w-[85rem]'>
            <header className="py-2 text-4xl font-semibold w-[95%] max-w-7xl text-center">
                <p className='bg-gradient-to-br from-[#833AB4] via-[#FD1D1D] to-[#FCB045] bg-clip-text text-4xl font-semibold text-transparent'>Crafting Quality Experiences and Connecting Through Commerce </p>
                <p className="mx-auto mt-3 text-center text-base font-medium text-richblack-300 w-[80%] max-w-7xl">
                    Roarfit: Empowering Fitness Journeys with Top-notch Gym Equipment and a Vibrant Community Spirit
                </p>
            </header>
            <section>
                <div className="mx-auto flex w-11/12 max-w-7xl flex-col justify-between gap-5 text-richblack-500">
                    <div className="flex flex-col items-center gap-10 lg:flex-row justify-between">
                        <div className="my-20 flex lg:w-[50%] flex-col gap-5">
                            <h1 className="bg-gradient-to-br from-[#833AB4] via-[#FD1D1D] to-[#FCB045] bg-clip-text text-4xl font-semibold text-transparent lg:w-[70%] pb-4">
                                Our Founding Story
                            </h1>
                            <p className="text-base font-medium text-richblack-300 lg:w-[95%]">
                                Roarfit: Igniting Fitness Transformations with a Collective Vision for Quality Gym Equipment. Our journey started with a community of fitness enthusiasts, tech innovators, and lifelong health advocates. Recognizing the demand for accessible, flexible, and premium fitness solutions in today's dynamic world, we embarked on a mission to reshape the fitness landscape.
                            </p>
                            <p className="text-base font-medium text-richblack-300 lg:w-[95%]">
                                With a deep understanding of fitness education, we've encountered the constraints of conventional training methods. Our belief is simple: fitness should be accessible beyond the confines of a gym or limited by location. We envisioned a platform breaking these barriers, empowering individuals from every corner to unleash their full fitness potential.
                            </p>
                        </div>

                        <div>
                            <img
                                src={FoundingStory}
                                alt="founding story img"
                                className="shadow-[0_0_20px_0] shadow-gray-200"
                            />
                        </div>
                    </div>
                    <div className="flex flex-col items-center lg:gap-10 lg:flex-row justify-between">
                        <div className="my-7 sm:my-0 flex lg:w-[40%] flex-col gap-5">
                            <h1 className="bg-gradient-to-b from-[#FF512F] to-[#F09819] bg-clip-text text-4xl font-semibold text-transparent lg:w-[70%] ">
                                Our Vision
                            </h1>
                            <p className="text-base font-medium text-richblack-300 lg:w-[95%] sm:pb-10">
                                Fuelled by this vision, we embarked on a mission to redefine the fitness learning landscape. Our devoted team of experts put in relentless effort to craft an innovative e-commerce platform that seamlessly integrates state-of-the-art technology with captivating content. The result is a dynamic and interactive fitness shopping experience like never before.
                            </p>
                        </div>
                        <div className="my-7 sm:my-0 flex lg:w-[40%] flex-col gap-5">
                            <h1 className="bg-gradient-to-b from-[#1FA2FF] via-[#12D8FA] to-[#A6FFCB] text-transparent bg-clip-text text-4xl font-semibold lg:w-[70%] ">
                                Our Mission
                            </h1>
                            <p className="text-base font-medium text-richblack-300 lg:w-[95%] sm:pb-10">
                                At Roarfit, our mission extends beyond merely selling gym equipment. We aspire to cultivate a dynamic community of fitness enthusiasts, providing a space for individuals to connect, collaborate, and learn together. We are firm believers that fitness thrives in an environment of shared experiences and dialogue. To nurture this spirit, we facilitate collaboration through forums, live sessions, and networking opportunities, fostering a vibrant fitness community.                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default AboutUs