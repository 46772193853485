import React,{useEffect} from 'react';

const ShippingPolicyPage = () => {
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
      window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);
  return (
    <div className="container mx-auto w-11/12 max-w-7xl pt-5 mb-12 p-4">
      <h1 className="text-3xl font-bold mb-4">Shipping Policy for Gym Equipment</h1>
      <p className="mb-4">
        Our shipping policy outlines the details regarding the shipment of gym equipment and related products.
      </p>

      <ol className="list-decimal pl-4">
        <li className="mb-2">
          We offer standard shipping for all gym equipment orders.
        </li>
        <li className="mb-2">
          Shipping costs are calculated based on the weight and dimensions of the items in your order.
        </li>
        <li className="mb-2">
          Please allow 1-2 business days for order processing before shipment.
        </li>
        <li className="mb-2">
          Delivery times vary depending on your location. Standard shipping typically takes 5-7 business days.
        </li>
        <li className="mb-2">
          Expedited shipping options are available at checkout for an additional fee.
        </li>
        <li className="mb-2">
          Orders are shipped via reputable carriers with tracking information provided.
        </li>
        <li className="mb-2">
          Shipping delays may occur during peak seasons or due to unforeseen circumstances.
        </li>
        <li className="mb-2">
          We are not responsible for lost or stolen packages once they are marked as delivered.
        </li>
        <li className="mb-2">
          For international shipping inquiries, please contact our customer service.
        </li>
      </ol>
    </div>
  );
};

export default ShippingPolicyPage;
