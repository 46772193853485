import React, { useState, useEffect } from 'react';
import Banner from '../Components/Homepage/Banner'
import HomeProducts from '../Components/Homepage/HomeProducts';
import BestSellerProducts from '../Components/Homepage/BestSellerProducts';
import QuickAddModal from '../Components/Common/QuickAddModal';
import { useSelector} from 'react-redux';


const Homepage = () => {
  const showModal = useSelector((state) => state.modal.show);

  return (
    <div className='relative'>
      <Banner />
      <BestSellerProducts/>
      <HomeProducts/>
      <QuickAddModal showModal={showModal} />
    </div>

  )
}

export default Homepage