import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Pagination, Navigation } from 'swiper/modules';
import BestSellerProduct from '../Product/BestSellerProduct';
import { addBestSellerProducts } from '../../slices/bestSellerSlice';
import { IoIosArrowDroprightCircle } from "react-icons/io";
import { useNavigate } from 'react-router-dom';
import axios from 'axios';



const BestSellerProducts = () => {
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const bestSellerProducts = useSelector(state => state.bestSellerProducts.bestSellerProducts);
    const navigate = useNavigate();

    async function fetchBestSellerData() {
        setLoading(true);
        try {
            const response = await axios.get('https://admin.theroarfit.com/api/get-bestseller-products')
            const bestSellerproducts = response?.data?.data?.data;
            dispatch(addBestSellerProducts(bestSellerproducts));
            setLoading(false);
        } catch (error) {
            console.error('Error while fetching best seller products:', error);
            setLoading(false);
        }
    }

    useEffect(() => {
        if (bestSellerProducts.length === 0) {
            fetchBestSellerData();
        }
    }, [dispatch, bestSellerProducts]);


    const navigateToPage = () => {
        navigate("/best-seller");
    };

    return (
        <div className=' max-w-7xl w-11/12 pt-4 mx-auto'>
            <div className='flex justify-between items-center'>
                <p className='text-2xl font-bold sm:text-3xl'>Best Seller</p>
                <button onClick={navigateToPage} className='flex items-center gap-1 group text-black'>View all <IoIosArrowDroprightCircle className='text-2xl text-gray-500 group-hover:text-black' /></button>
            </div>
            <div className=''>
                <Swiper
                    slidesPerView={4}
                    spaceBetween={30}
                    navigation={true}
                    modules={[Pagination, Navigation]}
                    className="mySwiper py-5 pl-2"
                    breakpoints={{
                        200: {
                            slidesPerView: 1.25,
                            spaceBetween: 20
                        },
                        300: {
                            slidesPerView: 1.25,
                            spaceBetween: 20
                        },
                        400: {
                            slidesPerView: 1.25,
                            spaceBetween: 20
                        },
                        568: {
                            slidesPerView: 3,
                            spaceBetween: 30
                        },
                        850: {
                            slidesPerView: 4,
                            spaceBetween: 30
                        },
                    }}
                >
                    {bestSellerProducts.map(product => (
                        <SwiperSlide key={product.id}>
                            <BestSellerProduct product={product} />
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
        </div>
    );
}

export default BestSellerProducts;


// import React, { useEffect, useState, useCallback } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import { Swiper, SwiperSlide } from 'swiper/react';
// import 'swiper/css';
// import 'swiper/css/pagination';
// import 'swiper/css/navigation';
// import { Pagination, Navigation } from 'swiper/modules';
// import { addBestSellerProducts } from '../../slices/bestSellerSlice';
// import { IoIosArrowDroprightCircle } from "react-icons/io";
// import { useNavigate } from 'react-router-dom';
// import axios from 'axios';
// import Loader from '../Common/Loader';
// import BestSellerProduct from '../Product/BestSellerProduct';


// const BestSellerProducts = () => {
//     const [loading, setLoading] = useState(false);
//     const [error, setError] = useState(null);
//     const dispatch = useDispatch();
//     const bestSellerProducts = useSelector(state => state.bestSellerProducts.bestSellerProducts);
//     const navigate = useNavigate();

//     const fetchBestSellerData = useCallback(async () => {
//         setLoading(true);
//         setError(null);
//         try {
//             const response = await axios.get('https://admin.theroarfit.com/api/get-bestseller-products');
//             const bestSellerProducts = response?.data?.data?.data;
//             dispatch(addBestSellerProducts(bestSellerProducts));
//         } catch (error) {
//             console.error('Error while fetching best seller products:', error);
//             setError('Failed to load best seller products. Please try again later.');
//         } finally {
//             setLoading(false);
//         }
//     }, [dispatch]);

//     useEffect(() => {
//         if (bestSellerProducts.length === 0) {
//             fetchBestSellerData();
//         }
//     }, [fetchBestSellerData, bestSellerProducts.length]);

//     const navigateToPage = () => {
//         navigate("/best-seller");
//     };

//     return (
//         <>
//         <div className='max-w-7xl w-11/12 pt-4 mx-auto'>
//             <div className='flex justify-between items-center'>
//                 <p className='text-2xl font-bold sm:text-3xl'>Best Seller</p>
//                 <button onClick={navigateToPage} className='flex items-center gap-1 group text-black'>
//                     View all <IoIosArrowDroprightCircle className='text-2xl text-gray-500 group-hover:text-black' />
//                 </button>
//             </div>
//             {loading && <Loader />}
//             {error && <div className="error-message">{error}</div>}
//             {!loading && !error && (
//                 <div className=''>
//                     <Swiper
//                         slidesPerView={4}
//                         spaceBetween={30}
//                         navigation={true}
//                         modules={[Pagination, Navigation]}
//                         className="mySwiper py-5 pl-2"
//                         breakpoints={{
//                             200: {
//                                 slidesPerView: 1.25,
//                                 spaceBetween: 20
//                             },
//                             300: {
//                                 slidesPerView: 1.25,
//                                 spaceBetween: 20
//                             },
//                             400: {
//                                 slidesPerView: 1.25,
//                                 spaceBetween: 20
//                             },
//                             568: {
//                                 slidesPerView: 3,
//                                 spaceBetween: 30
//                             },
//                             850: {
//                                 slidesPerView: 4,
//                                 spaceBetween: 30
//                             },
//                         }}
//                     >
//                         {bestSellerProducts.map(product => (
//                             <SwiperSlide key={product.id}>
//                                     <BestSellerProduct product={product} />
//                             </SwiperSlide>
//                         ))}
//                     </Swiper>
//                 </div>
//             )}
//         </div>
//         </>
//     );
// }

// export default BestSellerProducts;



