// ProductSlice.js
import { createSlice } from "@reduxjs/toolkit";

const ProductSlice = createSlice({
    name: "Products",
    initialState: {
        products: [],
    },
    reducers: {
        addProducts: (state, action) => {
            state.products = action.payload; 
        }
    }
});

export const { addProducts } = ProductSlice.actions;
export default ProductSlice.reducer;
