import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import CartItem from '../Components/Cart/CartItem';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import Loader from "../Components/Common/Loader";
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { remove } from '../slices/cartSlice';

function CartPage() {
  const [totalAmount, setTotalAmount] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const shippingCharges = 0;
  const [cartItems, setCartItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let stock = 0;

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  
  useEffect(() => {
    const fetchCartItems = async () => {
      const sessionId = getSessionId();
      try {
        const response = await axios.get('https://admin.theroarfit.com/api/cart/view', {
          headers: {
            'Content-Type': 'application/json',
            'X-Session-ID': sessionId,
          },
        });
        const cartItemsData = response.data.data;
        setCartItems(cartItemsData);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching cart items:', error);
      }
    };

    fetchCartItems();
  }, []);




  useEffect(() => {
    const calculatedTotalAmount = cartItems.reduce((acc, curr) => {
      const gstpercent = curr.product?.gst;
      const calculateGstAmount = (price, gstpercent) => (price * gstpercent) / 100;
      const gstAmount = calculateGstAmount(curr.product?.sale_price, gstpercent);
      const totalItemAmountWithGST = (Number(curr.product?.sale_price) + Number(gstAmount)) * curr.quantity;
      return acc + totalItemAmountWithGST;
    }, 0);

    const calculatedTotalItems = cartItems.reduce((acc, curr) => {
      return Number(acc) + Number(curr.quantity);
    }, 0);

    setTotalAmount(calculatedTotalAmount);
    setTotalItems(calculatedTotalItems);
  }, [cartItems]);


  // const handleCheckout = () => {
  //   const allItemsInStock = cartItems.every((item) => {
  //     const selectedSize = item.variations.size;
  //     const selectedVariation = item.product.variations.find(variation =>
  //       variation.variations.some(variant => variant.size === selectedSize)
  //     );

  //     if (selectedVariation) {
  //       const stockItem = selectedVariation.variations.find(variant =>
  //         variant.size === selectedSize
  //       );
  //       return stockItem && stockItem.stock > 0;
  //     }
  //     return false;
  //   });
  //   if (allItemsInStock) {
  //     navigate("/checkout");
  //   } else {
  //     toast.error("Cannot proceed to checkout. Some items are out of stock.");
  //   }
  // };

  const handleCheckout = ()=>{
    navigate("/checkout");
  }



  const getSessionId = () => {
    let sessionId = localStorage.getItem('sessionId');
    if (!sessionId) {
      sessionId = uuidv4();
      localStorage.setItem('sessionId', sessionId);
    }
    return sessionId;
  };

  const removeFromCart = async (itemId) => {
    try {
      const response = await axios.delete(`https://admin.theroarfit.com/api/cart/remove/${itemId}`)
      if (response.status === 200) {
        const updatedCart = cartItems.filter((cartItem) => cartItem.id !== itemId);
        setCartItems(updatedCart);
        dispatch(remove(itemId));
      } else {
        console.error('Failed to remove item from the cart');
      }
    } catch (error) {
      console.error('Error removing item from the cart:', error);
    }
  };

  const increaseItemQuantity = async (itemId, quantity, maxStock) => {
    if (quantity < maxStock) {
      try {
        const response = await axios.put(`https://admin.theroarfit.com/api/cart/update/${itemId}`, { quantity: Number(quantity) + 1 })
        const newCartData = cartItems.map((data) => {
          if (data.id === response.data.data.id) {
            data.quantity = response.data.data.quantity
            return data;
          }
          return data;
        })
        setCartItems(newCartData)
      } catch (error) {
        console.error('Error updating item from the cart:', error);
      }
    } else {
      toast.error("Cannot increase quantity beyond available stock.");
    }
  };

  const decreaseItemQuantity = async (itemId, quantity) => {
    if (Number(quantity) === 1) {
      removeFromCart(itemId);
    } else {
      try {
        const response = await axios.put(`https://admin.theroarfit.com/api/cart/update/${itemId}`, { quantity: Number(quantity) - 1 })
        const newCartData = cartItems.map((data) => {
          if (data.id === response.data.data.id) {
            data.quantity = response.data.data.quantity
            return data;
          }
          return data;
        })
        setCartItems(newCartData)
      } catch (error) {
        console.error('Error updating item from the cart:', error);
      }
    }
  };

  const handleTotalStock = (totalStock) => {
    stock = totalStock;
  };


  return (
    <div className='h-full bg-white pt-5 pb-24 font-Quicksand w-11/12 max-w-7xl mx-auto'>

      {loading ? (
        <Loader />
      ) : cartItems.length > 0 ? (
        <div>
          <h1 className="mb-10 text-center text-2xl font-bold pt-10">Cart Items</h1>
          <div className='mx-auto max-w-5xl justify-center px-6 md:flex md:space-x-6 xl:px-0'>
            <div className='rounded-lg md:w-2/3'>
              {cartItems.map((cartItem, index) => (
                <CartItem
                  key={index}
                  item={cartItem.product}
                  fullItem={cartItem}
                  quantity={cartItem.quantity}
                  removeFromCart={() => removeFromCart(cartItem.id)}
                  increaseItemQuantity={() => increaseItemQuantity(cartItem.id, cartItem.quantity, stock)}
                  decreaseItemQuantity={() => decreaseItemQuantity(cartItem.id, cartItem.quantity)}
                  handleTotalStock={handleTotalStock}
                />
              ))}
            </div>

            <div className='mt-6 h-full rounded-lg border bg-white p-6 shadow-[0_3px_10px_rgb(0,0,0,0.2)] md:mt-0 md:w-1/3'>
              <div className="mb-2 flex justify-between">
                <p className="text-gray-900 font-semibold">Total Items</p>
                <p className="text-gray-900 font-semibold">{totalItems}</p>
              </div>
              <div className="mb-2 flex justify-between">
                <p className="text-gray-900 font-semibold">Subtotal Amount</p>
                <p className="text-gray-900 font-semibold">Rs. {Math.round(totalAmount).toFixed(2)}</p>
              </div>
              <div className="flex justify-between">
                <p className="text-gray-900 font-semibold">Shipping</p>
                <p className="text-gray-900 font-semibold">Rs. {shippingCharges}</p>
              </div>
              <hr className="my-4" />
              <div className="flex justify-between">
                <p className="text-lg font-bold">Total Paybill</p>
                <div>
                  <p className="mb-1 text-lg font-bold text-right">Rs. {Math.round(totalAmount + shippingCharges).toFixed(2)}</p>
                  <p className="text-sm text-gray-700 text-right font-semibold ">including GST</p>
                </div>
              </div>
              <button onClick={handleCheckout} className="mt-6 w-full rounded-md bg-black py-1.5 text-white font-semibold">
                Checkout Now
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div className='flex flex-col justify-center items-center h-[50%] sm:h-screen gap-3'>
          <h1 className='text-black font-bold'>Cart Empty</h1>
          <Link to='/'>
            <button className='bg-black text-white font-bold py-2 px-4 rounded w-full'>Shop Now</button>
          </Link>
        </div>
      )}
    </div>
  );
}

export default CartPage;
