import React, { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Product from '../../Components/Product/Product';
import { getAllProducts } from "../../services/operations/productDetailsAPI";
import Loader from '../Common/Loader';
import { addProducts } from '../../slices/productSlice';

const HomeProducts = () => {
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const products = useSelector(state => state.products.products);

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        if (products.length === 0) {
            setLoading(true);
            getAllProducts()
                .then(result => {
                    const data = result.data.data;
                    dispatch(addProducts(data));
                })
                .catch(error => {
                    console.log("Error occurred during fetching all products", error);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }, [dispatch, products]);

    return (
        <div>
            <div className='pt-8 pb-12 '>
                {
                    loading ? <Loader /> :
                        products.length > 0 ?
                            (
                                <>
                                    <p className='text-2xl font-bold sm:text-3xl max-w-7xl w-11/12  mx-auto '>Shop Our Best Collections</p>
                                    <div className="max-w-7xl w-11/12 pt-4 mx-auto grid gap-2 grid-cols-2 md:gap-2 sm:grid-cols-3 lg:grid-cols-4 my-5">
                                        {
                                            products.map((product) => {
                                                return <Product key={product.id} product={product} />;
                                            })
                                        }
                                    </div>
                                </>
                            ) :
                            <div className="flex justify-center items-center">
                                <p>No Data Found...</p>
                            </div>
                }
            </div>
        </div>
    );
}

export default HomeProducts;


