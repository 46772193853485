import React, { useEffect } from 'react';


const PrivacyPolicyPage = () => {
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
      window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  return (
    <div className="container mx-auto w-11/12 max-w-7xl pt-5 mb-12 p-4">
      <h1 className="text-3xl font-bold mb-4">Privacy Policy</h1>
      <p className="mb-4">
        This privacy policy describes how we collect, use, and protect your personal information.
      </p>

      <ol className="list-decimal pl-4">
        <li className="mb-2 ">
          <p className='font-bold'>Information We Collect:</p>
          <ul>
            <li>Name, email address, and contact information</li>
            <li>Shipping and billing addresses</li>
            <li>Order details and purchase history</li>
            <li>Browsing and usage data</li>
          </ul>
        </li>
        <li className="mb-2">
          <p className='font-bold'>How We Use Information:</p>
          <ul>
            <li>Processing and fulfilling orders</li>
            <li>Communicating with you about your orders and account</li>
            <li>Providing customer service</li>
            <li>Improving our products and services</li>
            <li>Personalizing your shopping experience</li>
          </ul>
        </li>
        <li className="mb-2">
          <p className='font-bold'>Sharing Information:</p>
          <ul>
            <li>We may share information with third-party service providers who assist us in our operations (e.g., payment processors, shipping providers).</li>
            <li>We may disclose information if required by law or to protect our rights or property.</li>
          </ul>
        </li>
        <li className="mb-2">
          <p className='font-bold'>Data Security:</p>
          <ul>
            <li>We take appropriate security measures to protect your personal information.</li>
            <li>We use industry-standard encryption technology to protect sensitive data.</li>
          </ul>
        </li>
        <li className="mb-2">
          <p className='font-bold'>Your Choices:</p>
          <ul>
            <li>You can access and update your personal information in your account settings.</li>
            <li>You can opt out of marketing communications.</li>
          </ul>
        </li>
      </ol>
    </div>
  );
};

export default PrivacyPolicyPage;
