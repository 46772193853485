import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import toast from 'react-hot-toast';
import axios from 'axios';
import WishlistItem from "../../WishList/WishlistItem";
import { MdDeleteForever } from "react-icons/md";
import { FaCartPlus } from "react-icons/fa";
import { add } from "../../../slices/cartSlice";
import Loader from '../../Common/Loader';
import { v4 as uuidv4 } from 'uuid';
import wishlistSlice from '../../../slices/wishlistSlice';
import { addWishlistItem, removeWishlistItem, setAllWishlistItems } from '../../../slices/wishlistSlice';


export const getAllWishlistItems = async (userToken, dispatch, setAllWishlistItems) => {
  try {
    const response = await axios.get(
      'https://admin.theroarfit.com/api/wishlist/view',
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userToken}`,
        },
      }
    );

    const fetchedWishlistItems = response.data.data;
    console.log("fetchedwishist items ", response.data.data);
    dispatch(setAllWishlistItems(fetchedWishlistItems));

  } catch (error) {
    console.error('Error retrieving wishlist items:', error);
  }
};

function UserWishlist() {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);
  const dispatch = useDispatch();
  const userToken = JSON.parse(localStorage.getItem('token'));
  const [loading, setLoading] = useState(false);
  const { wishlistData } = useSelector(state => state.wishlist);
  console.log("slice wishlist item data", wishlistData)

  const [selectedSize, setSelectedSize] = useState('');
  const [selectedColor, setSelectedColor] = useState('');
  const [selectedThickness, setSelectedThickness] = useState('');

  const handleSelectSize = (selectedSize) => {
    console.log('Selected Size in UserWishlist:', selectedSize);
    setSelectedSize(selectedSize);
  };

  const handleSelectColor = (selectedColor) => {
    console.log('Selected Color in UserWishlist:', selectedColor);
    setSelectedColor(selectedColor);
  };

  const handleSelectThickness = (selectedThickness) => {
    console.log('Selected Thickness in UserWishlist:', selectedThickness);
    setSelectedThickness(selectedThickness);
  };

  useEffect(() => {
    if (wishlistData.length === 0) {
      setLoading(true);
      getAllWishlistItems(userToken, dispatch, setAllWishlistItems)
      setLoading(false);
    };
  }, []); // Empty dependency array means this effect runs once on mount

  // Function to get or generate a session ID
  const getSessionId = () => {
    let sessionId = localStorage.getItem('sessionId');
    if (!sessionId) {
      sessionId = uuidv4();
      localStorage.setItem('sessionId', sessionId);
    }
    return sessionId;
  };

  const handleAddToCart = async (productId) => {
    const sessionId = getSessionId();
    const toastId = toast.loading("Loading...");

    // Check if the product has options
    // if (hasOptions) {
    //   // Validate if all options are selected
    //   if (!selectedSize || !selectedColor || !selectedThickness) {
    //     toast.error('Please select size, color, and thickness before adding to the cart.');
    //     return;
    //   }
    // }

    try { 
      
      console.log("inside add to cart",productId)
      const response = await axios.post(
        'https://admin.theroarfit.com/api/cart/add',
        {
          product_id: productId,
          quantity: 1,
          size: selectedSize, // Add selected size to the API request
          color: selectedColor,
          thickness: selectedThickness,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'X-Session-ID': sessionId,
          },
        }
      );

      dispatch(add(response.data.data));
      // console.log("response of add to cart ",response.data.data.product_id);

      if (response.status === 200) {
        // Item added to cart successfully, now remove it from the wishlist
        await axios.delete(
          `https://admin.theroarfit.com/api/wishlist/remove/${productId}`,
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${userToken}`,
            },
          }
        );
        dispatch(removeWishlistItem(productId))



        toast.success("Item added to cart");
      } else {
        console.error('Failed to add item to the cart');
        toast.error('Failed to add item to the cart');
      }
    } catch (error) {
      console.error('Error adding item to the cart:', error);
      toast.error('Error adding item to the cart');
    }

    toast.dismiss(toastId);
  };


  const handleRemoveFromWishlist = async (productId) => {
    const toastId = toast.loading('Deleting...');

    try {
      const response = await axios.delete(
        `https://admin.theroarfit.com/api/wishlist/remove/${productId}`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      const responseData = response.data;

      if (response.status === 200) {
        dispatch(removeWishlistItem(productId))
        toast.success(responseData.message);
      } else {
        console.log('Error removing product from wishlist:', responseData);
        toast.error('Failed to remove product from wishlist. Please try again.');
      }
    } catch (error) {
      console.error('Error removing product from wishlist:', error);
      toast.error('An error occurred while removing the product from the wishlist.');
    }

    toast.dismiss(toastId);
  };

  return (
    <div className=''>
      <div className='h-full bg-white pb-24 font-Quicksand w-11/12 max-w-7xl'>
      <h1 className="mb-10 text-center text-2xl font-bold pt-10">Your Favourite Items are here</h1>
      {loading ? ( // Loading state
        <Loader/>
      ) :wishlistData.length > 0 ? (
        <div>
          <div className='w-11/12 max-w-[700px] justify-center px-6 mx-auto'>
          <div className='rounded-lg'>
            {wishlistData.map((wishlistItem) => (
               <WishlistItem 
               key={wishlistItem.id}
               item={wishlistItem.product} 
               fullItem={wishlistItem}
               quantity={wishlistItem.quantity}
               onSelectSize={handleSelectSize}
               onSelectColor={handleSelectColor}
               onSelectThickness={handleSelectThickness}
               handleRemoveFromWishlist={() => handleRemoveFromWishlist(wishlistItem.product.id)}
               handleAddToCart={()=>handleAddToCart(wishlistItem.product.id)}
               // hasOptions={hasOptions}
               />
            ))}

          </div>
        </div>
        </div>
      ) : (
        <div className='flex flex-col justify-center items-center h-[50%] md:h-full gap-3 font-Quicksand'>
               <h1 className='text-2xl font-bold text-gray-800 mb-4'>Wishlist Empty</h1>
               <p className='text-gray-600 mb-8'>Your favorite items will appear here. Start exploring our products now!</p>
               <Link to='/'>
                 <button className='bg-black text-white font-bold py-2 px-4 rounded hover:bg-gray-800'>
                   Explore Products
                 </button>
               </Link>
             </div>
      )}
    </div>
    </div>
  );
}

export default UserWishlist;
