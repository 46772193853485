import { createSlice } from "@reduxjs/toolkit";

export const CartSlice = createSlice({
  name: "cart",
  initialState: [],
  reducers: {
    add: (state, action) => {
      const existingItem = state.find(item => item.id === action.payload.id);
      if (existingItem) {
        // If item already exists, increase quantity
        existingItem.quantity += 1;
      } else {
        // If item doesn't exist, add it with quantity 1
        state.push({ ...action.payload, quantity: 1 });
      }
      return state;
    },
    remove: (state, action) => {
     // Remove the item with the specified id from the state
     const updatedState = state.filter((item) => item.id !== action.payload);
     console.log("in the slice",updatedState);
     return state = updatedState;
    },
    resetCart: (state, action) => {
      return [];
    },
    increaseQuantity: (state, action) => {
      const itemId = action.payload;
      const itemIndex = state.findIndex((item) => item.id === itemId);
      if (itemIndex !== -1) {
        state[itemIndex].quantity += 1;
      }
    },
    decreaseQuantity: (state, action) => {
      const itemId = action.payload;
      const itemIndex = state.findIndex((item) => item.id === itemId);
      if (itemIndex !== -1 && state[itemIndex].quantity > 1) {
        state[itemIndex].quantity -= 1;
      }
    },
    updateCart: (state, action) => {
      return action.payload; // Set the entire cart state at once
    },
  }
  });
export const { add, remove, resetCart, increaseQuantity, decreaseQuantity, updateCart } = CartSlice.actions;
export default CartSlice.reducer;



// import { createSlice } from "@reduxjs/toolkit";

// const CartSlice = createSlice({
//   name: "cart",
//   initialState: {
//     cartData: [],
//   },
//   reducers: {
//     add: (state, action) => {
//       return {
//         ...state,
//         carttData: [...state.cartData, action.payload],
//       };
//     },
//     remove: (state, action) => {
//       state.cartData = state.cartData.filter((item) => item.product.id !== action.payload);
//     },
//     increaseQuantity: (state, action) => {
//       const itemId = action.payload;
//       const itemIndex = state.findIndex((item) => item.id === itemId);
//       if (itemIndex !== -1) {
//         state[itemIndex].quantity += 1;
//       }
//     },
//     decreaseQuantity: (state, action) => {
//       const itemId = action.payload;
//       const itemIndex = state.findIndex((item) => item.id === itemId);
//       if (itemIndex !== -1 && state[itemIndex].quantity > 1) {
//         state[itemIndex].quantity -= 1;
//       }
//     },
//   }
// })

// export const { add, remove,increaseQuantity,decreaseQuantity } = CartSlice.actions;
// export default CartSlice.reducer;
