import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Product from '../Components/Product/Product';
import Loader from '../Components/Common/Loader';

const CategoryProductsPage = () => {
    const { slug } = useParams();
    const [categoryProducts, setCategoryProducts] = useState({ data: { products: { data: [] } } });
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const productsPerPage = 8;

    useEffect(() => {
        const fetchCategoryProducts = async () => {
            try {
                const response = await axios.get(`https://admin.theroarfit.com/api/categories/${slug}`);
                // console.log("response of category data",response)
                setCategoryProducts(response.data);
            } catch (error) {
                if (error.response && error.response.status === 404) {
                    setCategoryProducts({ data: { products: { data: [] } } });
                } else {
                    console.error('Error fetching category products:', error);
                }
            } finally {
                setLoading(false);
            }
        };

        fetchCategoryProducts();
    }, [slug]);

    if (loading) {
        return <Loader/>;
    }

    if (!categoryProducts.data?.products?.data?.length) {
        return <div className="flex justify-center items-center pt-44  h-96 mb-20">
                 <p>No Data Found for this category...</p>
               </div>;
    }

    const totalProducts = categoryProducts.data.products.data.length;
    const totalPages = Math.ceil(totalProducts / productsPerPage);
    const indexOfLastProduct = currentPage * productsPerPage;
    const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
    const currentProducts = categoryProducts.data.products.data.slice(indexOfFirstProduct, indexOfLastProduct);

    return (
        <div className='pt-5 pb-12 lg:max-w-7xl mx-auto w-11/12'>
            <div className="max-w-7xl mx-auto grid gap-2 grid-cols-2 md:gap-4 sm:grid-cols-3 lg:grid-cols-4 ">
                {currentProducts.map((product) => (
                    <Product key={product.id} product={product} />
                ))}
            </div>
            <div className="flex items-center justify-center  mt-4 w-full font-Quicksand">
                <button
                    onClick={() => setCurrentPage(currentPage - 1)}
                    disabled={currentPage === 1}
                    className="mr-2 px-4 py-2 bg-white border hover:cursor-pointer text-gray-400"
                >
                    {"<"}
                </button>
                <p className='mx-1 text-gray-400'>
                    Page {currentPage} of {totalPages}
                </p>
                <button
                    className="ml-2 px-4 py-2 bg-white border hover:cursor-pointer text-gray-400"
                    onClick={() => setCurrentPage(currentPage + 1)}
                    disabled={indexOfLastProduct >= totalProducts}
                >
                    {">"}
                </button>
            </div>
        </div>
    );
};

export default CategoryProductsPage;

