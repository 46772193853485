// import React, { useState } from 'react';
// import { FaCheckCircle } from 'react-icons/fa';



// const ComboProductsOptions = ({ comboProducts }) => {
//     const [selectedOptions, setSelectedOptions] = useState({});
//     const [notifyEmail, setNotifyEmail] = useState('');

//     const handleColorChange = (productName, color) => {
//         setSelectedOptions(prevOptions => ({
//             ...prevOptions,
//             [productName]: {
//                 ...prevOptions[productName],
//                 selectedColor: color,
//                 selectedSize: '', 
//                 availableStock: '', 
//                 showNotifyModal: false 
//             }
//         }));
//     };

//     const handleSizeChange = (productName, size) => {
//         const product = comboProducts.find(p => p.name === productName);
//         const colorVariation = product.variations.find(v => v.color === selectedOptions[productName]?.selectedColor);
//         const sizeVariant = colorVariation?.variations.find(v => v.size === size);

//         setSelectedOptions(prevOptions => ({
//             ...prevOptions,
//             [productName]: {
//                 ...prevOptions[productName],
//                 selectedSize: size,
//                 availableStock: sizeVariant ? sizeVariant.stock : ''
//             }
//         }));
//     };

//     const handleNotifyMeClick = (productName) => {
//         setSelectedOptions(prevOptions => ({
//             ...prevOptions,
//             [productName]: {
//                 ...prevOptions[productName],
//                 showNotifyModal: true
//             }
//         }));
//     };

//     const handleNotifySubmit = (productName) => {
//         setSelectedOptions(prevOptions => ({
//             ...prevOptions,
//             [productName]: {
//                 ...prevOptions[productName],
//                 showNotifyModal: false
//             }
//         }));
//     };

//     const getSelectedProducts = () => {
//         return Object.keys(selectedOptions).map(productName => {
//             const { selectedColor, selectedSize } = selectedOptions[productName];
//             return {
//                 productName,
//                 selectedColor,
//                 selectedSize,
//                 isComboProduct: true 
//             };
//         });
//     };

//   return (
//         <div>
//             <h2 className='text-black font-bold mb-2'>Select color and size of your combo products :</h2>
//             {comboProducts.map((product) => {
//                 const { name, variations } = product;
//                 const options = selectedOptions[name] || {};

//                 return (
//                     <div key={name} className="mb-6">
//                         <h3 className='text-green-500 font-semibold'>{name}</h3>
//                         <div className="text-black text-sm mb-2 flex flex-col gap-2">
//                             <div>
//                                 <label htmlFor={`colorDropdown-${name}`} className="block mb-2 text-sm font-semibold text-black">
//                                     Color : <span className="text-[10px]">{options.selectedColor}</span>
//                                 </label>
//                                 <div className="flex flex-wrap gap-2">
//                                     {variations.map((variation, index) => (
//                                         <label
//                                             key={index}
//                                             className={`border inline-flex items-center cursor-pointer rounded-lg p-2 ${options.selectedColor === variation.color ? 'bg-black text-white' : 'bg-white text-black'}`}
//                                         >
//                                             <input
//                                                 type="radio"
//                                                 className="hidden"
//                                                 name={`color-${name}`}
//                                                 value={variation.color}
//                                                 checked={options.selectedColor === variation.color}
//                                                 onChange={(e) => handleColorChange(name, e.target.value)}
//                                             />
//                                             <span className="text-sm">{variation.color || "N/A"}</span>
//                                         </label>
//                                     ))}
//                                 </div>
//                             </div>
//                             {options.selectedColor && (
//                                 <div>
//                                     <label htmlFor={`sizeDropdown-${name}`} className="block mb-1 text-sm font-semibold text-black">
//                                         Size : <span className="text-[10px]">{options.selectedSize}</span>
//                                     </label>
//                                     <div className="flex flex-wrap gap-2">
//                                         {variations.find(variant => variant.color === options.selectedColor)?.variations.map((sizeVariant, index) => (
//                                             <label
//                                                 key={index}
//                                                 className={`border inline-flex items-center cursor-pointer rounded-lg p-2 ${options.selectedSize === sizeVariant.size ? 'bg-black text-white' : 'bg-white text-black'}`}
//                                             >
//                                                 <input
//                                                     type="radio"
//                                                     className="hidden"
//                                                     name={`size-${name}`}
//                                                     value={sizeVariant.size}
//                                                     checked={options.selectedSize === sizeVariant.size}
//                                                     onChange={(e) => handleSizeChange(name, e.target.value)}
//                                                 />
//                                                 <span className="ml-2 text-sm">{sizeVariant.size}</span>
//                                             </label>
//                                         ))}
//                                     </div>
//                                 </div>
//                             )}
//                         </div>
//                         <div className='flex gap-2 items-center'>
//                             {options.availableStock === '' ? (
//                                 <div className='font-semibold flex gap-2 justify-between items-center'>
//                                     <FaCheckCircle className="text-green-600" /> Available Stock : <span>first choose size</span>
//                                 </div>
//                             ) : (options.availableStock === '0' ? (
//                                 <div className='flex gap-1'>
//                                     <p className='text-red-500'>Out of stock</p>
//                                     <button
//                                         className='text-black font-bold'
//                                         onClick={() => handleNotifyMeClick(name)}
//                                     >
//                                         Notify me
//                                     </button>
//                                 </div>
//                             ) : (
//                                 <div className='font-semibold flex gap-2 justify-between items-center'>
//                                     <FaCheckCircle className="text-green-600" /> Available Stock : {options.availableStock}
//                                 </div>
//                             ))}
//                         </div>
//                         {options.showNotifyModal && (
//                             <div className='modal border p-2 rounded-md bg-gray-100'>
//                                 <div className='modal-content'>
//                                     <p>You will be notified by email when the product is back in stock.</p>
//                                     <div className='flex flex-col gap-2 mt-2'>
//                                         <input
//                                             type='email'
//                                             placeholder='Enter your email'
//                                             value={notifyEmail}
//                                             onChange={(e) => setNotifyEmail(e.target.value)}
//                                             className='focus:outline-none border rounded-md px-2'
//                                         />
//                                         <div className='flex gap-2'>
//                                             <button
//                                                 onClick={() => handleNotifyMeClick(name)}
//                                                 className='bg-black text-white font-bold text-xs px-4 py-[0.35rem] rounded shadow outline-none focus:outline-none mb-1 mt-2 sm:mt-0'
//                                             >
//                                                 Cancel
//                                             </button>
//                                             <button
//                                                 onClick={() => handleNotifySubmit(name)}
//                                                 className='bg-black text-white font-bold text-xs px-4 py-[0.35rem] rounded shadow outline-none focus:outline-none mb-1 mt-2 sm:mt-0'
//                                             >
//                                                 Submit
//                                             </button>
//                                         </div>
//                                     </div>
//                                 </div>
//                             </div>
//                         )}
//                     </div>
//                 );
//             })}
//         </div>
//     );
// };

// export default ComboProductsOptions;


import React from 'react';
import { FaCheckCircle } from 'react-icons/fa';

const ComboProductsOptions = ({
    comboProducts,
    comboSelectedOptions,
    comboNotifyEmail,
    onComboColorSelect,
    onComboSizeSelect,
    onComboNotifyClick,
    onComboNotifySubmit,
    onComboNotifyEmailChange
}) => {

    const isSelectionComplete = () => {
        return comboProducts.every(product => {
            const options = comboSelectedOptions[product.name] || {};
            return options.selectedColor && options.selectedSize;
        });
    };

    return (
        <div>
            <h2 className='text-black font-bold mb-2'>Select color and size of your combo products :</h2>
            {comboProducts.map((product) => {
                const { name, variations } = product;
                const options = comboSelectedOptions[name] || {};

                return (
                    <div key={name} className="mb-6">
                        <h3 className='text-green-500 font-semibold'>{name}</h3>
                        <div className="text-black text-sm mb-2 flex flex-col gap-2">
                            <div>
                                <label htmlFor={`colorDropdown-${name}`} className="block mb-2 text-sm font-semibold text-black">
                                    Color : <span className="text-[10px]">{options.selectedColor}</span>
                                </label>
                                <div className="flex flex-wrap gap-2">
                                    {variations.map((variation, index) => (
                                        <label
                                            key={index}
                                            className={`border inline-flex items-center cursor-pointer rounded-lg p-2 ${options.selectedColor === variation.color ? 'bg-black text-white' : 'bg-white text-black'}`}
                                        >
                                            <input
                                                type="radio"
                                                className="hidden"
                                                name={`color-${name}`}
                                                value={variation.color}
                                                checked={options.selectedColor === variation.color}
                                                onChange={(e) => onComboColorSelect(name, e.target.value)}
                                            />
                                            <span className="text-sm">{variation.color || "N/A"}</span>
                                        </label>
                                    ))}
                                </div>
                            </div>
                            {options.selectedColor && (
                                <div>
                                    <label htmlFor={`sizeDropdown-${name}`} className="block mb-1 text-sm font-semibold text-black">
                                        Size : <span className="text-[10px]">{options.selectedSize}</span>
                                    </label>
                                    <div className="flex flex-wrap gap-2">
                                        {variations.find(variant => variant.color === options.selectedColor)?.variations.map((sizeVariant, index) => (
                                            <label
                                                key={index}
                                                className={`border inline-flex items-center cursor-pointer rounded-lg p-2 ${options.selectedSize === sizeVariant.size ? 'bg-black text-white' : 'bg-white text-black'}`}
                                            >
                                                <input
                                                    type="radio"
                                                    className="hidden"
                                                    name={`size-${name}`}
                                                    value={sizeVariant.size}
                                                    checked={options.selectedSize === sizeVariant.size}
                                                    onChange={(e) => onComboSizeSelect(name, e.target.value)}
                                                />
                                                <span className="ml-2 text-sm">{sizeVariant.size}</span>
                                            </label>
                                        ))}
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className='flex gap-2 items-center'>
                            {options.availableStock === '' ? (
                                <div className='font-semibold flex gap-2 justify-between items-center'>
                                    <FaCheckCircle className="text-green-600" /> Available Stock : <span>first choose size</span>
                                </div>
                            ) : (options.availableStock === '0' ? (
                                <div className='flex gap-1'>
                                    <p className='text-red-500'>Out of stock</p>
                                    <button
                                        className='text-black font-bold'
                                        onClick={() => onComboNotifyClick(name)}
                                    >
                                        Notify me
                                    </button>
                                </div>
                            ) : (
                                <div className='font-semibold flex gap-2 justify-between items-center'>
                                    <FaCheckCircle className="text-green-600" /> Available Stock : {options.availableStock}
                                </div>
                            ))}
                        </div>
                        {options.showNotifyModal && (
                            <div className='modal border p-2 rounded-md bg-gray-100'>
                                <div className='modal-content'>
                                    <p>You will be notified by email when the product is back in stock.</p>
                                    <div className='flex flex-col gap-2 mt-2'>
                                        <input
                                            type='email'
                                            placeholder='Enter your email'
                                            value={comboNotifyEmail}
                                            onChange={(e) => onComboNotifyEmailChange(e.target.value)}
                                            className='focus:outline-none border rounded-md px-2'
                                        />
                                        <div className='flex gap-2'>
                                            <button
                                                onClick={() => onComboNotifyClick(name)}
                                                className='bg-black text-white font-bold text-xs px-4 py-[0.35rem] rounded shadow outline-none focus:outline-none mb-1 mt-2 sm:mt-0'
                                            >
                                                Cancel
                                            </button>
                                            <button
                                                onClick={() => onComboNotifySubmit(name)}
                                                className='bg-black text-white font-bold text-xs px-4 py-[0.35rem] rounded shadow outline-none focus:outline-none mb-1 mt-2 sm:mt-0'
                                            >
                                                Submit
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                );
            })}
            {!isSelectionComplete() && (
                <div className="text-red-500 mt-4">
                    Please select both color and size for all combo products.
                </div>
            )}
        </div>
    );
};

export default ComboProductsOptions;

