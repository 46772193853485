import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    couponId: null,
    discount: null,
    discountType: null,
    totalAmount:0
};

const couponSlice = createSlice({
    name: 'coupon',
    initialState,
    reducers: {
        setDiscountDetails: (state, action) => {
            state.couponId = action.payload.couponId;
            state.discount = action.payload.discount;
            state.discountType = action.payload.discountType;
        },
        setTotalCartAmount: (state, action)=>{
            state.totalAmount = action.payload.totalAmount;

        }
    },
});

export const { setDiscountDetails, setTotalCartAmount } = couponSlice.actions;
export default couponSlice.reducer;
