import { createSlice } from "@reduxjs/toolkit";

const BestSellerSlice = createSlice({
    name: "BestSellerProducts",
    initialState: {
        bestSellerProducts: [],
    },
    reducers: {
        addBestSellerProducts: (state, action) => {
            state.bestSellerProducts = action.payload;
        }
    }
});

export const { addBestSellerProducts } = BestSellerSlice.actions;
export default BestSellerSlice.reducer;
