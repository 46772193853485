import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { AiFillDelete } from "react-icons/ai"
import { FaCartPlus } from "react-icons/fa"
import toast from 'react-hot-toast';
import axios from 'axios';
import { add } from "../../slices/cartSlice";
import wishlistSlice from '../../slices/wishlistSlice';
import {removeWishlistItem } from '../../slices/wishlistSlice';

const WishlistItem = ({ item, handleRemoveFromWishlist}) => {
  const [optionsVisible, setOptionsVisible] = useState(false);
  const [selectedSize, setSelectedSize] = useState('');
  const [selectedThickness, setSelectedThickness] = useState('');
  const [selectedColor, setSelectedColor] = useState('')
  const dispatch = useDispatch();
  const userToken = JSON.parse(localStorage.getItem('token'));
  const [loading, setLoading] = useState(false);
  const { wishlistData } = useSelector(state => state.wishlist);
  const [availableStock, setAvailableStock] = useState('');
  const handleOptionsToggle = () => {
    setOptionsVisible(!optionsVisible);
  };

  const hasOptions = item?.size && item.color && item.thickness;
  // Function to get or generate a session ID
  const getSessionId = () => {
    let sessionId = localStorage.getItem('sessionId');
    if (!sessionId) {
      sessionId = uuidv4();
      localStorage.setItem('sessionId', sessionId);
    }
    return sessionId;
  };

  const handleAddToCart = async (productId) => {
    const sessionId = getSessionId();
    const toastId = toast.loading("Loading...");

    // Check if the product has options
    if (hasOptions) {
      // Validate if all options are selected
      if (!selectedSize || !selectedColor || !selectedThickness) {
        toast.error('Please select size, color, and thickness before adding to the cart.');
        toast.dismiss(toastId);
        return;
      }
    }

    if (availableStock > 0) {
      try {
        const response = await axios.post(
          'https://admin.theroarfit.com/api/cart/add',
          {
            product_id: productId,
            quantity: 1,
            size: selectedSize, // Add selected size to the API request
            color: selectedColor,
            thickness: selectedThickness,
          },
          {
            headers: {
              'Content-Type': 'application/json',
              'X-Session-ID': sessionId,
            },
          }
        );

        dispatch(add(response.data.data));


        if (response.status === 200) {
          // Item added to cart successfully, now remove it from the wishlist
          await axios.delete(
            `https://admin.theroarfit.com/api/wishlist/remove/${productId}`,
            {
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userToken}`,
              },
            }
          );
          dispatch(removeWishlistItem(productId))



          toast.success("Item added to cart");
        } else {
          console.error('Failed to add item to the cart');
          toast.error('Failed to add item to the cart');
        }
      } catch (error) {
        console.error('Error adding item to the cart:', error);
        toast.error('Error adding item to the cart');
      }
      toast.dismiss(toastId);
    } else {
      toast.error("Item is out of stock");
    }
  };


  const gstpercent = item?.gst;
  const calculateGstAmount = (price, gstpercent) => (price * gstpercent) / 100;
  const gstAmount = calculateGstAmount(item?.sale_price, gstpercent);
  const regulaPriceWithGst = (Number(item?.regular_price) + gstAmount).toFixed(2);
  const salePriceWithGst = (Number(item?.sale_price) + gstAmount).toFixed(2);

  return (
    <div className="font-Quicksand">
      <div className='justify-between mb-6 rounded-lg bg-white p-6 shadow-[0_3px_10px_rgb(0,0,0,0.2)] flex-col flex sm:flex-row'>
        <div className='h-28 w-48'>
          <img src={item?.image_data?.thumbnail} alt="cart item" className="rounded-lg sm:w-40 w-full h-full object-contain sm:object-contain" />
        </div>
        <div>
          <p className="font-bold text-gray-900">{item?.name}</p>
          {hasOptions && (
            <button
              className="bg-gray-200 text-black px-3 py-0 rounded-md mb-2  mt-2"
              onClick={handleOptionsToggle}
            >
              Choose Options
            </button>
          )}
          {optionsVisible && hasOptions && (
            <>
              <div className='mb-2'>{availableStock === '' ? null : (availableStock > 0 ? <p className='block mb-1 text-sm font-medium text-black'>Available Stock : {availableStock}</p> : <p className='block mb-1 text-sm font-medium text-red-500'>Out of stock</p>)}</div>
              <div className='text-black text-sm mb-2 flex flex-col gap-2'>  {item?.size && (
                <div className=''>
                  <label htmlFor="sizeDropdown" className="block mb-1 text-sm font-medium text-black">Select size</label>
                  <select id="sizeDropdown" className="focus:outline-none block w-full p-1 mb-2 text-sm text-gray-900 border border-gray-200 rounded-lg bg-gray-50 "
                    defaultValue={''}
                    onChange={(e) => {
                      // console.log('Selected Size:', e.target.value);
                      setSelectedSize(e.target.value);
                      if (e.target.value === '') {
                        console.log(e.target.value, "e value");
                        setAvailableStock('');
                      } else {
                        for (const key in item.stock) {
                          if (key === e.target.value) {
                            setAvailableStock(item.stock[key]);
                            // console.log("key",key);   
                          }
                        }
                      }
                    }}
                  >
                    <option value={''}>Select size</option>
                    {item.size.map((sizeOption, index) => (
                      <option key={index} value={sizeOption}>{sizeOption || "N/A"}</option>
                    ))}
                  </select>
                </div>
              )}
                {item.color && (
                  <div className=''>
                    <label htmlFor="colorDropdown" className="block mb-2 text-sm font-medium text-black">Select color</label>
                    <select id="colorDropdown" className="focus:outline-none block w-full p-1 mb-2 text-sm text-gray-900 border border-gray-200 rounded-lg bg-gray-50 "
                      defaultValue={null}
                      onChange={(e) => {
                        // console.log('Selected color:', e.target.value);
                        setSelectedColor(e.target.value);
                      }}
                    >
                      <option value={null}>Select color</option>
                      {item.color.map((colorOption, index) => (
                        <option key={index} value={colorOption}>{colorOption || "N/A"}</option>
                      ))}
                    </select>
                  </div>
                )}
                {item.thickness && (
                  <div className=''>
                    <label htmlFor="thicknessDropdown" className="block mb-2 text-sm font-medium text-black">Select thickness</label>
                    <select id="thicknessDropdown" className="focus:outline-none block w-full p-1 mb-2 text-sm text-gray-900 border border-gray-200 rounded-lg bg-gray-50 "
                      defaultValue={null}
                      onChange={(e) => {
                        // console.log('Selected thickness:', e.target.value);
                        setSelectedThickness(e.target.value);
                      }}
                    >
                      <option value={null}>Select thickness</option>
                      {item.thickness.map((thicknessOption, index) => (
                        <option key={index} value={thicknessOption}>{thicknessOption || "N/A"}</option>
                      ))}
                    </select>
                  </div>
                )}
              </div>
            </>
          )}
        </div>
        <div className='mt-4 flex justify-between sm:space-y-6 sm:mt-0 sm:block sm:space-x-6  justify-self-end'>
          <div className="flex flex-col self-end">
            <p className="text-black font-semibold">Rs. {salePriceWithGst}</p>
            <div className='flex gap-2 items-center'>
              <div
                onClick={() => handleRemoveFromWishlist(item.product_id)}
                className='flex items-center justify-center bg-red-400 rounded-full hover:cursor-pointer'
                style={{ width: '30px', height: '30px' }}
              >
                <AiFillDelete className='m-1' />
              </div>
              <button
                onClick={() => {
                  handleAddToCart(item.id);
                  //  console.log("id in add to cart", item)
                }}
                className="bg-green-600 hover:bg-green-800 text-white font-bold p-2 rounded-full"
              >
                <FaCartPlus />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WishlistItem;
