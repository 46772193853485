// import React, { useState, useEffect, useCallback  } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import { Swiper, SwiperSlide } from 'swiper/react';
// import 'swiper/css';
// import 'swiper/css/navigation';
// import { Autoplay, Navigation } from 'swiper/modules';
// import { getAllBanners } from '../../services/operations/productDetailsAPI';
// import { addBanners } from '../../slices/bannerSlice';
// import Loader from '../Common/Loader';

// const Banner = () => {
//   const [loading, setLoading] = useState(false);
//   const dispatch = useDispatch();
//   const banners = useSelector(state => state.banners.banners);

//   const fetchBannerData = useCallback(async () => {
//     setLoading(true);
//     try {
//       const result = await getAllBanners();
//       const data = result.data;
//       dispatch(addBanners(data));
//     } catch (error) {
//       console.log("Error occurred during fetching the banners", error);
//     } finally {
//       setLoading(false);
//     }
//   }, [dispatch]);

//   useEffect(() => {
//     if (banners.length === 0) {
//       fetchBannerData();
//     }
//   }, [fetchBannerData, banners.length]);

//   return (
//     <>
//       {loading && <Loader />}
//       {
//         !loading && banners.length > 0 &&
//         (
//           <Swiper
//             className="mySwiper"
//             spaceBetween={30}
//             centeredSlides={true}
//             direction="horizontal"
//             autoplay={{
//               delay: 4000,
//               disableOnInteraction: false,
//             }}
//             loop={true}
//             navigation={false}
//             modules={[Autoplay, Navigation]}
//           >
//             {
//               banners.map((banner) => (
//                 <SwiperSlide key={banner.id}>
//                   <img
//                     className="w-full h-full fade-in-out"
//                     src={banner?.show_media?.original}
//                     loading='lazy'
//                     alt={`Slide banner`}
//                   />
//                 </SwiperSlide>
//               ))
//             }
//           </Swiper>
//         )
//       }
//     </>
//   );
// };

// export default Banner;

import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllBanners } from '../../services/operations/productDetailsAPI';
import { addBanners } from '../../slices/bannerSlice';
import Loader from '../Common/Loader';

const Banner = () => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const banners = useSelector(state => state.banners.banners);
  const [currentIndex, setCurrentIndex] = useState(0);

  const fetchBannerData = useCallback(async () => {
    setLoading(true);
    try {
      const result = await getAllBanners();
      const data = result.data;
      dispatch(addBanners(data));
    } catch (error) {
      console.log("Error occurred during fetching the banners", error);
    } finally {
      setLoading(false);
    }
  }, [dispatch]);

  useEffect(() => {
    if (banners.length === 0) {
      fetchBannerData();
    }
  }, [fetchBannerData, banners.length]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % banners.length);
    }, 4000);
    return () => clearTimeout(timer);
  }, [currentIndex, banners.length]);

  if (loading) {
    return <Loader />;
  }

  if (!loading && banners.length === 0) {
    return <p>No banners available</p>;
  }

  return (
      <div className="w-full">
        <div className="relative">
          <div className="flex space-x-4">
            {banners.slice(currentIndex, currentIndex + 1).map((banner, index) => (
              <div key={index} className="w-full h-full fade-in-out">
                <img className="w-full h-full" src={banner?.show_media?.original} alt="banner" />
              </div>
            ))}
          </div>
        </div>
      </div>

  );
};

export default Banner;

