import React, { useState, useEffect } from 'react'
import { FcGoogle } from "react-icons/fc"
import { FaFacebookF } from "react-icons/fa6"
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch } from "react-redux"
import { login } from "../services/operations/authAPI"
import img from "../Assets/login-img.webp"
import { useForm } from 'react-hook-form';
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai"
import { GoogleLogin, googleLogout, useGoogleLogin } from '@react-oauth/google';
import axios from 'axios'
import { jwtDecode } from 'jwt-decode';




const Login = () => {
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  const { register, handleSubmit, formState: { errors } } = useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false)

  const onSubmit = (data) => {
    dispatch(login(data.email, data.password, navigate));
  };


  const handleLoginSuccess = async (response) => {
    try {
      const credential = response.credential; 
      const decoded = jwtDecode(credential);

      console.log('User Data:', decoded);

      // Send user data to your backend API
      // const res = await axios.post('YOUR_BACKEND_API_ENDPOINT', { token: credential, userData: decoded, additionalUserInfo });
      // console.log(res.data);
    } catch (error) {
      console.error('Error authenticating user:', error);
    }
  };

  const handleLoginFailure = (response) => {
    console.error(response);
  };


  return (
    <div className=' pb-36 pt-5'>
      <section className="w-11/12 flex flex-col md:flex-row justify-center space-y-10 md:space-y-0 md:space-x-16 items-center mx-5 md:mx-0 md:my-0">
        <div className="md:w-1/3 max-w-sm">
          <img src={img} alt="Sampleimage" loading="lazy" />
        </div>
        <div className="md:w-1/3 max-w-sm ">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="text-center md:text-left">
              {/* <label className="mr-1">Sign in with</label> */}
              <GoogleLogin
                onSuccess={handleLoginSuccess}
                onFailure={handleLoginFailure}
                cookiePolicy={'single_host_origin'}
                scope="profile email https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email"
                render={(renderProps) => (
                  <button
                    type="button"
                    onClick={renderProps.onClick}
                    disabled={renderProps.disabled}
                    className="mx-1 h-9 w-9 rounded-full bg-white hover:bg-blue-200 text-white shadow-[0_4px_9px_-4px_#3b71ca]"
                  >
                    <FcGoogle className="mx-auto h-3.5 w-3.5" />
                  </button>
                )}
              />
              {/* <button
                type="button"
                className="mx-1 h-9 w-9 rounded-full bg-white hover:bg-blue-200 text-blue-800 shadow-[0_4px_9px_-4px_#3b71ca]"
              >
                <FaFacebookF className="mx-auto h-3.5 w-3.5" />
              </button> */}
            </div>
            <div className="my-5 flex items-center before:mt-0.5 before:flex-1 before:border-t before:border-neutral-300 after:mt-0.5 after:flex-1 after:border-t after:border-neutral-300">
              <p className="mx-4 mb-0 text-center font-semibold text-slate-500">Or</p>
            </div>
            <input
              {...register("email", { required: true })}
              className={`text-sm w-full px-4 py-2 border border-solid border-gray-300 rounded focus:outline-none ${errors.email ? 'border-red-500' : ''}`}
              type="text"
              placeholder="Email Address"
            />
            {errors.email && <span className="text-red-500 text-sm">Email is required</span>}
            <div className='relative'>
              <input
                {...register("password", { required: true })}
                className={`text-sm w-full px-4 py-2 border border-solid border-gray-300 rounded mt-4 focus:outline-none ${errors.password ? 'border-red-500' : ''}`}
                type={showPassword ? "text" : "password"}
                placeholder="Password"
              />
              <span
                onClick={() => setShowPassword((prev) => !prev)}
                className="absolute right-4 top-6 z-10 cursor-pointer text-red-500"
              >
                {showPassword ? (
                  <AiOutlineEyeInvisible fontSize={24} fill="#AFB2BF" />
                ) : (
                  <AiOutlineEye fontSize={24} fill="#AFB2BF" />
                )}
              </span>
            </div>
            {errors.password && <span className="text-red-500 text-sm">Password is required</span>}
            <div className="mt-4 flex justify-between font-semibold text-sm">
              <label className="flex text-slate-500 hover:text-slate-600 cursor-pointer">
                <input className="mr-1 focus:outline-none" type="checkbox" />
                <span>Remember Me</span>
              </label>
              <Link className="text-red-600 hover:underline hover:underline-offset-4" >
                Forgot Password?
              </Link>
            </div>
            <div className="text-center md:text-left">
              <button
                className="mt-4 bg-black hover:bg-white px-4 py-2 text-white hover:text-black hover:border uppercase rounded text-xs tracking-wider"
                type="submit"
              >
                Login
              </button>
            </div>
            <div className="mt-4 font-semibold text-sm text-slate-500 text-center md:text-left">
              Don't have an account? <Link className="text-red-600 hover:underline hover:underline-offset-4" to="/signup">Register</Link>
            </div>
          </form>
        </div>
      </section>
    </div>
  )
}

export default Login