import React from 'react'
import { Link } from 'react-router-dom'

const PaymentDecline = () => {
    return (
        <div className='h-screen'>
            <div className="text-center mt-20">
                <div className="text-3xl font-bold "> <h1 className='text-red-600'>Uh Oh! Your Payment is Declined</h1></div>
                <div className="text-gray-600 mt-2"> <p>Don’t worry, we got your back. Just open up your cart and retry. <Link to={"/cart"} className='text-blue-500'>Go to cart</Link> or <Link to={"/"} className='text-blue-500'>Continue shopping</Link> </p> </div>
            </div>
        </div>
    )
}

export default PaymentDecline