import { toast } from "react-hot-toast"
import { apiConnector } from "../apiconnector"
import { categoriesEndpoints } from "../apis"


const {
    GET_ALL_CATEGORIES_API
} = categoriesEndpoints

//get all users api
export const getAllCategories = async () => {
//   const toastId = toast.loading("Loading...")
  let result = []
  try {
    const response = await apiConnector("GET",GET_ALL_CATEGORIES_API)
    if (!response) {
      throw new Error("Could Not Fetch All Categories")
    }
    result = response.data
  } catch (error) {
    console.log("GET_ALL_CATEGORIES_API API Error............", error)
    toast.error(error.message)
  }
//   toast.dismiss(toastId)
  return result
}