import React,{useEffect, useState} from 'react'
import CheckoutForm from '../Components/Checkout/CheckoutForm'
import OrderSummery from '../Components/Checkout/OrderSummery'

const CheckoutPage = () => {
  const [selectedPaymentMode, setSelectedPaymentMode] = useState('');
  useEffect(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);
  return (
    <div className='flex flex-col md:flex-row pt-5 pb-5 max-w-7xl w-11/12 mx-auto gap-5'>
         <div className='md-w-[50%] w-full border rounded-md'>
         <CheckoutForm  setSelectedPaymentMode = {setSelectedPaymentMode}/>
         </div>
         <div className='md-w-[50%] w-full'>
         <OrderSummery selectedPaymentMode={selectedPaymentMode}/>
         </div>
    </div>
  )
}

export default CheckoutPage