import React, { useEffect, useState } from 'react';
import { Link,useNavigate } from 'react-router-dom';
import { logout } from '../../services/operations/authAPI';
import { useDispatch } from 'react-redux';
import {BsPersonFill} from "react-icons/bs"
import { getAllCategories } from '../../services/operations/categoriesAPI';

const CategoryDropdown = () => {
const [isOpen, setIsOpen] = useState(false);
const [loading,setLoading]=useState(false);
const [categories,setCategories]=useState([]);
const dispatch = useDispatch();
const navigate = useNavigate();


  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const fetchCategories =async () => {
    setLoading(true);
    try {
      const result = await getAllCategories();
      const data = result.data;
      // console.log("all categories", data);
      setCategories(data);
    } catch (error) {
      console.log("Error occured during fetching the all categories", error);
      setCategories([]);
    }
    setLoading(false);
  };

  useEffect(()=>{
    fetchCategories();
  },[])

  return (
    <div className="relative inline-block text-left">
      <button
        id="dropdownDefaultButton"
        onClick={toggleDropdown}
        className="text-black font-semibold focus:outline-none focus:ring-blue-300  rounded-full py-2 text-center inline-flex items-center"
        type="button"
      >Collections{" "}
        <svg
          className={`w-2.5 h-2.5 ml-2.5 transform ${isOpen ? 'rotate-180' : 'rotate-0'}`}
          aria-hidden="true"
          fill="none"
          viewBox="0 0 10 6"
        >
          <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 4 4 4-4" />
        </svg>
      </button>

      {/* Dropdown menu */}
      {isOpen && (
        <div className="z-10 absolute left-0 mt-2 w-[12rem] bg-white divide-y rounded-lg shadow text-black">
          <ul className="py-2 text-sm text-black w-44"  aria-labelledby="dropdownDefaultButton">
          {categories.map((category) => (
              <li key={category.id} className="pl-2 pr-2 font-Quicksand">
                <Link
                  to={`/collections/${category.slug}`}
                  className="block px-4 py-2 hover:bg-gray-300 hover:text-black rounded-md w-full font-Quicksand"
                  onClick={() => setIsOpen(false)}
                >
                  {category.name}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default CategoryDropdown;
