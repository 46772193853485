import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import CartItem from '../../Cart/CartItem';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import Loader from "../../Common/Loader"
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';


function UserCart() {
  const [totalAmount, setTotalAmount] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const shippingCharges = 0;
  const [cartItems, setCartItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  let stock = 0;
  
   useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  useEffect(() => {
    const fetchCartItems = async () => {
      const sessionId = getSessionId();
      try {
        const response = await axios.get('https://admin.theroarfit.com/api/cart/view', {
          headers: {
            'Content-Type': 'application/json',
            'X-Session-ID': sessionId,
          },
        });

        // Assuming the cart items are in the response data
        const cartItemsData = response.data.data;
        // console.log("response of getting cart items api", cartItemsData)
        setCartItems(cartItemsData);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching cart items:', error);
      }
    };

    fetchCartItems();
  }, []);

  const updateTotalAmount = (itemTotalAmount, index) => {
    // Update the itemTotalAmount for the corresponding cart item
    setCartItems((prevItems) => {
      const updatedItems = [...prevItems];
      updatedItems[index] = {
        ...updatedItems[index],
        itemTotalAmount
      };
      return updatedItems;
    });
  };

  useEffect(() => {
    const calculatedTotalAmount = cartItems.reduce(
      (acc, curr) =>
        acc +
        (curr.variation
          ? curr.variation?.sale_price * curr.quantity
          : curr.product?.sale_price * curr.quantity),
      0
    );

    const calculatedTotalItems = cartItems.reduce((acc, curr) => {
      return Number(acc) + Number(curr.quantity);
    }, 0);

    setTotalAmount(calculatedTotalAmount);
    setTotalItems(calculatedTotalItems);
  }, [cartItems]);

 

  const handleCheckout = () => {
   // Check if all items in the cart have available stock
   const allItemsInStock = cartItems.every((item) => item?.product?.stock > 0);
   if (allItemsInStock) {
     navigate("/checkout");
   } else {
     toast.error("Cannot proceed to checkout. Some items are out of stock.");
   }
  };

  const getSessionId = () => {
    let sessionId = localStorage.getItem('sessionId');
    if (!sessionId) {
      sessionId = uuidv4();
      localStorage.setItem('sessionId', sessionId);
    }
    return sessionId;
  };

  const removeFromCart = async (itemId) => {
    try {
      const response = await axios.delete(`https://admin.theroarfit.com/api/cart/remove/${itemId}`)
        if (response.status === 200) {
        // Successfully removed from the server, now update the local state
        const updatedCart = cartItems.filter((cartItem) => cartItem?.id !== itemId);
        setCartItems(updatedCart);
        // console.log('Item removed successfully');
      } else {
        console.error('Failed to remove item from the cart');
      }
    } catch (error) {
      console.error('Error removing item from the cart:', error);
    }
  };

  const increaseItemQuantity = async (itemId,quantity) => {
    try {
      const response = await axios.put(`https://admin.theroarfit.com/api/cart/update/${itemId}`,{quantity:Number(quantity)+1})
      const newCartData = cartItems.map((data)=>{
        if(data.id===response?.data?.data?.id){
         data.quantity = response?.data?.data?.quantity
         return data;
        }
        return data;
     })
    //  console.log("new cart data",newCartData)
      setCartItems(newCartData)
      // console.log("response of update cart item",response)
    } catch (error) {
      console.error('Error updating item from the cart:', error);
    }
  };
  
  const decreaseItemQuantity = async (itemId,quantity) => {
    if(quantity===1){
      return;
    }
     try {
       const response = await axios.put(`https://admin.theroarfit.com/api/cart/update/${itemId}`,{quantity:Number(quantity)-1})
       const newCartData = cartItems.map((data)=>{
         if(data.id===response.data.data.id){
          data.quantity = response?.data?.data?.quantity
          return data;
         }
         return data;
      })
      // console.log("new cart data",newCartData)
       setCartItems(newCartData)
      //  console.log("response of update cart item",response)
     } catch (error) {
       console.error('Error updating item from the cart:', error);
     }
  };

  const handleTotalStock = (totalStock) => {
    // Do something with the totalStock value
    stock = totalStock;
    console.log("Total Stock in CartItem:", totalStock);
    // You can store it in state or use it as needed
  };
  
  return (
    <div className='h-full bg-white font-Quicksand w-11/12 max-w-7xl mx-auto'>
      <h1 className="mb-10 text-center text-2xl font-bold">Cart Items</h1>
      {loading ? ( // Loading state
        <Loader/>
      ) :cartItems.length > 0 ? (
        <div>
          <div className='mx-auto max-w-5xl justify-center px-6 md:flex md:space-x-6 xl:px-0'>
          <div className='rounded-lg md:w-2/3'>
            {cartItems.map((cartItem, index) => (
              <CartItem
                key={index}
                item={cartItem?.product}
                variation={cartItem}
                quantity={cartItem?.quantity}
                removeFromCart={() => removeFromCart(cartItem?.id)}
                increaseItemQuantity={() => increaseItemQuantity(cartItem.id,cartItem?.quantity)}
                decreaseItemQuantity={() => decreaseItemQuantity(cartItem.id,cartItem?.quantity)}
                updateTotalAmount={(itemTotalAmount) => updateTotalAmount(itemTotalAmount, index)}
                handleTotalStock={handleTotalStock}

              />
            ))}

          </div>

          <div className='mt-6 h-full rounded-lg border bg-white p-6 shadow-[0_3px_10px_rgb(0,0,0,0.2)] md:mt-0 md:w-1/3'>
            <div className="mb-2 flex justify-between">
              <p className="text-gray-700">Total Items</p>
              <p className="text-gray-700">{totalItems}</p>
            </div>
            <div className="mb-2 flex justify-between">
              <p className="text-gray-700">Subtotal Amount</p>
              <p className="text-gray-700">Rs. {totalAmount}</p>
            </div>
            <div className="flex justify-between">
              <p className="text-gray-700">Shipping</p>
              <p className="text-gray-700">Rs. {shippingCharges}</p>
            </div>
            <hr className="my-4" />
            <div className="flex justify-between">
              <p className="text-lg font-bold">Total Paybill</p>
              <div>
                <p className="mb-1 text-lg font-bold text-right">Rs. {totalAmount + shippingCharges}</p>
                <p className="text-sm text-gray-700 text-right">including GST</p>
              </div>
            </div>
            <button onClick={handleCheckout} className="mt-6 w-full rounded-md bg-black py-1.5 font-medium text-white">
              Checkout Now
            </button>
          </div>
        </div>
        </div>
      ) : (
        <div className='flex flex-col items-center h-[50%] md:h-full gap-3'>
          <h1 className='text-black font-bold'>Cart Empty</h1>
          <Link to='/'>
            <button className='bg-black text-white font-bold py-2 px-4 rounded w-full'>Shop Now</button>
          </Link>
        </div> 
      )}
    </div>
  );
}

export default UserCart;
