import React,{useEffect} from 'react';


const RefundPolicyPage = () => {
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
      window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  return (
    <div className="container mx-auto w-11/12 max-w-7xl pt-5  mb-12  p-4">
      <h1 className="text-3xl font-bold mb-4">Refund Policy for Gym Equipment</h1>
      <p className="mb-4">
        Our refund policy aims to provide clarity on the conditions under which refunds are issued for gym equipment purchases.
      </p>

      <ol className="list-decimal pl-4">
        <li className="mb-2">
          Full refunds will be issued for gym equipment returned within 10 days of the delivery date.
        </li>
        <li className="mb-2">
          Refunds are only applicable to unused items returned in their original condition.
        </li>
        <li className="mb-2">
          Clothing items, including jerseys and gloves, must have all tags attached and be in their original packaging to qualify for a refund.
        </li>
        <li className="mb-2">
          Shoes must be returned in their original shoebox, undamaged and unworn, to be eligible for a refund.
        </li>
        <li className="mb-2">
          Refunds are not available for personalized or customized gym equipment.
        </li>
        <li className="mb-2">
          Shipping costs are non-refundable unless the item is defective or damaged.
        </li>
        <li className="mb-2">
          Refunds will be processed using the same payment method as the original purchase.
        </li>
        <li className="mb-2">
          The refund process may take 5-7 business days to complete.
        </li>
        <li className="mb-2">
          If you have any questions or concerns, please contact our customer service.
        </li>
      </ol>
    </div>
  );
};

export default RefundPolicyPage;
