import React from 'react'
import gif from "../.././Assets/delivery-package.gif"

const TrackingLoader = () => {
  return (
    <div className='w-full flex justify-center items-center'>
        <img src={gif} alt="waiting for loading the data" />
    </div>
  )
}

export default TrackingLoader