import React,{useEffect} from 'react';

const ReturnPolicyPage = () => {
    useEffect(() => {
        // Scroll to the top of the page when the component mounts
          window.scrollTo({ top: 0, behavior: 'smooth' });
      }, []);
    
    return (
        <div className="container mx-auto w-11/12 max-w-7xl pt-5 mb-12 p-4">
            <h1 className="text-3xl font-bold mb-4">Return Policy</h1>
            <p className="mb-4">
                Please read our return policy carefully to ensure that you are eligible for a return.
            </p>
            <ol className="list-decimal pl-4">
                <li className="mb-2">
                    Gym equipment must be returned within 10 days of the delivery date.
                </li>
                <li className="mb-2">
                    Items, including ballet bars, gloves, jerseys, shoes, and deadlift belts, must be unused and in the same condition as received.
                </li>
                <li className="mb-2">
                    Powerlifting items including lower belts, wrist support, singlet, gloves and knee sleeves must have all tags attached and be in their original packaging to quantity for refund.
                </li>
                <li className="mb-2">
                    Shoes must be returned in their original shoebox, undamaged and unworn.
                </li>
                <li className="mb-2">
                    Returns are not accepted for personalized or customized items.
                </li>
                <li className="mb-2">
                    Shipping costs are non-refundable unless the item is defective or damaged.
                </li>
                <li className="mb-2">
                    Please include a copy of the packing slip or order confirmation with your return.
                </li>
                <li className="mb-2">
                    We reserve the right to refuse returns that do not meet our return policy.
                </li>
                <li className="mb-2">
                    If you have any questions or concerns, please contact our customer service.
                </li>
            </ol>
        </div>
    );
};

export default ReturnPolicyPage;
