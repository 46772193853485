// SearchResultsPage.js
import React, { useState,useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Product from '../Components/Product/Product';
import Loader from '../Components/Common/Loader';




const SearchResultsPage = () => {
    const location = useLocation();
    const searchResults = location.state?.searchResults || [];
    const [loading,setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const productsPerPage = 8;

    const totalProducts = searchResults.length;
    const totalPages = Math.ceil(totalProducts / productsPerPage);

    // Calculate the index of the first and last product to display on the current page
    const indexOfLastProduct = currentPage * productsPerPage;
    const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
    const currentProducts = searchResults.slice(indexOfFirstProduct, indexOfLastProduct);

    useEffect(() => {
          window.scrollTo({ top: 0, behavior: 'smooth' });
    }, [])
    return (
        <div>
        <div className=' pt-5  pb-12' >
            <p className='pt-10 text-2xl lg:text-4xl md:text-3xl xl:text-4xl sm:text-2xl text-black  xs:text-lg text-center font-Quicksand'>Your best choice's product are here</p>
            {
                loading ? <Loader /> :
                searchResults.length > 0 ?
                        (
                            <div className="max-w-7xl w-11/12 pt-4 mx-auto grid gap-2 grid-cols-2 md:gap-4 sm:grid-cols-3 lg:grid-cols-4 ">
                                {
                                    currentProducts.map((product) => {
                                        return <Product key={product.id} product={product} />
                                    })
                                }
                            </div>

                        ) :
                        <div className="flex justify-center items-center">
                            <p>No Data Found Of Related Search ...</p>
                        </div>
            }
            <div className="flex items-center justify-center  mt-4 w-full font-Quicksand">
                <button
                    onClick={() => { setCurrentPage(currentPage - 1);   window.scrollTo({ top: 0, behavior: 'smooth' }); }}
                    disabled={currentPage === 1}
                    className="mr-2 px-4 py-2 bg-white border hover:cursor-pointer text-gray-400"
                >
                    <p>{"<"}</p>
                </button>
                <p className='mx-1 text-gray-400'>
                    Page {currentPage} of {totalPages}
                </p>
                <button
                    onClick={() => { setCurrentPage(currentPage + 1);  window.scrollTo({ top: 0, behavior: 'smooth' }); }}
                    disabled={indexOfLastProduct >= searchResults.length}
                    className="ml-2 px-4 py-2 bg-white border hover:cursor-pointer text-gray-400"
                >
                    <p>{">"}</p>
                </button>
            </div>

        </div>
    </div>
    );
};

export default SearchResultsPage;
