// BannerSlice.js
import { createSlice } from "@reduxjs/toolkit";

const BannerSlice = createSlice({
    name: "Banners",
    initialState: {
        banners: [],
    },
    reducers: {
        addBanners: (state, action) => {
            state.banners = action.payload;
        }
    }
});

export const { addBanners } = BannerSlice.actions;
export default BannerSlice.reducer;
