import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/autoplay';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import ReviewModal from './ReviewModal';
import StarRating2 from './StarRating2';



const breakpoints = {
  0: {
    slidesPerView: 1,
  },
  320: {
    slidesPerView: 1,
  },
  768: {
    slidesPerView: 3,
  },
  1024: {
    slidesPerView: 3,
  },
};



const Review = ({ product }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const reviews = product?.reviews || [];

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = String(date.getFullYear()).slice(-2);
    return `${day}-${month}-${year}`;
  };

  return (
    <div className='max-w-7xl w-11/12 mx-auto items-center mt-10 mb-16'>
      <div className='flex justify-between items-center'>
        <p className='text-sm sm:text-2xl font-Quicksand font-semibold'>What People Are Saying !</p>
        <button onClick={handleOpenModal} className='rounded-md bg-black text-white px-2 py-1 whitespace-nowrap'>Add Review</button>
      </div>
      <div className='mt-5'>
        <Swiper
          modules={[Autoplay, Pagination, Navigation]}
          spaceBetween={20}
          slidesPerView={reviews.length === 1 ? 1 : 3}
          pagination={reviews.length > 1 ? { clickable: true } : false}
          navigation={false}
          breakpoints={reviews.length > 1 ? breakpoints : {}}
          autoplay={reviews.length > 1 ? { delay: 3000 } : false}
        >
          {product?.reviews?.map((review) => (
            <SwiperSlide key={review.id} className="my-swiper">
              <div className="p-4 border rounded-lg shadow-lg  bg-slate-100">
                <h2 className="text-xl font-semibold mb-2 text-gray-700">{review?.name}</h2>
                <p className="text-gray-700 mb-4">{review.comment}</p>
                <div className="flex items-center justify-between font-bold">
                  <div className='text-yellow-400 text-[10px] flex gap-[1px] items-center'><StarRating2 initialValue={review?.rating} onSave={5} /></div>
                  {/* <p className="text-sm text-gray-500">{review.name}</p> */}
                  <p className="text-sm text-gray-500">{formatDate(review.created_at)}</p>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      {isModalOpen && <ReviewModal isOpen={true} onClose={handleCloseModal} product={product} />}
    </div>
  )
}

export default Review