import React, { useState, useEffect } from 'react';
import toast from 'react-hot-toast';


function CheckoutcartItem({ item, fullItem, quantity }) {
  const [isFilled, setIsFilled] = useState(false);
  const userToken = localStorage.getItem('token');

  const gstpercent = item?.gst;
  const calculateGstAmount = (price, gstpercent) => (price * gstpercent) / 100;
  const gstAmount = calculateGstAmount(item?.sale_price, gstpercent);
  const salePriceWithGst = Math.round(Number(item.sale_price) + gstAmount).toFixed(2);


  return (
    <div className="font-Quicksand">
      <div className='justify-between rounded-lg p-4 flex items-center'>
        <div className='h-20 w-20 border border-gray-500 rounded-md p-2 relative'>
          <div className='absolute p-2 rounded-full bg-red-500  text-white  w-6 h-6 top-[-10px] right-[-10px] flex items-center justify-center'>{quantity}</div>
          <img src={item.image_data?.thumbnail} alt="cart item" className="relative rounded-lg sm:w-40 w-full h-full object-contain" />
        </div>
        <div className='ml-4 sm:flex sm:w-full sm:justify-between'>
          {fullItem?.is_combo === 1 ? (
            <div>
              {fullItem.variations.map((variation, index) => (
                <div key={index} className="text-sm mb-2">
                  <div className='flex gap-2 font-semibold'>
                    <p className='text-gray-800 whitespace-nowrap'>Product {index + 1} : </p>
                    <span className='text-gray-700'>{variation.name}</span>
                  </div>
                  <div className='flex gap-2 font-semibold'>
                    <p className='text-gray-800'>Color: </p>
                    <span className='text-gray-700'>{variation.color}</span>
                  </div>
                  <div className='flex gap-2 font-semibold'>
                    <p className='text-gray-800'>Size: </p>
                    <span className='text-gray-700'>{variation.size}</span>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div>
              <p className="font-bold text-gray-900 text-sm">{item.name}</p>
              <div className="text-sm ">
                {fullItem?.variations.size && (
                  <div className='flex gap-2 font-semibold'>
                    <p className='text-gray-800'>Size :</p>
                    <span className='text-gray-700'>{fullItem.variations.size}</span>
                  </div>
                )}
              </div>
              <div className="text-sm">
                {fullItem?.variations.color && (
                  <div className='flex gap-2 font-semibold'>
                    <p className='text-gray-800'>Colour : </p>
                    <span className='text-gray-700'>{fullItem.variations.color}</span>
                  </div>
                )}
              </div>
            </div>
          )}
          {/* <div className="">
            <p className="font-bold text-gray-900 text-sm">{item.name}</p>
            <div className="text-sm">
              {fullItem.variations.size && (
                <div className='flex gap-2 font-semibold'>
                  <p className='text-gray-800'>Size :</p> <span className='text-gray-700'>{fullItem.variations.size}</span>
                </div>
              )}
            </div>
            <div className="text-sm">
              {fullItem.variations.color && (
                <div className='flex gap-2 font-semibold'>
                  <p className='text-gray-800'>Colour : </p><span className='text-gray-700'>{fullItem.variations.color}</span>
                </div>
              )}
            </div>
            <div className="text-sm">
              {fullItem.variations.thickness && (
                <div className='flex gap-2 font-semibold'>
                 <p className='text-gray-800'> Thickness : </p><span className='text-gray-700'>{fullItem.variations.thickness}</span>
                </div>
              )}
            </div>
          </div> */}
          <div className=''>
            <p className="text-black font-semibold whitespace-nowrap">Rs. {salePriceWithGst * quantity}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CheckoutcartItem;
