import React, { useEffect } from 'react';

const CancellationPolicyPage = () => {
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
     window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  return (
    <div className="container mx-auto w-11/12 max-w-7xl pt-5 mb-12 p-4">
      <h1 className="text-3xl font-bold mb-4">Cancellation Policy</h1>
      <p className="mb-4">
        This cancellation policy outlines your rights to cancel orders and receive refunds.
      </p>

      <ol className="list-decimal pl-4">
        <li className="mb-2">
          <p className='font-bold'>Eligibility for Cancellation:</p>
          <ul>
            <li>You may cancel your order within 24 hours of placement without any charges.</li>
            <li>Orders for custom-made or personalized items are not eligible for cancellation.</li>
            <li>Orders that have already shipped cannot be canceled.</li>
          </ul>
        </li>
        <li className="mb-2">
          <p className='font-bold'>How to Cancel:</p>
          <ul>
            <li>To cancel an order, please contact our customer service team at <a href="mailto:theroarfit@gmail.com">theroarfit@gmail.com
            </a>.</li>
            <li>Please include your order number and reason for cancellation in your request.</li>
          </ul>
        </li>
        <li className="mb-2">
          <p className='font-bold'>Refunds:</p>
          <ul>
            <li>If your order is eligible for cancellation, you will receive a full refund.</li>
            <li>Refunds will be processed within 5-7 business days of cancellation approval.</li>
            <li>Original shipping charges are non-refundable.</li>
          </ul>
        </li>
        <li className="mb-2">
          <p className='font-bold'>Exceptions:</p>
          <ul>
            <li>Some items may have specific cancellation policies due to their nature or supplier restrictions.</li>
            <li>Please refer to the product details for any additional cancellation terms.</li>
          </ul>
        </li>
      </ol>
    </div>
  );
};

export default CancellationPolicyPage;
