import React from "react";
import {                      
  LuSettings,
} from "react-icons/lu";
import { FaShoppingCart } from "react-icons/fa"
import { BsFillBagHeartFill } from "react-icons/bs"
import { FaHistory } from "react-icons/fa";
import { CgProfile } from "react-icons/cg";
import { useLocation, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

export const menuItems = [
  { id: 1, icon: CgProfile, value: "Profile", path:"/userdashboard/profile" },
  { id: 2, icon: FaHistory, value: "Order History",  path:"/userdashboard/order-history"},
  { id: 3, icon: FaShoppingCart, value: "Cart", path:"/userdashboard/cart" },
  { id: 4, icon: BsFillBagHeartFill, value: "Wishlist", path:"/userdashboard/wishlist" },
  // { id: 5, icon: LuSettings, value: "Settings",  path:"/userdashboard/settings" },
];
const UserSidebar = () => {
    const location = useLocation();
    const navigate = useNavigate();
    console.log(location)
  return (
    <div className="p-4  w-full rounded-lgPlus text-black bg-slate-100 border flex-col justify-between flex h-full  font-Quicksand">
      <div>
        <div className="text-3xl font-bold pb-5 pl-2 text-red-500">User</div>
        <div className="">
          {menuItems.map((item) => (
            <div
              key={item.id}
              className="flex items-center gap-2 lg:text-lg mt-5 hover:cursor-pointer  w-fit" onClick={() => {
                 navigate(item.path)
              }}
            >
              <item.icon width={18} height={18} />
              <div
                className={
                  item.path === location.pathname ? "font-bold text-red-500" : "font-normal duration-150 hover:text-gray-400 "
                }
              >
                {item.value}
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="text-sm">
        <div className="hover:cursor-pointer"><Link to={"/contact-us"}>Help</Link></div>
        <div className="mt-2 hover:cursor-pointer"><Link to={"/contact-us"}>Contact Us</Link></div>
      </div>
    </div>
  );
};

export default UserSidebar;
