// import React, { useState, useEffect } from 'react';
// import { useSelector, useDispatch } from 'react-redux';
// import { Navigate, useNavigate } from 'react-router-dom';
// import { closeModal } from '../../slices/modalSlice';
// import toast from 'react-hot-toast';
// import { add } from '../../slices/cartSlice';
// import axios from 'axios';
// import { v4 as uuidv4 } from 'uuid';


// const QuickAddModal = () => {
//     const dispatch = useDispatch();
//     const showModal = useSelector(state => state.modal.show);
//     const product = useSelector(state => state.modal.product);
//     const [selectedSize, setSelectedSize] = useState('');
//     const [selectedColor, setSelectedColor] = useState('')
//     const [imageInAction, setImageInAction] = useState('');
//     const [availableStock, setAvailableStock] = useState('');
//     const [variations, setVariations] = useState('');
//     const navigate = useNavigate();
//     useEffect(() => {
//         if (showModal && product) {
//             setVariations(product.variations || []);
//         }

//     }, [showModal, product]);

//     console.log("variation in the modal", variations)

//     let gstpercent = 0;
//     let calculateGstAmount = (price, gstpercent) => (price * gstpercent) / 100;
//     let gstAmount = 0;
//     let salePriceWithGst = 0;
//     let hasOptions = null;

//     useEffect(() => {
//         if (product) {
//             setImageInAction(product?.image_data?.original || '');
//             setSelectedSize(product?.size[0]);
//             setSelectedColor(product?.color[0]);
//             const firstSize = product.size[0];
//             setAvailableStock(product.stock[firstSize] || 0);
//         }
//     }, [product]);


//     if (product) {
//         gstpercent = product?.gst;
//         gstAmount = calculateGstAmount(product.sale_price, gstpercent);
//         salePriceWithGst = Math.round(Number(product.sale_price) + gstAmount);
//         hasOptions = product?.size && product?.color;
//     }

//     const handleColorChange = (colorvalue) => {
//         let imageInUse = product.color_images[colorvalue]
//         setSelectedColor(colorvalue);
//         if (colorvalue && imageInUse) {
//             setImageInAction(imageInUse.original);
//         } else {
//             setImageInAction(product.image_data.original);
//         }
//     }

//     const getSessionId = () => {
//         let sessionId = localStorage.getItem('sessionId');
//         if (!sessionId) {
//             sessionId = uuidv4();
//             localStorage.setItem('sessionId', sessionId);
//         }
//         return sessionId;
//     };


//     const addToCart = async () => {
//         const sessionId = getSessionId();
//         if (hasOptions) {
//             if (!selectedSize || !selectedColor) {
//                 toast.error('Please select size, color before adding to the cart.');
//                 return;
//             }
//         }
//         if (availableStock > 0) {
//             const toastId = toast.loading("Loading...")
//             try {
//                 const response = await axios.post('https://admin.theroarfit.com/api/cart/add', {
//                     product_id: product.id,
//                     quantity: 1,
//                     size: selectedSize,
//                     color: selectedColor,
//                 },
//                     {
//                         headers: {
//                             'Content-Type': 'application/json',
//                             'X-Session-ID': sessionId,
//                         },
//                     }
//                 );
//                 dispatch(add(response.data.data))
//                 toast.success("Item added to cart");
//                 toast.dismiss(toastId)
//             } catch (error) {
//                 console.error('Error adding item to the cart:', error);
//                 toast.error('Error adding item to the cart');
//                 toast.dismiss(toastId)
//             }
//         } else {
//             toast.error("Item is out of stock");
//         }
//     }

//     const handleCheckout = async () => {
//         if (hasOptions) {
//             if (!selectedSize || !selectedColor) {
//                 toast.error('Please select size, color before adding to the cart.');
//                 return;
//             } else {
//                 for (const key in product.stock) {
//                     if (key == selectedSize && product.stock[key] > 0) {
//                         await addToCart();
//                         navigate("/checkout");
//                         return;
//                     }
//                 }
//                 toast.error("Cannot proceed to checkout. item is out of stock.");
//             }
//         }

//     };

//     return (
//         <>
//             {showModal && (
//                 <div className="fixed inset-0 z-40 bg-black bg-opacity-50" onClick={() => dispatch(closeModal())}></div>
//             )}
//             {
//                 showModal && (
//                     <div className={` sm:w-[500px] bg-white fixed bottom-4 z-50 right-3 sm:right-4 w-11/12 mx-auto rounded-lg p-4 transition-transform duration-300 ease-in-out ${showModal ? 'transform translate-x-0  shadow-lg' : 'transform translate-x-full'}`}>
//                         {product && (
//                             <div>
//                                 <div className="flex justify-between items-center border-b-[1px] gap-2">
//                                     <img src={imageInAction} alt={product.name} loading='lazy' className="w-24 h-24 mb-2 object-contain" />
//                                     <div className='flex flex-col'>
//                                         <h2>{product.name}</h2>
//                                         <div className='flex gap-2 items-center pb-2'>
//                                             <p className="text-red-600 text-sm sm:text-base font-bold whitespace-nowrap">Rs. {salePriceWithGst}</p>
//                                             <p className="text-gray-600 text-sm whitespace-nowrap line-through decoration-black">Rs. <span className=''>{product.regular_price}</span></p>
//                                         </div>
//                                     </div>
//                                     <button onClick={() => dispatch(closeModal())} className="text-xl text-gray-600 hover:text-gray-800 mb-12">
//                                         &times;
//                                     </button>
//                                 </div>
//                                 <div className='flex flex-col gap-2 max-w-full w-11/12 mx-auto mt-4'>
//                                     {hasOptions && (
//                                         <div className='text-black text-sm mb-2 flex flex-col gap-2'>
//                                             {product.size && (
//                                                 <div className=''>
//                                                     <label htmlFor="sizeDropdown" className="block mb-1 text-sm font-semibold text-black ">Size : <span className='text-[10px]'>{selectedSize}</span></label>
//                                                     <div className="flex flex-wrap gap-2">
//                                                         {product.size.map((sizeOption, index) => (
//                                                             <label key={index} className={` border inline-flex items-center cursor-pointer rounded-lg p-2 ${selectedSize === sizeOption ? 'bg-black text-white' : 'bg-white text-black'}`}>
//                                                                 <input
//                                                                     type="radio"
//                                                                     className="hidden"
//                                                                     name="size"
//                                                                     value={sizeOption}
//                                                                     checked={selectedSize === sizeOption}
//                                                                     onChange={(e) => {
//                                                                         setSelectedSize(e.target.value);
//                                                                         if (e.target.value === '') {
//                                                                             setAvailableStock('');
//                                                                         } else {
//                                                                             for (const key in product.stock) {
//                                                                                 if (key === e.target.value) {
//                                                                                     setAvailableStock(product.stock[key]);
//                                                                                 }
//                                                                             }
//                                                                         }
//                                                                     }}
//                                                                 />
//                                                                 <span className="ml-2 text-sm">{sizeOption || "N/A"}</span>
//                                                             </label>
//                                                         ))}
//                                                     </div>
//                                                 </div>

//                                             )}
//                                             {product.color && (
//                                                 <div className=''>
//                                                     <label htmlFor="colorDropdown" className="block mb-2 text-sm font-semibold text-black ">Color : <span className='text-[10px]'>{selectedColor}</span></label>
//                                                     <div className="flex flex-wrap gap-2">
//                                                         {product.color.map((colorOption, index) => (
//                                                             <label key={index} className={`border inline-flex items-center cursor-pointer rounded-lg p-2 ${selectedColor === colorOption ? 'bg-black text-white' : 'bg-white text-black'}`}>
//                                                                 <input
//                                                                     type="radio"
//                                                                     className="hidden"
//                                                                     name="color"
//                                                                     value={colorOption}
//                                                                     checked={selectedColor === colorOption}
//                                                                     onChange={(e) => {
//                                                                         handleColorChange(e.target.value);
//                                                                     }}
//                                                                 />
//                                                                 <span className="text-sm">{colorOption || "N/A"}</span>
//                                                             </label>
//                                                         ))}
//                                                     </div>
//                                                 </div>

//                                             )}
//                                         </div>
//                                     )}
//                                 </div>
//                                 <div className='flex  items-start gap-3 mt-4 sm:gap-4'>
//                                     <button
//                                         onClick={addToCart}
//                                         className=" whitespace-nowrap border border-black ease-in-out duration-300 bg-white text-black hover:bg-black hover:text-white font-semibold py-2 sm:py-3 px-3 sm:px-16 rounded-full h-full w-11/12 sm:w-full"
//                                     >
//                                         Add to Cart
//                                     </button>
//                                     <button onClick={handleCheckout} className=' whitespace-nowrap bg-red-500 border text-white font-semibold py-2 sm:py-3 px-3 sm:px-16  rounded-full h-full w-11/12 sm:w-full' >Buy Now</button>
//                                 </div>
//                             </div>
//                         )}
//                     </div>
//                 )
//             }
//         </>

//     );
// };

// export default QuickAddModal;

import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { closeModal } from '../../slices/modalSlice';
import toast from 'react-hot-toast';
import { add } from '../../slices/cartSlice';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import ComboProductsOptions from '../Product/ComboProductsOptions';

const QuickAddModal = () => {
    const dispatch = useDispatch();
    const showModal = useSelector(state => state.modal.show);
    const product = useSelector(state => state.modal.product);
    const [selectedSize, setSelectedSize] = useState('');
    const [selectedColor, setSelectedColor] = useState('');
    const [imageInAction, setImageInAction] = useState('');
    const [availableStock, setAvailableStock] = useState('');
    const [variations, setVariations] = useState([]);
    const [comboSelectedOptions, setComboSelectedOptions] = useState({});
    const [comboNotifyEmail, setComboNotifyEmail] = useState('');
    const [comboProducts, setComboProducts] = useState([]);
    const navigate = useNavigate();


    useEffect(() => {
        if (showModal && product) {
            setVariations(product.variations || []);
        }
    }, [showModal, product]);

    useEffect(() => {
        if (product) {
            setComboProducts(product.combo_products);
            setImageInAction(product?.image_data?.original || '');
            if (product.variations.length > 0) {
                const firstColor = product.variations[0].color;
                setSelectedColor(firstColor);
            }
        }
    }, [product]);

    const updateAvailableStock = (color, size) => {
        const variation = variations.find(variant => variant.color === color);
        if (variation) {
            const sizeVariant = variation.variations.find(variant => variant.size === size);
            if (sizeVariant) {
                setAvailableStock(sizeVariant.stock);
            } else {
                setAvailableStock(0);
            }
        } else {
            setAvailableStock(0);
        }
    };

    const handleColorChange = (color) => {
        setSelectedColor(color);
        const imageInUse = product.color_images[color];
        setImageInAction(imageInUse ? imageInUse.original : product.image_data.original);
        if (selectedSize) {
            updateAvailableStock(color, selectedSize);
        } else {
            setAvailableStock('');
        }
    };

    const handleSizeChange = (size) => {
        setSelectedSize(size);
        if (selectedColor) {
            updateAvailableStock(selectedColor, size);
        }
    };

    const addToCart = async () => {
        const sessionId = getSessionId();
        if (product.is_combo === 1) {
            const toastId = toast.loading('Loading...');
            try {
                const comboSelectedProducts = getComboSelectedProducts();
                if (comboSelectedProducts.length === 0) {
                    toast.error('Please select options for all combo products before adding to the cart.');
                    return;
                }
                if (comboSelectedProducts.some(p => !p.selectedSize || !p.selectedColor)) {
                    toast.error('Please select size and color for all combo products.');
                    return;
                }
                if (comboSelectedProducts.some(p => p.availableStock <= 0)) {
                    toast.error('One or more combo products are out of stock.');
                    return;
                }

                const payload = {
                    product_id: product.id,
                    quantity: 1,
                    selectedCombo: comboSelectedProducts,
                    isCombo: true
                };

                const response = await axios.post('https://admin.theroarfit.com/api/cart/add', payload, {
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Session-ID': sessionId,
                    },
                });

                dispatch(add(response.data.data));
                toast.success('Items added to cart');
            } catch (error) {
                console.error('Error adding items to the cart:', error);
                toast.error('Error adding items to the cart');
            } finally {
                toast.dismiss(toastId);
            }
        } else {
            if (!selectedSize || !selectedColor) {
                toast.error('Please select size and color before adding to the cart.');
                return;
            }
            if (availableStock <= 0) {
                toast.error('Item is out of stock.');
                return;
            }

            const toastId = toast.loading('Loading...');
            try {
                const response = await axios.post('https://admin.theroarfit.com/api/cart/add', {
                    product_id: product.id,
                    quantity: 1,
                    size: selectedSize,
                    color: selectedColor,
                }, {
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Session-ID': sessionId,
                    },
                });

                dispatch(add(response.data.data));
                toast.success('Item added to cart');
            } catch (error) {
                console.error('Error adding item to the cart:', error);
                toast.error('Error adding item to the cart');
            } finally {
                toast.dismiss(toastId);
            }
        }
    };


    const handleCheckout = async () => {
        if (product.is_combo === 1) {
            const comboSelectedProducts = getComboSelectedProducts();
            if (comboSelectedProducts.length === 0) {
                toast.error('Please select options for all combo products before proceeding.');
                return;
            }
            if (comboSelectedProducts.some(product => !product.selectedSize || !product.selectedColor)) {
                toast.error('Please select size and color for all combo products.');
                return;
            }
            if (comboSelectedProducts.some(product => product.availableStock <= 0)) {
                toast.error('One or more combo products are out of stock.');
                return;
            }
            await addToCart();
            navigate('/checkout');
        } else {
            if (!selectedSize || !selectedColor) {
                toast.error('Please select size and color before proceeding to checkout.');
                return;
            }
            for (const variation of variations) {
                if (variation.color === selectedColor) {
                    const sizeVariant = variation.variations.find(variant => variant.size === selectedSize);
                    if (sizeVariant && sizeVariant.stock > 0) {
                        await addToCart();
                        navigate('/checkout');
                        return;
                    }
                }
            }
            toast.error('Cannot proceed to checkout. Item is out of stock.');
        }

    };

    const getSessionId = () => {
        let sessionId = localStorage.getItem('sessionId');
        if (!sessionId) {
            sessionId = uuidv4();
            localStorage.setItem('sessionId', sessionId);
        }
        return sessionId;
    };

    let salePriceWithGst = 0;
    if (product) {
        const gstPercent = product?.gst || 0;
        const salePrice = Number(product.sale_price);
        const gstAmount = (salePrice * gstPercent) / 100;
        salePriceWithGst = Math.round(salePrice + gstAmount);
    }

    //for combo products functionality

    const handleComboColorSelect = (productName, color) => {
        setComboSelectedOptions(prevOptions => ({
            ...prevOptions,
            [productName]: {
                ...prevOptions[productName],
                selectedColor: color,
                selectedSize: '',
                availableStock: '',
                showNotifyModal: false
            }
        }));
    };

    const handleComboSizeSelect = (productName, size) => {
        const product = comboProducts.find(p => p.name === productName);
        const colorVariation = product.variations.find(v => v.color === comboSelectedOptions[productName]?.selectedColor);
        const sizeVariant = colorVariation?.variations.find(v => v.size === size);

        setComboSelectedOptions(prevOptions => ({
            ...prevOptions,
            [productName]: {
                ...prevOptions[productName],
                selectedSize: size,
                availableStock: sizeVariant ? sizeVariant.stock : ''
            }
        }));
    };

    const handleComboNotifyClick = (productName) => {
        setComboSelectedOptions(prevOptions => ({
            ...prevOptions,
            [productName]: {
                ...prevOptions[productName],
                showNotifyModal: true
            }
        }));
    };

    const handleComboNotifySubmit = (productName) => {
        setComboSelectedOptions(prevOptions => ({
            ...prevOptions,
            [productName]: {
                ...prevOptions[productName],
                showNotifyModal: false
            }
        }));
    };

    const handleComboNotifyEmailChange = (email) => {
        setComboNotifyEmail(email);
    };

    const getComboSelectedProducts = () => {
        return Object.keys(comboSelectedOptions).map(productName => {
            const { selectedColor, selectedSize, availableStock } = comboSelectedOptions[productName];
            return {
                productName,
                selectedColor,
                selectedSize,
                availableStock
            };
        });
    };

    return (
        <>
            {showModal && (
                <div className="fixed inset-0 z-40 bg-black bg-opacity-50" onClick={() => dispatch(closeModal())}></div>
            )}
            {showModal && (
                <div className={`sm:w-[500px] bg-white fixed bottom-4 z-50 right-3 sm:right-4 w-11/12 mx-auto rounded-lg p-4 transition-transform duration-300 ease-in-out ${showModal ? 'transform translate-x-0 shadow-lg' : 'transform translate-x-full'}`}>
                    {product && (
                        <div>
                            <div className="flex justify-between items-center border-b-[1px] gap-2">
                                <img src={imageInAction} alt={product.name} loading="lazy" className="w-24 h-24 mb-2 object-contain" />
                                <div className="flex flex-col">
                                    <h2>{product.name}</h2>
                                    <div className="flex gap-2 items-center pb-2">
                                        <p className="text-red-600 text-sm sm:text-base font-bold whitespace-nowrap">Rs. {salePriceWithGst}</p>
                                        <p className="text-gray-600 text-sm whitespace-nowrap line-through decoration-black">Rs. <span className="">{product.regular_price}</span></p>
                                    </div>
                                </div>
                                <button onClick={() => dispatch(closeModal())} className="text-xl text-gray-600 hover:text-gray-800 mb-12">
                                    &times;
                                </button>
                            </div>
                            {product?.is_combo === 1 ? (
                                <ComboProductsOptions
                                    comboProducts={comboProducts}
                                    comboSelectedOptions={comboSelectedOptions}
                                    comboNotifyEmail={comboNotifyEmail}
                                    onComboColorSelect={handleComboColorSelect}
                                    onComboSizeSelect={handleComboSizeSelect}
                                    onComboNotifyClick={handleComboNotifyClick}
                                    onComboNotifySubmit={handleComboNotifySubmit}
                                    onComboNotifyEmailChange={handleComboNotifyEmailChange}
                                />
                            ) : (
                                <>
                                    <div className="flex flex-col gap-2 max-w-full w-11/12 mx-auto mt-4">
                                        {variations.length > 0 && (
                                            <div className="text-black text-sm mb-2 flex flex-col gap-2">
                                                <div className="">
                                                    <label htmlFor="colorDropdown" className="block mb-2 text-sm font-semibold text-black">Color : <span className="text-[10px]">{selectedColor}</span></label>
                                                    <div className="flex flex-wrap gap-2">
                                                        {variations.map((variation, index) => (
                                                            <label key={index} className={`border inline-flex items-center cursor-pointer rounded-lg p-2 ${selectedColor === variation.color ? 'bg-black text-white' : 'bg-white text-black'}`}>
                                                                <input
                                                                    type="radio"
                                                                    className="hidden"
                                                                    name="color"
                                                                    value={variation.color}
                                                                    checked={selectedColor === variation.color}
                                                                    onChange={(e) => {
                                                                        handleColorChange(e.target.value);
                                                                    }}
                                                                />
                                                                <span className="text-sm">{variation.color || "N/A"}</span>
                                                            </label>
                                                        ))}
                                                    </div>
                                                </div>
                                                <div className="">
                                                    <label htmlFor="sizeDropdown" className="block mb-1 text-sm font-semibold text-black">Size : <span className="text-[10px]">{selectedSize}</span></label>
                                                    <div className="flex flex-wrap gap-2">
                                                        {variations.find(variant => variant.color === selectedColor)?.variations.map((sizeVariant, index) => (
                                                            <label key={index} className={`border inline-flex items-center cursor-pointer rounded-lg p-2 ${selectedSize === sizeVariant.size ? 'bg-black text-white' : 'bg-white text-black'}`}>
                                                                <input
                                                                    type="radio"
                                                                    className="hidden"
                                                                    name="size"
                                                                    value={sizeVariant.size}
                                                                    checked={selectedSize === sizeVariant.size}
                                                                    onChange={(e) => {
                                                                        handleSizeChange(e.target.value);
                                                                    }}
                                                                />
                                                                <span className="ml-2 text-sm">{sizeVariant.size} ({sizeVariant.stock} in stock)</span>
                                                            </label>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </>
                            )
                            }
                            < div className="flex items-start gap-3 mt-4 sm:gap-4">
                                <button
                                    onClick={addToCart}
                                    className="whitespace-nowrap border border-black ease-in-out duration-300 bg-white text-black hover:bg-black hover:text-white font-semibold py-2 sm:py-3 px-3 sm:px-16 rounded-full h-full w-11/12 sm:w-full"
                                >
                                    Add to Cart
                                </button>
                                <button onClick={handleCheckout} className="whitespace-nowrap bg-red-500 border text-white font-semibold py-2 sm:py-3 px-3 sm:px-16 rounded-full h-full w-11/12 sm:w-full">
                                    Buy Now
                                </button>
                            </div>
                        </div>
                    )}
                </div >
            )}
        </>
    );
};

export default QuickAddModal;



