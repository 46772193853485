import React, { useState, useEffect, useCallback } from 'react'
import logo from "../../Assets/footerlogo.png"
import { Link } from 'react-router-dom';
import axios from 'axios'
import {
  FaInstagram,
  FaFacebook,
  FaYoutube ,
} from "react-icons/fa6";

const Footer = () => {
  const [socialLinksData, setSocialLinksData] = useState(null);
  // const [websiteSettingsData, setWebsiteSettingsData] = useState(null);
  const [loading, setLoading] = useState(true);

  const fetchSocialLinks = useCallback(() => {
    const socialLinksApiUrl = 'https://admin.theroarfit.com/api/social-links';
    axios.get(socialLinksApiUrl)
      .then(response => {
        setSocialLinksData(response.data);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching social-links data:', error);
        setLoading(false);
      });
  }, []);

useEffect(() => {
  fetchSocialLinks();
}, [])

  const links = [
    [
      { label: "Company", key: "header-1" },
      { label: "Home", key: "item-1-1", path: "/" },
      { label: "About us", key: "item-1-2", path: "/about-us" },
      { label: "Contact us", key: "item-1-3", path: "/contact-us" },
      { label: "Term & conditions", key: "item-1-4", path: "/term&conditions" }
    ],
    [
      { label: "Support", key: "header-2" },
      { label: "Return Policy", key: "item-2-1", path: "/return-policy" },
      { label: "Refund Policy", key: "item-2-2", path: "/refund-policy" },
      { label: "Shipping Policy", key: "item-2-3", path: "/shipping-policy" },
      { label: "Privacy Policy", key: "item-2-4", path: "/privacy-policy" },
      { label: "Cancellation Policy", key: "item-2-5", path: "/cancellation-policy" }
    ],
  ];

  return (
    <div className="flex items-end justify-center font-poppins">
      <div className="py-16 bg-black text-white w-full ">
        <div className='w-11/12 max-w-7xl mx-auto grid lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 relative'>
          <div className="  ">
            <div className="footer-img flex items-center">
              <Link to="/">
                <img
                  src={logo}
                  alt=""
                  className="h-[56px] w-[156px]"
                />
              </Link>
            </div>

            <div className="footer-icons flex items-center space-x-3 pt-4 p-2">
              {socialLinksData && socialLinksData.data && socialLinksData.data.social_links && (
                <Link to={socialLinksData.data.social_links.facebook_url} target="_blank" rel="noopener noreferrer">
                  <FaFacebook  className='w-14 h-14 p-2 rounded-full bg-red-600 hover:bg-white hover:text-black cursor-pointer' />
                </Link>
              )}
              {socialLinksData && socialLinksData.data && socialLinksData.data.social_links && (
                <Link to={socialLinksData.data.social_links.instagram_url} target="_blank" rel="noopener noreferrer">
                  <FaInstagram className='w-14 h-14 p-2 rounded-full bg-red-600 hover:bg-white hover:text-black cursor-pointer' />
                </Link>
              )}
              {socialLinksData && socialLinksData.data && socialLinksData.data.social_links && (
                <Link to={socialLinksData.data.social_links.youtube_url} target="_blank" rel="noopener noreferrer">
                  <FaYoutube  className='w-14 h-14 p-2 rounded-full bg-red-600 hover:bg-white hover:text-black cursor-pointer' />
                </Link>
              )}
            </div>
          </div>
          <div className="mx-2 grid w-full py-5 sm:py-0 grid-cols-2 ">
            {links.map((col, index) => {
              return (
                <ul className={`col col-${index + 1}`} key={`col-${index}`}>
                  {col.map((link, index) => {
                    return (
                      <li key={`link-${col}-${index}`}>
                        <Link
                          to={link.path}
                          key={`link-${col}-${index}`}
                          className={`text-white cursor-pointer ${link.key === "header-1" || link.key === "header-2"
                            ? "text-2xl text-white"
                            : ""
                            }`}
                        >
                          {link.label}
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              );
            })}
          </div>
          <div className="footer-form flex flex-col mt-0">
            <label className="text-lg font-semibold text-white">
              Stay up to date
            </label>
            <input
              type="email"
              placeholder="Subscribe to our email"
              className="mt-2 w-full border-none rounded-lg py-3 px-6"
            />
            <div className="infos text-white pt-4">
              <span>Copyright ©️ Techno Chem Overseas .
                Powered by <Link to={"https://technonize.com"}>Technonize Media</Link></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer


