import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom';
import { FaStarHalfAlt } from "react-icons/fa";
import StarRating from '../Common/StarRating';
import { openModal, setProduct } from '../../slices/modalSlice';
import { CiShoppingCart } from "react-icons/ci";




function Product({ product }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedColor, setSelectedColor] = useState('')
  const [selectedColorImage, setSelectedColorImage] = useState(product.image_data.medium);

  const gstpercent = product?.gst;
  const calculateGstAmount = (price, gstpercent) => (price * gstpercent) / 100;
  const gstAmount = calculateGstAmount(product.sale_price, gstpercent);
  const salePriceWithGst = Math.round(Number(product.sale_price) + gstAmount).toFixed(2);

  useEffect(() => {
    if (selectedColor && product.color_images[selectedColor]) {
      setSelectedColorImage(product.color_images[selectedColor].medium);
    } else {
      setSelectedColorImage(product.image_data.medium);
    }
  }, [selectedColor, product.color_images, product.image_data.medium]);


  function getRandomNumber(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  const handleQuickAdd = () => {
    dispatch(setProduct(product));
    dispatch(openModal());
  };

  return (
    <div className=" bg-white flex flex-col gap-4 justify-between items-center font-Quicksand
    group  hover:cursor-pointer relative shadow-[1px_1px_10px_9px_#edf2f7]  rounded-md  pb-10">

      <div className="relative h-full p-1 rounded-md transition duration-300 ease-in" >
        {product.tag && (
          <div
            className="absolute top-2 sm:top-4 right-2 px-1 sm:px-2 py-[1px] rounded z-30 text-[8px] sm:text-sm"
            style={{ backgroundColor: product.tag.bg_color, color: product.tag.txt_color }}
          >
            {product.tag.tag_name}
          </div>
        )}
        <div
          className="absolute font-bold top-2 sm:top-4 left-2 px-1 sm:px-2 py-[1px] z-0 text-[8px] sm:text-[12px] bg-red-600 text-white rounded-full"
        >
          Saving Rs. {(product.regular_price - salePriceWithGst).toFixed(2)}
        </div>
        <img
          className=" aspect-square object-contain"
          src={selectedColorImage}
          loading='lazy'
          alt="sale-products"
          onClick={() => { navigate(`/product-detail/${product.slug}/${product.id}`) }}
        />
        <button
          onClick={handleQuickAdd}
          className='hidden sm:flex absolute right-8 bottom-0 sm:bottom-1 rounded-full font-mono items-center justify-center px-2 py-2 sm:px-3 sm:py-3 bg-black text-white font-semibold opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-opacity duration-300 ease-in-out text-[0.55rem] sm:text-[0.75rem]'>
          + Quick add
        </button>
        <button
          onClick={handleQuickAdd}
          className='flex sm:hidden absolute right-2 -bottom-[15px] rounded-full text-black items-center justify-center border border-gray-300 p-1'>
          <CiShoppingCart size={20} />
        </button>
      </div>
      <div className='flex flex-col justify-start items-left pl-2 w-full gap-1 sm:gap-2' onClick={() => { navigate(`/product-detail/${product.slug}/${product.id}`) }}>
        <p className=" text-gray-500  text-[12px] text-left uppercase">Roarfit</p>
        <p className="text-black font-bold text-sm sm:text-[16px] text-left truncate mt-1">{product.name}</p>
        <div className='text-yellow-400 text-[10px] flex gap-[1px] items-center'><StarRating initialValue={getRandomNumber(4, 4)} onSave={5} /> <FaStarHalfAlt className='text-[16px]' /><span className='text-black font-bold ml-2'>({getRandomNumber(15, 50)})</span></div>
        <div className='flex gap-2 items-center pb-2'>
          <p className="text-red-600 text-sm sm:text-base font-bold whitespace-nowrap">Rs. {salePriceWithGst}</p>
          <p className="text-gray-600 text-sm whitespace-nowrap line-through decoration-black">Rs. <span className=''>{product.regular_price}</span></p>
        </div>
      </div>
    </div>
  )
}

export default Product


