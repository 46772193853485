import React,{useEffect} from 'react'
import ContactDetail from '../Components/Contact/ContactDetail'
import ContactForm from '../Components/Contact/ContactForm';

const ContactUs = () => {
    useEffect(() => {
        // Scroll to the top of the page when the component mounts
          window.scrollTo({ top: 0, behavior: 'smooth' });
      }, []);
    return (
        <div className=''>
            <div className="mx-auto mb-20 flex w-11/12 max-w-7xl flex-col justify-between gap-10 text-white lg:flex-row pt-5 font-Quicksand">
                {/* Contact Details */}
                <div className="lg:w-[40%]">
                    <ContactDetail />
                </div>

                {/* Contact Form */}
                <div className="lg:w-[60%]">
                    <ContactForm />
                </div>
            </div>
        </div>
    )
}

export default ContactUs