import React, { useState } from 'react';
import StarRating from './StarRating';
import axios from 'axios';

const ReviewModal = ({ isOpen, onClose, product }) => {
    const [rating, setRating] = useState(0);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [comment, setComment] = useState('');
    const [image, setImage] = useState(null);
    const product_id = product.id;

    const handleCancel = () => {
        onClose();
    };

    const handleSaveRating = (value) => {
        setRating(value);
    };

    const handleSubmit = async(event) => {
        event.preventDefault();
        const formData = new FormData();
        formData.append('rating', rating);
        formData.append('name', name);
        formData.append('email', email);
        formData.append('comment', comment);
        formData.append('product_id', product_id);
        if (image) {
            formData.append('image', image);
        }

        try {
           const res =  await axios.post('https://admin.theroarfit.com/api/add-review', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            console.log("res of rating", res);
            onClose();
        } catch (error) {
            console.error('Error submitting review:', error);
        }
    };

    return (
        <div className={`fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center z-10 mt-16  ${isOpen ? 'block' : 'hidden'}`}>
            <div className="bg-white p-4 rounded-lg max-w-md w-full mx-2">
                <div className="flex justify-between items-center mb-4">
                    <h2 className="text-lg font-semibold">Write a Review</h2>
                    <hr />
                    <button onClick={handleCancel} className="text-gray-600 hover:text-gray-800">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>
                </div>
                <div className="mb-4">
                    <label htmlFor="rating" className="block text-sm font-medium text-gray-700 mb-1">Rating</label>
                    <StarRating initialValue={rating} onSave={handleSaveRating} />
                </div>
                <div className="mb-4">
                    <label htmlFor="name" className="block text-sm font-medium text-gray-700 mb-1">Name</label>
                    <input type="text" id="name" value={name} onChange={(e) => setName(e.target.value)} className=" py-1 px-2 w-full border border-gray-200 rounded-md shadow-sm sm:text-sm outline-none" />
                </div>
                <div className="mb-4">
                    <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">Email</label>
                    <input type="email" id="email" value={email} onChange={(e) => setEmail(e.target.value)} className=" py-1 px-2 w-full border border-gray-200 rounded-md shadow-sm sm:text-sm outline-none" />
                </div>
                <div className="mb-4">
                    <label htmlFor="review" className="block text-sm font-medium text-gray-700 mb-1">Write Review</label>
                    <textarea id="comment" value={comment} onChange={(e) => setComment(e.target.value)} rows="3" className=" py-1 px-2 w-full border border-gray-200 rounded-md shadow-sm sm:text-sm outline-none" />
                </div>
                <div className="mb-4">
                    <label htmlFor="image" className="block text-sm font-medium text-gray-700 mb-1">Upload Image</label>
                    <input type="file" accept="image/*" id="image" onChange={(e) => setImage(e.target.files[0])} className=" py-1 px-2 w-full border border-gray-200 rounded-md shadow-sm sm:text-sm outline-none" />
                </div>
                <div className="flex justify-end">
                    <button onClick={handleSubmit} className="bg-black text-white px-2 py-1 rounded-md mr-2">Submit</button>
                    <button onClick={handleCancel} className="text-white px-2 py-1 rounded-md bg-red-500">Cancel</button>
                </div>
            </div>
        </div>
    );
};

export default ReviewModal;
