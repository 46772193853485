import React, { useState, useEffect } from "react";
import axios from "axios";
import { FaRegEye } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

const Transactions = () => {
  const userToken = JSON.parse(localStorage.getItem('token'));
  const [orderData, setOrderData] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const fetchOrderData = async () => {
    try {
      const response = await axios.get('https://admin.theroarfit.com/api/orders', {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userToken}`,
        },
      });
      console.log("order data response", response.data)
      setOrderData(response?.data?.data?.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching order data:', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    fetchOrderData();
  }, []);

  const formatCreatedAt = (createdAt) => {
    const date = new Date(createdAt);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear().toString().slice(2);
    return `${day}-${month}-${year}`;
  };

  return (
    <>
      {/* {
          loading ? <Loader /> : orderData.data.length > 0 ? (
            <div>
              <Table className="">
                <Thead>
                  <Tr className="text-left">
                    <Th className="px-2 py-2">Order Id</Th>
                    <Th className="px-2 py-2">Amount</Th>
                    <Th className="px-2 py-2">Method</Th>
                    <Th className="px-2 py-2">Date</Th>
                    <Th className="px-2 py-2">Actions</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {orderData.data.map((transaction) => (
                    <Tr key={transaction.id} className="">
                      <Td className="px-2 py-2">#{transaction.id}</Td>
                      <Td className="px-2 py-2">Rs. {transaction.total_amount}</Td>
                      <Td className="px-2 py-2">{transaction.payment_mode}</Td>
                      <Td className="px-2 py-2">{formatCreatedAt(transaction.created_at)}</Td>
                      <Td className="px-2 py-2">
                        <button
                          className="border rounded-md bg-slate-200 text-xs px-2 py-1"
                          onClick={() => {
                            const orderId = transaction.id
                            navigate(`/userdashboard/view-order?order_id=${orderId}`);
                          }}
                        >
                          View
                        </button>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>

            </div> */}
      <div className="relative overflow-x-auto rounded-md">
        <table className="w-full">
          <thead className="border-b font-medium dark:border-neutral-500">
            <tr className="text-md font-semibold tracking-wide text-left text-gray-900 bg-gray-100 border-b border-gray-600">
              <th scope="col" className="px-4 py-3 whitespace-nowrap">Order Id</th>
              <th scope="col" className="px-4 py-3 whitespace-nowrap">Amount</th>
              <th scope="col" className="px-4 py-3 whitespace-nowrap">Method</th>
              <th scope="col" className="px-4 py-3 whitespace-nowrap">Date</th>
              <th scope="col" className="px-4 py-3 whitespace-nowrap">Action</th>
            </tr>
          </thead>
          <tbody className="bg-white">
            {loading ? (
              <tr>
                <td colSpan="5" className="px-4 py-3 font-medium">Data loading ....</td>
              </tr>
            ) : (
              orderData?.length > 0 ? (
                orderData.map(order => (
                  <tr key={order?.id} className="border-b dark:border-neutral-500">
                    <td className="whitespace-nowrap px-4 py-3 font-medium">{order?.id}</td>
                    <td className="whitespace-nowrap px-4 py-3 font-medium">{order?.total_amount}</td>
                    <td className="whitespace-nowrap px-4 py-3 flex gap-2 items-center">
                      {order?.payment_mode}
                    </td>
                    <td className="whitespace-nowrap px-4 py-3 font-medium">{formatCreatedAt(order?.created_at)}</td>
                    <td className="whitespace-nowrap px-4 py-3 flex gap-1">
                      <button
                        onClick={() => {
                          const orderId = order?.id;
                          navigate(`/userdashboard/view-order?order_id=${orderId}`);
                        }}
                        className="rounded-full px-1 py-1 font-medium bg-blue-500 text-white">
                        <FaRegEye />
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr className="border-b dark:border-neutral-500">
                  <td colSpan="5" className="text-center text-gray-500 pt-2 font-semibold">
                    No data available
                  </td>
                </tr>
              )
            )}
          </tbody>
        </table>
      </div>


      {/* //   ) : (
        //     <div className='flex flex-col items-center h-[50%] md:h-full gap-3'>
        //       <h1 className='text-black font-bold'>We couldn't find your order history </h1>
        //       <Link to='/'>
        //         <button className='bg-black text-white font-bold py-2 px-4 rounded w-full'>Order Now</button>
        //       </Link>
        //     </div>
        //   )
        // } */}
    </>

  );
};

export default Transactions;
