import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import TrackingLoader from '../Components/Common/TrackingLoader';
import trackingimg from "../Assets/trackingimg.jpg"
import { FaShippingFast } from "react-icons/fa";
import { MdLocalShipping } from "react-icons/md";
import deliveryimg from "./../Assets/delivery-package.gif"

function OrderTrackingPage() {
    const [awbNumber, setAwbNumber] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [bearerToken, setBearerToken] = useState('');
    const [trackingData, setTrackingData] = useState(null);
    const dataRef = useRef(null);

    const handleInputChange = (e) => {
        setAwbNumber(e.target.value);
    };
    useEffect(() => {
        if (trackingData && dataRef.current) {
            dataRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [trackingData]);

    const fetchBearerToken = async () => {
        try {
            const response = await axios.get('https://admin.theroarfit.com/api/shiprocket-token')
            const token = response.data.api_token;
            setBearerToken(token);

        } catch (error) {
            console.error('Error fetching bearer token:', error);
            throw new Error('Failed to obtain bearer token');
        }
    };

    useEffect(() => {
        fetchBearerToken();
    }, []);

    const trackShipment = async () => {
        setLoading(true);
        try {
            const response = await axios.get(`https://admin.theroarfit.com/api/track/${awbNumber}`, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${bearerToken}`
                }
            });
            setTrackingData(response.data.tracking_data);
            if (dataRef.current) {
                dataRef.current.scrollIntoView({ behavior: 'smooth' });
            }
            setError(null);
        } catch (error) {
            console.error('Error fetching order data:', error);
            setTrackingData(null);
            setError(`No data found for the provided AWB Number ${awbNumber}.`);
        } finally {
            setLoading(false);
        }
    };

    const formatEDD = (dateString) => {
        if (!dateString) return '';
        const eddDate = new Date(dateString);
        const formattedDate = `${eddDate.getDate().toString().padStart(2, '0')}-${(eddDate.getMonth() + 1).toString().padStart(2, '0')}-${eddDate.getFullYear().toString().slice(-2)}`;
        const formattedTime = eddDate.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true });
        return `${formattedDate} ${formattedTime}`;
    };


    return (
        <>
            <div className="mx-auto w-11/12 max-w-7xl pt-5  mb-12  p-4 h-full min-h-screen">
                <div className="relative flex flex-col  lg:pt-0 lg:flex-col lg:pb-0 gap-4">
                    <div className="inset-y-0 top-0 right-0 z-0 w-full max-w-xl  mx-auto md:px-0 lg:pr-0 lg:mb-0 lg:mx-0 lg:w-7/12 lg:max-w-full lg:absolute xl:px-0">
                        <svg
                            className="absolute left-0 hidden h-full text-white transform -translate-x-1/2 lg:block"
                            viewBox="0 0 100 100"
                            fill="currentColor"
                            preserveAspectRatio="none slice"
                        >
                            <path d="M50 0H100L50 100H0L50 0Z" />
                        </svg>
                        <img
                            className="object-cover w-full h-56 rounded shadow-lg lg:rounded-none lg:shadow-none md:h-96 lg:h-full"
                            src={trackingimg}
                            alt=""
                        />
                    </div>
                    <div className="relative flex flex-col items-start w-full max-w-xl mx-auto  lg:max-w-screen-xl">
                        <div className="mb-16 lg:my-40 lg:max-w-lg lg:pr-5">
                            <h2 className="mb-5 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none">
                                Track your order
                            </h2>
                            <p className="pr-5 mb-5 text-base text-gray-700 md:text-lg">
                                Thank you for choosing the Roarfit ! Track the status of your order using the order id.
                                If you have any questions or concerns regarding your order, please feel free to <span className='text-blue-600 whitespace-nowrap'><Link to={"/contact-us"} >contact us</Link></span>
                            </p>
                            <div className="flex  items-start flex-col sm:flex-row gap-5">
                                <input
                                    type="text"
                                    placeholder="Enter AWB Number"
                                    value={awbNumber}
                                    onChange={handleInputChange}
                                    className="border border-gray-300 px-4 py-2 rounded-md mr-2 focus:outline-none"
                                />
                                <button
                                    onClick={trackShipment}
                                    disabled={!awbNumber || loading}
                                    className="bg-black text-white px-4 py-2 rounded-md"
                                >
                                    {loading ? 'Loading...' : 'Track Order'}
                                </button>
                            </div>
                            {error && <p className="text-red-500 mt-2">{error}</p>}
                        </div>
                    </div>
                </div>
                {
                    trackingData && (trackingData.track_status === 1) ? (
                        <div ref={dataRef} className="mt-4 mb-5 h-full font-Quicksand">

                            {loading ? (
                                <p className='text-center'><TrackingLoader /></p>
                            ) :
                                (
                                    <div className='flex flex-col-reverse sm:flex-row justify-between gap-5'>
                                        <div className=''>
                                            <ol className="text-gray-500  dark:text-gray-400 border bg-slate-50 rounded-md border-gray-200 ">
                                                {trackingData.shipment_track_activities.slice(0, -1).map((activity, index) => {
                                                    // Parse the date string
                                                    const dateTime = new Date(activity.date);
                                                    const day = dateTime.toLocaleDateString('en-GB', { day: '2-digit' });
                                                    const month = dateTime.toLocaleDateString('en-GB', { month: 'short' });
                                                    const year = dateTime.toLocaleDateString('en-GB', { year: 'numeric' });
                                                    const formattedDate = `${day} ${month} ${year}`;
                                                    const time = dateTime.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true });

                                                    return (
                                                        <li key={index} className=" grid grid-cols-[120px_30px_auto] sm:grid-cols-[150px_30px_auto] max-w-[600px] lastchild">
                                                            <div className=" flex flex-col items-center text-xs sm:text-base p-4">
                                                                <p className="text-gray-600 font-semibold">{formattedDate}</p>
                                                                <p className="text-gray-600 font-semibold">{time}</p>
                                                            </div>
                                                            <div className="flex  justify-center relative  top-6 bg-center  bg-repeat-y" style={{ backgroundImage: `url('/line.svg')`, backgroundSize: "4px", }} id='bgRemove'>
                                                                <div className="w-4 h-4 bg-slate-300 rounded-full ring-4  ring-green-300">
                                                                </div>
                                                            </div>

                                                            <div className="text-black  text-xs sm:text-base p-4">
                                                                <p className="font-bold">Activity: <span className='font-normal text-gray-600'>{(activity.activity === 'Data Received') ? ("Order Recieved") : (activity.activity)}</span></p>
                                                                <p className="font-bold">Location: <span className='font-normal text-gray-600'>{activity.location}</span></p>
                                                            </div>
                                                        </li>
                                                    );
                                                })}
                                            </ol>
                                        </div>
                                        <div className=' bg-slate-50 border border-gray-200 rounded-md p-4 flex-grow'>
                                            <p className='font-bold text-lg'>Status: <span className='text-lg text-red-500'>{trackingData.shipment_track[0]?.current_status}</span></p>
                                            <p className='font-bold'>AWB Number : <span className='text-blue-500'>{trackingData.shipment_track[0]?.awb_code}</span></p>
                                            <p className='font-bold'>Estimated Delivery Date : <span className='text-blue-500'>{formatEDD(trackingData.shipment_track[0]?.edd)}</span></p>
                                            <ul className="relative flex flex-row gap-x-2 mt-2">
                                                <li className="shrink basis-0 flex-1 group">
                                                    <div className="min-w-7 min-h-7 w-full inline-flex items-center text-xs align-middle">
                                                        <span className="size-7 w-10 h-10 flex justify-center items-center flex-shrink-0 bg-gray-100 font-medium text-gray-800 rounded-full dark:bg-gray-700 dark:text-white">
                                                            <FaShippingFast className='text-green-300 text-2xl' />
                                                        </span>
                                                        <div className="ms-2 w-full h-px flex-1 bg-gray-200 group-last:hidden dark:bg-gray-700"></div>
                                                    </div>
                                                    <div className="mt-3">
                                                        <span className="block text-sm font-medium text-gray-800">
                                                            {trackingData.shipment_track[0]?.origin}
                                                        </span>
                                                    </div>
                                                </li>
                                                <li className="shrink basis-0 flex-1 group">
                                                    <div className="min-w-7 min-h-7 w-full inline-flex items-center text-xs align-middle">
                                                        <span className="size-7  w-16  h-10  flex justify-center items-center flex-shrink-0">
                                                            <img src={deliveryimg} alt="" />
                                                        </span>
                                                        <div className="ms-2 w-full h-px flex-1 bg-gray-200 group-last:hidden dark:bg-gray-700"></div>
                                                    </div>

                                                </li>
                                                <li className="shrink basis-0 flex-1 group">
                                                    <div className="min-w-7 min-h-7 w-full inline-flex items-center text-xs align-middle">
                                                        <span className="size-7 w-10 h-10 flex justify-center items-center flex-shrink-0 bg-gray-100 font-medium text-gray-800 rounded-full dark:bg-gray-700 dark:text-white">
                                                            <MdLocalShipping className='text-green-300 text-2xl' />
                                                        </span>
                                                        <div className="ms-2 w-full h-px flex-1  group-last:hidden dark:bg-gray-700"></div>
                                                    </div>
                                                    <div className="mt-3">
                                                        <span className="block text-sm font-medium text-gray-800">
                                                            {trackingData.shipment_track[0]?.destination}
                                                        </span>
                                                    </div>
                                                </li>
                                            </ul>

                                        </div>
                                    </div>
                                )
                            }


                        </div>
                    ) :
                        (
                            <div className='mt-5 p-4'>
                                <p className='text-3xl text-center text-red-500'>{trackingData?.error}</p>
                            </div>
                        )
                }
            </div>

        </>
    );
}

export default OrderTrackingPage;
