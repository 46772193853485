import React from 'react'
import { Link} from "react-router-dom";

const ErrorPage = () => {
  return (
    <div>
        <div className="flex pt-5 justify-center mb-5">
        <div className="px-4">
          <div className="">
            <div className="flex flex-col items-center justify-center">
              <h1 className="font-bold text-black text-9xl">404</h1>
              <p className="mb-2 text-2xl font-bold text-center text-gray-800 md:text-3xl">
                <span className="text-red-500">Oops!</span> Page{" "}
              </p>
              <p className="mb-8 text-center text-gray-500 md:text-lg">
                The page you’re looking for doesn’t exist.
              </p>
              <Link
                to="/"
                className="px-5 py-2 rounded-md text-blue-100 bg-black"
              >
                Go home
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ErrorPage