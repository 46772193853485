import React, { useEffect, useState } from 'react'
import { useForm } from "react-hook-form"
import CountryCode from "../../data/countrycode.json"
import axios from 'axios'
import toast from 'react-hot-toast'


const ContactUsForm = () => {

    const [loading, setLoading] = useState(false);
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors, isSubmitSuccessful }
    } = useForm();

    const submitContactForm = async (data) => {
        // console.log("Logging Data", data);
        let loadingToastId;

        try {
            loadingToastId = toast.loading('Submitting...');
            const response = await axios.post(
                'https://admin.theroarfit.com/api/contact-form-submit',
                {
                    "fname": data.firstname,
                    "lname": data.lastname,
                    "email": data.email,
                    "countrycode": data.countrycode,
                    "phone": data.phoneNo,
                    "message": data.message
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );
            toast.success("Email sent successfully");
            // console.log("res",response)
        } catch (error) {
            console.error('Error submitting contact form:', error.message);
        }
        finally {
            // Close the loading toast regardless of success or failure
            toast.dismiss(loadingToastId);
        }

    };


    useEffect(() => {
        if (isSubmitSuccessful) {
            reset({
                email: "",
                firstname: "",
                lastname: "",
                message: "",
                phoneNo: "",
            })
        }
    }, [reset, isSubmitSuccessful]);

    return (
        <form
            onSubmit={handleSubmit(submitContactForm)}
            className="flex flex-col gap-7"
        >
            <div className='flex flex-col gap-5 lg:flex-row'>
                <div className='flex flex-col gap-2 lg:w-[48%]'>
                    {/* firstName */}
                    <label htmlFor='firstname' className='lable-style'>First Name</label>
                    <input
                        type='text'
                        name='firstname'
                        id='firstname'
                        placeholder='Enter first name'
                        className='form-style'
                        {...register("firstname", { required: true })}
                    />
                    {
                        errors.firstname && (
                            <span className='-mt-1 text-[12px] text-red-500'>
                                Please enter Your name
                            </span>
                        )
                    }
                </div>
                {/* lastName */}
                <div className='flex flex-col gap-2 lg:w-[48%]'>
                    <label htmlFor='lastname' className='lable-style'>Last Name</label>
                    <input
                        type='text'
                        name='lastname'
                        id='lastname'
                        className='form-style'
                        placeholder='Enter Last name'
                        {...register("lastname")}
                    />
                </div>
            </div>
            {/* email */}
            <div className='flex flex-col gap-2'>
                <label htmlFor='email' className='lable-style'>Email Address</label>
                <input
                    type='email'
                    name='email'
                    id='email'
                    className='form-style'
                    placeholder='Enter email Address'
                    {...register("email", { required: true })}
                />
                {
                    errors.email && (
                        <span className="-mt-1 text-[12px] text-red-500">
                            Please enter your email address
                        </span>
                    )
                }
            </div>
            {/* phoneNo */}
            <div className='flex flex-col gap-2'>
                <label htmlFor='phonenumber' className='lable-style'>Phone Number</label>
                <div className="flex gap-5">
                    <div className="flex w-[81px] flex-col gap-2">
                        <select
                            type="text"
                            name="firstname"
                            id="firstname"
                            placeholder="Enter first name"
                            className="form-style"
                            {...register("countrycode", { required: true })}
                        >
                            {CountryCode.map((ele, i) => {
                                return (
                                    <option key={i} value={ele.code}>
                                        {ele.code} -{ele.country}
                                    </option>
                                )
                            })}
                        </select>
                    </div>
                    <div className="flex w-[calc(100%-90px)] flex-col gap-2">
                        <input
                            type="tel"
                            name="phonenumber"
                            id="phonenumber"
                            placeholder="12345 67890"
                            className="form-style"
                            {...register("phoneNo", {
                                required: {
                                    value: true,
                                    message: "Please enter your Phone Number.",
                                },
                                maxLength: { value: 12, message: "Invalid Phone Number" },
                                minLength: { value: 10, message: "Invalid Phone Number" },
                            })}
                        />
                    </div>
                </div>
                {
                    errors.phoneNo && (
                        <span className="-mt-1 text-[12px] text-red-500">
                            {errors.phoneNo.message}
                        </span>
                    )
                }
            </div>
            {/* message */}
            <div className='flex flex-col gap-2'>
                <label htmlFor='message' className='lable-style'>Message</label>
                <textarea
                    name='message'
                    id='message'
                    cols="30"
                    className='form-style'
                    rows="7"
                    placeholder='Enter Your message here'
                    {...register("message", { required: true })}
                />
                {
                    errors.message && (
                        <span className="-mt-1 text-[12px] text-red-500">
                            PLease enter your message.
                        </span>
                    )
                }
            </div>
            <button type='submit'
                className={`rounded-md bg-slate-400 px-6 py-3 text-center text-[13px] font-bold text-black shadow-[2px_2px_0px_0px_rgba(255,255,255,0.18)] 
                    ${!loading &&
                    "transition-all duration-200 hover:scale-95 hover:shadow-none"
                    }  disabled:bg-richblack-500 sm:text-[16px] `}>
                Send Message
            </button>
        </form>
    )
}

export default ContactUsForm
