import React,{useEffect} from 'react';
import { Link } from 'react-router-dom';

const TermAndConditions = () => {
    useEffect(() => {
        // Scroll to the top of the page when the component mounts
          window.scrollTo({ top: 0, behavior: 'smooth' });
      }, []);
    
    return (
        <div className="container mx-auto w-11/12 max-w-7xl pt-5 mb-12 p-4">
            <h1 className="text-3xl font-bold mb-4">Roarfit Term and Conditions</h1>
            <ol className="list-decimal pl-4">

                <li className="mb-2">
                    <p className='font-bold'>Product Information :</p>
                    <p>We do our best to show you accurate pictures and details of our RoarFit gym gear. Colors or features might vary a bit.   
                    </p>
                </li>
                <li className="mb-2">
                    <p className='font-bold'>Order Confirmation :</p>
                    <p>After you place a RoarFit order, we'll send you an email to confirm. This email acknowledges your order but doesn't mean we've accepted it yet.  
                    </p>
                </li>
                <li className="mb-2">
                    <p className='font-bold'>Pricing and Payment :</p>
                    <p>RoarFit product prices are in rupees and include taxes. We might change prices, but we'll let you know. Pay securely using PhonePe. We'll ship your order after we get the payment.
                    </p>
                </li>
                <li className="mb-2">
                    <p className='font-bold'>Shipping and Delivery :</p>
                    <p>We aim to process and send out RoarFit orders within 10 days. Delivery times vary. We can't control delays from the shipping company or unexpected events.
                    </p>
                </li>
                <li className="mb-2">
                    <p className='font-bold'>Returns and Refund :</p>
                    <p>Check our "<span className='text-blue-500'><Link to={"/return-policy"}>Return</Link></span>  and <span className='text-blue-500'><Link to={"/refund-policy"}>Refund</Link></span> " policy for how to return or refund RoarFit products. Some conditions apply.
                    </p>
                </li>
                <li className="mb-2">
                    <p className='font-bold'>Warranty :</p>
                    <p>RoarFit products may have a warranty. Look at the product details for info. We're not responsible for damage from misuse, improper care, or normal wear.
                    </p>
                </li>
                <li className="mb-2">
                    <p className='font-bold'>Account Information :</p>
                    <p>Keep your RoarFit account info safe. You're responsible for what happens with your account.
                    </p>
                </li>
                <li className="mb-2">
                    <p className='font-bold'>User Content :</p>
                    <p>If you share reviews or comments, we can use them. Make sure your content follows our rules and doesn't violate others' rights.
                    </p>
                </li>
                <li className="mb-2">
                    <p className='font-bold'>Intellectual Property :</p>
                    <p>Everything on the RoarFit website belongs to RoarFit. Don't use or copy it without permission.
                    </p>
                </li>
                <li className="mb-2">
                    <p className='font-bold'>Limitation of Liability :</p>
                    <p>We're not responsible for indirect or consequential damages. Our total responsibility won't be more than what you paid for the product.
                    </p>
                </li>
                <li className="mb-2">
                    <p className='font-bold'>Contact Information :</p>
                    <p>Questions or worries? Click on this <span className='text-blue-500'><Link to={"/contact-us"}>link</Link></span> and contact us.
                    </p>
                </li>
                <li className="mb-2">
                    <p className='font-bold'>Changes to Terms :</p>
                    <p>We might update these terms without telling you first. Check them regularly.
                    </p>
                </li>
                
            </ol>
        </div>
    );
};

export default TermAndConditions;
