import React, { useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';
import { featureData } from '../../data/featureData';

const FeatureSlider = () => {
    return (
        <div className=' max-w-7xl w-11/12 pt-4 mx-auto bg-slate-100 border rounded-md mb-4'>

            <Swiper
                slidesPerView={4}
                spaceBetween={30}
                pagination={{
                    clickable: true,
                }}
                modules={[Pagination]}
                className="mySwiper"
                breakpoints={{
                    200: {
                        slidesPerView: 1,
                        spaceBetween: 20
                    },
                    300: {
                        slidesPerView: 1,
                        spaceBetween: 20
                    },
                    400: {
                        slidesPerView: 1,
                        spaceBetween: 20
                    },
                    568: {
                        slidesPerView: 1,
                        spaceBetween: 30
                    },
                    850: {
                        slidesPerView: 4,
                        spaceBetween: 30
                    },
                   
                }}
            >
                {featureData.map((feature, index) => (
                    <SwiperSlide key={index}>
                        <div className="flex flex-col justify-center items-center  py-6">
                            <div className="text-4xl text-black">
                                <feature.Icon />
                            </div>
                            <h2 className="text-xl font-semibold mt-4">{feature.title}</h2>
                            <p className="text-normal  mt-2">{feature.para}</p>
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>


        </div>
    )
}

export default FeatureSlider