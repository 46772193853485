const BASE_URL = process.env.REACT_APP_BASE_URL

// AUTH ENDPOINTS
export const endpoints = {
  // SENDOTP_API: BASE_URL + "/auth/sendotp",
  SIGNUP_API: BASE_URL + "register",
  LOGIN_API: BASE_URL + "login",
}

export const productEndpoints = {
  // ADD_PRODUCT_API: BASE_URL + "/product/addProduct",
  GET_ALL_PRODUCTS_API:BASE_URL + "get-products",
  GET_BANNERS_API:BASE_URL + "banners",
  GET_ONE_PRODUCT_API : BASE_URL + "get-products/:slug"
  
}

export const categoriesEndpoints = {
  // ADD_PRODUCT_API: BASE_URL + "/product/addProduct",
  GET_ALL_CATEGORIES_API:BASE_URL + "categories",

}

export const userEndPoints = {
  GET_ALL_USERS_API: BASE_URL + "/users/getallusers",
}