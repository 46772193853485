import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { Link, useLocation } from 'react-router-dom';
import Loader from '../../Common/Loader';
// import { SiPhonepe } from "react-icons/si";
import toast from 'react-hot-toast';
import { SiRazorpay } from "react-icons/si";


const ViewOrderData = () => {
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);

    const [loading, setLoading] = useState(true);
    const [paymentData, setPaymentData] = useState();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const order_id = queryParams.get('order_id');
    const userToken = JSON.parse(localStorage.getItem('token'));
    const [cancellationStatus, setCancellationStatus] = useState(false);

    const createdAt = paymentData?.data?.created_at;
    const dateObject = createdAt ? new Date(createdAt) : null;

    let formattedDate
    if (dateObject) {
        const year = dateObject.getFullYear();
        const month = dateObject.getMonth() + 1; 
        const day = dateObject.getDate();

        formattedDate = `${day < 10 ? '0' : ''}${day}-${month < 10 ? '0' : ''}${month}-${year}`;


    }
    const responsedata = async () => {
        try {
            const response = await axios.get(`https://admin.theroarfit.com/api/order/${order_id}`);
            const result = response.data;
            setPaymentData(result);
            console.log("response of order-detail api after payments", response);
            setLoading(false);
        } catch (error) {
            console.error("Error fetching payment data:", error);
            setLoading(false);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        responsedata();
    }, [order_id]);

    const cancelOrder = async () => {
        const toastId = toast.loading("Loading...")
        try {
            const response = await axios.post(
                'https://admin.theroarfit.com/api/cancel-order',
                {
                    "order_id": order_id,
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${userToken}`,
                    },
                }
            );

            toast.success("Order canceled successfully")
            setCancellationStatus(true);
            // console.log("response of cancel order ", response);
            setLoading(false);

        } catch (error) {
            toast.error("Order not canceled, please try again")
            console.error("Error while canceling order:", error);
            setLoading(false);
        } finally {
            setLoading(false);
            toast.dismiss(toastId);
        }
    };





    return (
        <div className=" font-Quicksand">
            {loading && <p className='text-center'><Loader /></p>}
            {(!loading && !paymentData) && <p className='text-center'>Error loading payment data</p>}
            <div className="flex flex-col">
                {(!loading && paymentData) &&
                    (
                        <div className="mb-8 mx-2 ">
                            <div className="bg-white rounded shadow">
                                <div className="grid grid-cols-1 md:grid-cols-3  gap-6 sm:gap-20 w-11/12 max-w-7xl p-4">
                                    <div>
                                        <h3 className="text-lg font-bold mb-2">Order Detail</h3>
                                        <div className='flex gap-1'><p className='font-semibold whitespace-nowrap'>Order Id :</p> <p>{paymentData.data.order_details[0].order_id

                                        }</p></div>
                                        <div className='flex gap-1'><p className='font-semibold whitespace-nowrap'>Order Date :</p> <p>{formattedDate}</p></div>
                                        <div className='flex gap-1'><p className='font-semibold whitespace-nowrap'>Order Status :</p> <p>{paymentData.data.order_status}</p></div>
                                        <div className='flex gap-1'><p className='font-semibold whitespace-nowrap'>Payment Method :</p> <p>{paymentData.data.payment_mode}</p></div>
                                        <div className='flex gap-1'><p className='font-semibold whitespace-nowrap'>{paymentData.data.payment_mode === "phonepe" ? <>Paid Amount :</> : <> Payable Amount : </>}</p> <p>Rs {paymentData.data.payable_amount}</p></div>
                                        <div className='flex gap-1'><p className='font-semibold whitespace-nowrap'>{paymentData.data.payment_mode === "phonepe" ? `Transaction Id :` : <></>}</p> <p>{paymentData.data.payment?.transaction_id}</p></div>
                                        <div className='flex gap-1'><p className='font-semibold whitespace-nowrap'>Payment Status :</p> <p>{paymentData.data.payment_status}</p></div>

                                    </div>
                                    <div>
                                        <h3 className="text-lg font-bold mb-2">Shipping Address</h3>
                                        <div className='flex gap-1'><p className='font-semibold whitespace-nowrap'>Name :</p> <p>{paymentData.data.customer.shipping_name}</p></div>
                                        <div className='flex gap-1'><p className='font-semibold whitespace-nowrap'>Phone :</p> <p>{paymentData.data.customer.shipping_phone}</p></div>
                                        <div className='flex gap-1'><p className='font-semibold whitespace-nowrap'>Email :</p> <p>{paymentData.data.customer.shipping_email}</p></div>
                                        <div className='flex gap-1'><p className='font-semibold whitespace-nowrap'>Address :</p> <p>{paymentData.data.customer.customer_address.shipping_address_line1} {paymentData.data.customer.customer_address.shipping_address_line2}</p></div>
                                        <div className='flex gap-1'><p className='font-semibold whitespace-nowrap'>City :</p> <p>{paymentData.data.customer.customer_address.shipping_city}</p></div>
                                        <div className='flex gap-1'><p className='font-semibold whitespace-nowrap'>State :</p> <p>{paymentData.data.customer.customer_address.shipping_state}</p></div>
                                        <div className='flex gap-1'><p className='font-semibold whitespace-nowrap'>Country :</p> <p>{paymentData.data.customer.customer_address.shipping_country}</p></div>
                                        <div className='flex gap-1'><p className='font-semibold whitespace-nowrap'>Zip Code :</p> <p>{paymentData.data.customer.customer_address.shipping_zip_code}</p></div>
                                    </div>

                                    <div>
                                        <h3 className="text-lg font-bold mb-2">Billing Address</h3>
                                        <div className='flex gap-1'><p className='font-semibold whitespace-nowrap'>Name :</p> <p>{paymentData.data.customer.billing_name}</p></div>
                                        <div className='flex gap-1'><p className='font-semibold whitespace-nowrap'>Phone :</p> <p>{paymentData.data.customer.billing_phone}</p></div>
                                        <div className='flex gap-1'><p className='font-semibold whitespace-nowrap'>Email :</p> <p>{paymentData.data.customer.billing_email}</p></div>
                                        <div className='flex gap-1'><p className='font-semibold whitespace-nowrap'>Address :</p> <p>{paymentData.data.customer.customer_address.shipping_address_line1} {paymentData.data.customer.customer_address.billing_address_line2}</p></div>
                                        <div className='flex gap-1'><p className='font-semibold whitespace-nowrap'>City :</p> <p>{paymentData.data.customer.customer_address.billing_city}</p></div>
                                        <div className='flex gap-1'><p className='font-semibold whitespace-nowrap'>State :</p> <p>{paymentData.data.customer.customer_address.billing_state}</p></div>
                                        <div className='flex gap-1'><p className='font-semibold whitespace-nowrap'>Country :</p> <p>{paymentData.data.customer.customer_address.billing_country}</p></div>
                                        <div className='flex gap-1'><p className='font-semibold whitespace-nowrap'>Zip Code :</p> <p>{paymentData.data.customer.customer_address.billing_zip_code}</p></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }

                {/* <div className="mb-8">
                    <div className="py-2">
                        {(!loading && paymentData?.data.order_details && paymentData.data.order_details.length > 0) && (
                            <div className="max-w-7xl w-11/12 mx-auto">
                                <Table className="text-center text-sm font-light">
                                    <Thead className="border-b bg-gray-300 font-medium text-black ">
                                        <Tr>
                                            <Th className="px-2 py-4 text-left">No.</Th>
                                            <Th className="px-2 py-4 text-left">Product name</Th>
                                            <Th className="px-2 py-4 text-left">Qty.</Th>
                                            <Th className="px-2 py-4 text-left">Price</Th>
                                            <Th className="px-2 py-4 text-left">Amount</Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        {!loading && paymentData.data.order_details.map((product, index) => {
                                            const gstpercent = product.product?.gst;
                                            const calculateGstAmount = (price, gstpercent) => (price * gstpercent) / 100;
                                            const gstAmount = calculateGstAmount(product.product?.sale_price, gstpercent);
                                            const regulaPriceWithGst = (Number(product.product?.regular_price) + gstAmount).toFixed(2);
                                            const salePriceWithGst = (Number(product.product?.sale_price) + gstAmount).toFixed(2);
                                      
                                            return <Tr key={index} className="border-b dark:border-neutral-500 text-black font-semibold">
                                                <Td className="whitespace-normal px-2   py-4 font-medium text-left">{index + 1}</Td>
                                                <Td className="whitespace-normal px-2 py-4 text-left break-words text-black">
                                                    {product.product.name}
                                                </Td>
                                                <Td className="whitespace-normal px-2  py-4 text-left">{product.qty}</Td>
                                                <Td className="whitespace-nowrap px-2  py-4 text-left">{`Rs ${salePriceWithGst}`}</Td>
                                                <Td className="whitespace-normal px-2  py-4 text-left">{`Rs ${product.qty * salePriceWithGst}`}</Td>
                                            </Tr>
                                        })}
                                    </Tbody>
                                </Table>
                                {
                                    paymentData.data.payment_mode === "phonepe" ? (
                                        <>
                                            <div className="border-b dark:border-neutral-500 flex justify-between items-center">
                                                <div className="whitespace-normal py-4 text-left font-bold text-black uppercase">
                                                    Prepaid Discount
                                                </div>
                                                <div className="whitespace-nowrap  py-4 text-left text-black font-bold">
                                                    Rs {paymentData.data.discount}
                                                </div>
                                            </div>
                                            <div className="border-b dark:border-neutral-500 flex justify-between items-center">
                                                <div className="whitespace-normal py-4 text-left font-bold text-black">
                                                    GRAND TOTAL
                                                </div>
                                                <div className="whitespace-nowrap  py-4 text-left text-black font-bold">
                                                    {`Rs ${(paymentData.data.total_amount - paymentData.data.discount).toFixed(2)}`}
                                                </div>
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <div className="border-b dark:border-neutral-500 flex justify-between">
                                                <div className="whitespace-normal  py-4 text-left font-bold text-black">
                                                    <p>GRAND TOTAL</p>
                                                    <Link to={`https://admin.theroarfit.com

/pay-online/${order_id}`}>
                                                        <button type="button" className="text-white bg-purple-600 focus:outline-none font-medium rounded-lg  text-xs sm:text-sm px-2 py-1 text-center inline-flex items-center me-2 mb-2">
                                                            <SiPhonepe className='mr-1' /> Click to Pay and get 10% off
                                                        </button>
                                                    </Link>
                                                </div>
                                                <div className="whitespace-nowrap  py-4 text-left text-black font-bold">
                                                    {`Rs ${(paymentData.data.total_amount - paymentData.data.discount).toFixed(2)}`}
                                                </div>
                                            </div>
                                        </>
                                    )
                                }
                                {!cancellationStatus && (
                                    <button onClick={cancelOrder} className="text-white bg-red-500 focus:outline-none font-medium rounded-lg text-sm px-2 py-1 text-center inline-flex  me-2 mb-2 mt-2">cancel order</button>
                                )}
                            </div>
                        )}
                    </div>
                </div> */}
                <div className="relative overflow-x-auto mb-8 rounded-md">
                    <div className="py-2">
                        {(!loading && paymentData?.data.order_details && paymentData.data.order_details.length > 0) && (
                            <div className="max-w-7xl w-11/12 mx-auto">
                                <table className="w-full text-center text-sm font-light">
                                    <thead className="border-b bg-gray-300 font-medium text-black">
                                        <tr>
                                            <th className="px-2 py-4 text-left">No.</th>
                                            <th className="px-2 py-4 text-left">Product name</th>
                                            <th className="px-2 py-4 text-left">Qty.</th>
                                            <th className="px-2 py-4 text-left">Price</th>
                                            <th className="px-2 py-4 text-left">Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody className="bg-white">
                                        {!loading && paymentData.data.order_details.map((product, index) => {
                                            const gstpercent = product.product?.gst;
                                            const calculateGstAmount = (price, gstpercent) => (price * gstpercent) / 100;
                                            const gstAmount = calculateGstAmount(product.product?.sale_price, gstpercent);
                                            const regularPriceWithGst = (Number(product.product?.regular_price) + gstAmount).toFixed(2);
                                            const salePriceWithGst = (Number(product.product?.sale_price) + gstAmount).toFixed(2);

                                            return (
                                                <tr key={index} className="border-b dark:border-neutral-500 text-black font-semibold">
                                                    <td className="whitespace-normal px-2 py-4 font-medium text-left">{index + 1}</td>
                                                    <td className="whitespace-normal px-2 py-4 text-left break-words text-black">
                                                        {product.product.name}
                                                    </td>
                                                    <td className="whitespace-normal px-2 py-4 text-left">{product.qty}</td>
                                                    <td className="whitespace-nowrap px-2 py-4 text-left">{`Rs ${salePriceWithGst}`}</td>
                                                    <td className="whitespace-normal px-2 py-4 text-left">{`Rs ${product.qty * salePriceWithGst}`}</td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                                {paymentData.data.payment_mode === "phonepe" ? (
                                    <>
                                        <div className="border-b dark:border-neutral-500 flex justify-between items-center">
                                            <div className="whitespace-normal py-4 text-left font-bold text-black uppercase">
                                                Prepaid Discount
                                            </div>
                                            <div className="whitespace-nowrap py-4 text-left text-black font-bold">
                                                Rs {paymentData.data.discount}
                                            </div>
                                        </div>
                                        <div className="border-b dark:border-neutral-500 flex justify-between items-center">
                                            <div className="whitespace-normal py-4 text-left font-bold text-black">
                                                GRAND TOTAL
                                            </div>
                                            <div className="whitespace-nowrap py-4 text-left text-black font-bold">
                                                {`Rs ${(paymentData.data.total_amount - paymentData.data.discount).toFixed(2)}`}
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div className="border-b dark:border-neutral-500 flex justify-between">
                                            <div className="whitespace-normal py-4 text-left font-bold text-black">
                                                <p>GRAND TOTAL</p>
                                                {/* <Link to={`https://admin.theroarfit.com

/pay-online/${order_id}`}>
                                                    <button type="button" className="text-white bg-purple-600 focus:outline-none font-medium rounded-lg text-xs sm:text-sm px-2 py-1 text-center inline-flex items-center me-2 mb-2">
                                                        <SiPhonepe className='mr-1' /> Click to Pay and get 10% off
                                                    </button>
                                                </Link> */}
                                                <Link to={`https://pay.theroarfit.com/pay-now-razorpay/${order_id}`}>
                                                    <button type="button" className="text-white bg-black focus:outline-none font-medium rounded-lg text-xs sm:text-sm px-2 py-1 text-center inline-flex items-center me-2 mb-2">
                                                        <SiRazorpay className='mr-1 text-blue-600' /> Click to Pay and get 10% off
                                                    </button>
                                                </Link>
                                            </div>
                                            <div className="whitespace-nowrap py-4 text-left text-black font-bold">
                                                {`Rs ${(paymentData.data.total_amount - paymentData.data.discount).toFixed(2)}`}
                                            </div>
                                        </div>
                                    </>
                                )}
                                {!cancellationStatus && (
                                    <button onClick={cancelOrder} className="text-white bg-red-500 focus:outline-none font-medium rounded-lg text-sm px-2 py-1 text-center inline-flex me-2 mb-2 mt-2">Cancel Order</button>
                                )}
                            </div>
                        )}
                    </div>
                </div>

            </div>
        </div>
    );
};

export default ViewOrderData;
