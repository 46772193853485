import { createSlice } from '@reduxjs/toolkit';

const comboProductSlice = createSlice({
    name: 'selectedProducts',
    initialState: [],
    reducers: {
        setSelectedProducts: (state, action) => action.payload,
        clearSelectedProducts: () => []
    }
});

export const { setSelectedProducts, clearSelectedProducts } = comboProductSlice.actions;

export default comboProductSlice.reducer;
