import React, { useState, useEffect } from 'react'
import { IoMdChatbubbles } from "react-icons/io";
import { HiBuildingOffice2 } from "react-icons/hi2";
import { MdAddCall } from "react-icons/md";
import { FaWhatsapp } from "react-icons/fa6";
import axios from 'axios'

const ContactDetail = () => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        const apiUrl = 'https://admin.theroarfit.com/api/contact-detail';
        axios.get(apiUrl)
            .then(response => {
                setData(response.data);
                setLoading(false);
                // console.log("response of contact-detail", response);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
                setLoading(false);
            });
    }, []);

    return (
        <div className="flex flex-col gap-6 rounded-xl bg-slate-100 p-4 lg:p-6 text-gray-500">
            <div className="flex flex-col gap-[2px] p-3 text-sm">
                <div className="flex flex-row items-center gap-3">
                    <IoMdChatbubbles size={25} />
                    <h1 className="text-lg font-semibold text-gray-500">Chat on us</h1>
                </div>
                <p className='font-medium'>Our friendly team is here to help.</p>
                {data && data.data.contact_info && (
                    <p className='font-semibold'>{data.data.contact_info.email}</p>
                )}

            </div>
            <div className="flex flex-col gap-[2px] p-3 text-sm">
                <div className="flex flex-row items-center gap-3">
                    <HiBuildingOffice2 size={25} />
                    <h1 className="text-lg font-semibold text-gray-500">Visit us</h1>
                </div>
                <p className='font-medium'>Come and say hello at our office HQ.</p>
                {data && data.data.contact_info && (
                    <p className='font-semibold'>{data.data.contact_info.addresss}</p>
                )}

            </div>
            <div className="flex flex-col gap-[2px] p-3 text-sm">
                <div className="flex flex-row items-center gap-3">
                    <MdAddCall size={25} />
                    <h1 className="text-lg font-semibold text-gray-500">Call us</h1>
                </div>
                <p className='font-medium'>Mon - Fri From 8am to 5pm</p>
                {data && data.data.contact_info && (
                    <p className='font-semibold'>{data.data.contact_info.phone_no}</p>
                )}

            </div>
            <div className="flex flex-col gap-[2px] p-3 text-sm">
                <div className="flex flex-row items-center gap-3">
                    <FaWhatsapp size={25} />
                    <h1 className="text-lg font-semibold text-gray-500">Whatsapp us</h1>
                </div>
                <p className='font-medium'>Mon - Fri From 8am to 5pm</p>
                {data && data.data.contact_info && (
                    <p className='font-semibold'>{data.data.contact_info.whatsapp_no}</p>
                )}

            </div>
        </div>
    )
}

export default ContactDetail