import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { AiFillDelete } from "react-icons/ai";
import { GrFormAdd } from "react-icons/gr";
import { HiOutlineMinusSm } from "react-icons/hi";
import toast from 'react-hot-toast';
import { CiHeart } from "react-icons/ci";
import { BiSolidHeart } from "react-icons/bi";
import axios from 'axios';
import { setAllWishlistItems, addWishlistItem, removeWishlistItem } from '../../slices/wishlistSlice';
import { getAllWishlistItems } from '../UserDashboard/main/UserWishlist';


function CartItem({ item, fullItem, quantity, removeFromCart, increaseItemQuantity, decreaseItemQuantity, handleTotalStock }) {
  const [isFilled, setIsFilled] = useState(false);
  const userToken = JSON.parse(localStorage.getItem('token'));
  const { wishlistData } = useSelector(state => state.wishlist)
  const dispatch = useDispatch();
  const [notifyEmail, setNotifyEmail] = useState('');
  const [showNotifyModal, setShowNotifyModal] = useState(false);

  let totalStock = "";
  for (const key in item.stock) {
    if (key == fullItem?.variations?.size) {
      totalStock = item.stock[key];
    }
  }
  useEffect(() => {
    handleTotalStock(totalStock);
  }, [totalStock, handleTotalStock]);

  const addToWishlist = async () => {
    if (!userToken) {
      toast.error('Please log in first.');
      return;
    }
    const toastId = toast.loading("Loading...")
    try {
      const formData = new FormData();
      formData.append("product_id", item.id)
      console.log("add item id", item.id);
      const response = await axios.post(
        'https://admin.theroarfit.com/api/wishlist/add',
        formData,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${userToken}`,
          }
        }
      );

      const responseData = response.data;
      console.log("respnse", response.data.data);


      toast.success(responseData.message);
      wishlistData.length > 0 ? dispatch(addWishlistItem(response.data.data)) : getAllWishlistItems(userToken, dispatch, setAllWishlistItems);
      setIsFilled(!isFilled);
      toast.dismiss(toastId)

    } catch (error) {
      console.error('Error adding product to wishlist:', error);
      toast.error('An error occurred while adding the product to the wishlist.');
      toast.dismiss(toastId)
    }

  };


  const handleNotifyMeClick = () => {
    setShowNotifyModal((prevShowNotifyModal) => !prevShowNotifyModal);
  };

  const handleNotifySubmit = async () => {
    // Validate email format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(notifyEmail)) {
      toast.error('Please enter a valid email address.');
      return;
    }
    // console.log("email", notifyEmail, "cart item id", item.id);

    try {
      const response = await axios.post(
        'https://admin.theroarfit.com/api/product-notify',
        {
          email: notifyEmail,
          product_id: item.id, // Make sure to replace product.id with the actual product ID
        }
      );

      // Check if the request was successful (you may need to adjust based on your API response structure)
      if (response.status === 200) {
        // Close the modal
        setShowNotifyModal(false);

        // Clear the email input
        setNotifyEmail('');

        // Show success message to the user
        toast.success('You will be notified when the product is back in stock.');
      } else {
        // Handle unsuccessful response (show error message to the user)
        toast.error('Failed to submit notification. Please try again.');
      }
    } catch (error) {
      // Handle network or other errors
      console.error('Error submitting notification:', error);
      toast.error('An error occurred. Please try again later.');
    }
  };

  const gstpercent = item?.gst;
  const calculateGstAmount = (price, gstpercent) => (price * gstpercent) / 100;
  const gstAmount = calculateGstAmount(item?.sale_price, gstpercent);
  const salePriceWithGst = Math.round(Number(item.sale_price) + gstAmount);

  return (
    <div className="font-Quicksand">
      <div className='justify-between mb-6 rounded-lg bg-white p-6 shadow-[0_3px_10px_rgb(0,0,0,0.2)] sm:flex sm:justify-start'>
        <div className='h-28 w-48'>
          <img src={item?.image_data?.thumbnail} alt="cart item" className="rounded-lg sm:w-40 w-full h-full object-contain" />
        </div>
        <div className='sm:ml-4 sm:flex sm:w-full gap-2 sm:justify-between'>
          <div className="mt-5 sm:mt-0">
            <p className="font-bold text-gray-900">{item?.name}</p>
            {showNotifyModal && (
              <div className='modal border p-2 rounded-md bg-gray-100'>
                <div className='modal-content'>
                  <p>You will be notified on email when the product is back in stock.</p>
                  <div className='flex flex-col gap-2 mt-2'>
                    <input
                      type='email'
                      placeholder='Enter your email'
                      value={notifyEmail}
                      onChange={(e) => setNotifyEmail(e.target.value)}
                      className='focus:outline-none border rounded-md px-2'
                    />
                    <div className='flex gap-2'>
                      <button onClick={handleNotifyMeClick} className='bg-black text-white font-bold text-xs px-4 py-[0.35rem] rounded shadow  outline-none focus:outline-none mb-1 mt-2 sm:mt-0'>Cancel</button>
                      <button onClick={handleNotifySubmit} className='bg-black text-white font-bold text-xs px-4 py-[0.35rem] rounded shadow  outline-none focus:outline-none mb-1 mt-2 sm:mt-0'>Submit</button>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {fullItem?.is_combo === 1 ? (
              <div>
                {fullItem.variations.map((variation, index) => (
                  <div key={index} className="text-sm mb-2">
                    <div className='flex gap-2 font-semibold'>
                      <p className='text-gray-800 whitespace-nowrap'>Product {index+1} : </p>
                      <span className='text-gray-700'>{variation.name}</span>
                    </div>
                    <div className='flex gap-2 font-semibold'>
                      <p className='text-gray-800'>Color: </p>
                      <span className='text-gray-700'>{variation.color}</span>
                    </div>
                    <div className='flex gap-2 font-semibold'>
                      <p className='text-gray-800'>Size: </p>
                      <span className='text-gray-700'>{variation.size}</span>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div>
                <div className="text-sm mt-2">
                  {fullItem?.variations.size && (
                    <div className='flex gap-2 font-semibold'>
                      <p className='text-gray-800'>Size :</p>
                      <span className='text-gray-700'>{fullItem.variations.size}</span>
                    </div>
                  )}
                </div>
                <div className="text-sm">
                  {fullItem?.variations.color && (
                    <div className='flex gap-2 font-semibold'>
                      <p className='text-gray-800'>Colour : </p>
                      <span className='text-gray-700'>{fullItem.variations.color}</span>
                    </div>
                  )}
                </div>
              </div>
            )}
            <div className='flex gap-2 mt-4 items-center'>
              <p className='text-sm font-semibold'>Add to wishlist </p>
              <p>{isFilled ? <BiSolidHeart className='text-lg' /> : <CiHeart className='text-lg' onClick={addToWishlist} />}</p>
            </div>
          </div>
          <div className='mt-4 flex justify-between sm:space-y-6 sm:mt-0 sm:block sm:space-x-6'>
            <div className='flex items-center gap-2'>
              <div className='flex items-center border-gray-100'>
                <button onClick={decreaseItemQuantity} className='cursor-pointer rounded-l bg-gray-100 py-1 px-2 duration-100' style={{ width: '30px', height: '30px' }}>
                  <HiOutlineMinusSm />
                </button>
                <p className="h-8 w-8 border bg-white text-center text-xs outline-none pt-2 font-semibold">{quantity}</p>
                <button onClick={increaseItemQuantity} className='cursor-pointer rounded-l bg-gray-100 py-1 px-2 duration-100' style={{ width: '30px', height: '30px' }}>
                  <GrFormAdd />
                </button>
              </div>
              <div
                onClick={() => removeFromCart(item.id)}
                className='flex items-center justify-center bg-red-400 rounded-full hover:cursor-pointer'
                style={{ width: '30px', height: '30px' }}
              >
                <AiFillDelete className='m-1' />
              </div>
            </div>
            <div className="flex items-center space-x-4">
              <p className="text-black font-semibold whitespace-nowrap">Rs. {(salePriceWithGst * quantity).toFixed(2)}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CartItem;
